import { links } from "safira-app/config/links";
import { OccurrenceForm, OccurrenceListResponse, OccurrenceMotivesResponse, OccurrenceShowProps } from "interfaces/Occurrences";
import api from "services/api";

// OCCURRENCES
export const getOccurrences = async (companyId: string) => {
  const perPage = 99999;
  const response = await api.get(links.api.core + `/engineering/managers/occurrence`, {
    headers: { companyId },
    params: { perPage }
  });
  
  return response.data as OccurrenceListResponse;
}


export const showOccurrence = async (occurrenceId: string, companyId: string) => {
  const response = await api.get(links.api.core + `/engineering/managers/occurrence/${occurrenceId}`, {
    headers: { companyId }
  })

  return response.data as OccurrenceShowProps
}

export const createOccurrence = async (data: OccurrenceForm, companyId: string) => {
  const response = await api.post(links.api.core + `/engineering/managers/occurrence`, data, {
    headers: { companyId },
  });

  return response.data
}

export const deleteOccurrence = async (occurrenceId: string, companyId: string) => {
  const response = await api.delete(links.api.core + `/engineering/collaborators/occurrence/${occurrenceId}`, {
    headers: { companyId },
  })

  return response.data;
}

export const editOccurrence = async (occurrenceId: string, data: OccurrenceForm, companyId: string) => {
  const response = await api.put(links.api.core + `/engineering/managers/occurrence/${occurrenceId}`,
  data,
  {
    headers: { companyId },
  })

  return response.data as OccurrenceShowProps
}

// MOTIVES FOR OCCURRENCES
export const getOccurrenceMotives = async (companyId: string) => {
  const perPage = 99999;
  const response = await api.get(links.api.core + `/engineering/collaborators/occurrence/reason`, {
    headers: { companyId },
    params: { perPage },
  });

  return response.data as OccurrenceMotivesResponse;
}