import { Chip, MenuItem, Stack, Typography, chipClasses, menuItemClasses } from '@mui/material';
import InMenu from 'components/InMenu';

type ViewManyItemsProps<T> = {
  options: T[];
  getOptionLabel: (option: T) => {
    id: string;
    label: string;
  };
};

function ViewManyItems<Option extends any>({ options, getOptionLabel }: ViewManyItemsProps<Option>) {
  const firstOption = options[0];
  const optionsToDisplay = options.slice(1, options.length);
  const optionsCounter = optionsToDisplay.length;

  return (
    <Stack direction="row" gap={1}>
      <Typography
        sx={{
          whiteSpace: 'break-spaces',
          overflowWrap: 'anywhere',
          fontSize: 12,
          fontFamily: 'Open Sans, sans serif',
        }}
      >
        {getOptionLabel(firstOption).label}
      </Typography>
      {!!optionsToDisplay.length && (
        <InMenu
          MenuListProps={{
            onClick: ev => ev.stopPropagation(),
            sx: {
              maxWidth: 200,
              maxHeight: 180,
              [`.${menuItemClasses.root}`]: {
                textWrap: 'wrap',
                overflowWrap: 'anywhere',
              },
            },
          }}
          renderAnchor={({ openMenu }) => (
            <Chip
              id="incheck-sector-chip"
              label={optionsCounter >= 99 ? '+99' : `+${optionsCounter}`}
              onClick={ev => {
                ev.stopPropagation();
                openMenu(ev);
              }}
              sx={{
                cursor: 'pointer',
                width: 24,
                height: 24,
                borderRadius: 999,
                [`.${chipClasses.label}`]: {
                  p: 0,
                  fontSize: '10px',
                },
              }}
            />
          )}
        >
          {optionsToDisplay.map(option => {
            const item = getOptionLabel(option);

            return (
              <MenuItem key={item.id} disableTouchRipple disableRipple sx={{ cursor: 'default' }}>
                <Typography
                  sx={{
                    whiteSpace: 'break-spaces',
                    overflowWrap: 'anywhere',
                    fontSize: 12,
                    fontFamily: 'Open Sans, sans serif',
                  }}
                >
                  {item.label}
                </Typography>
              </MenuItem>
            );
          })}
        </InMenu>
      )}
    </Stack>
  );
}

export default ViewManyItems;
