import Avatar from 'components/Avatar';
import ExpandTableButton from 'components/Icons/ExpandTableButton';
import { StatusItem } from 'components/StatusItem';
import { ActionsCell, ExpandButton, TableCell, TableRow } from 'components/Tables/styles';
import React, { useState } from 'react';
import VacationRequestItem from './VacationRequestItem';
import { GeneralVacationItem, RequestVacationByCompanyItem } from 'interfaces/Vacations';
import { VacationStatusColor, VacationStatusLabel } from 'utils/lists';
import { MoreButton } from 'components/Buttons/styles';
import { ReactComponent as MoreIcon } from '../../../layout/icons/more-icon.svg';
import RequestVacationModal from '../RequestVacationModal';
import { Menu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { getEmployementType } from '../types';
import MoneyIcon from '../RequestVacationModal/icons/MoneyIcon';
import { useTranslation } from 'react-i18next';

interface AcquisitiveItemProps {
  item: GeneralVacationItem;
}

const formateDate = (date: string) => {
  if (!date) return date;
  const dateParts = date.split('-');
  const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0].slice(2)}`;
  return formattedDate;
};

const AcquisitiveItem: React.FC<AcquisitiveItemProps> = ({ item }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [acquisitiveItem, setAcquisitiveItem] = useState<GeneralVacationItem>(item);
  const [modalRequestOpen, setModalRequestOpen] = useState(false);
  const [modalProgramOpen, setModalProgramOpen] = useState(false);
  const [requests, setRequests] = useState<Array<RequestVacationByCompanyItem>>(acquisitiveItem.requests);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const requestVacation = () => {
    setModalRequestOpen(true);
  };

  const onVacations = acquisitiveItem.requests.find(item => item.status === 'ON_VACATION');
  const hasRequestPending = item.requests.find(item => item.status === 'PENDING');

  const employementType = getEmployementType(item.collaborator.employment_type);
  const isCLT = Boolean(employementType?.value === 'FULL_TIME');
  const hasWageNegotiation = item.requests.some(request => request.status !== 'REFUSED' && request.isWageNegotiation);

  return (
    <>
      <TableRow className="father-row">
        <TableCell style={{ width: '15px' }}>
          {requests.length ? (
            <ExpandButton onClick={handleExpand} expanded={expanded}>
              <ExpandTableButton />
            </ExpandButton>
          ) : null}
        </TableCell>
        <TableCell
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Avatar name={acquisitiveItem.collaborator.person.name} src={acquisitiveItem.collaborator.person.avatar} />{' '}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {acquisitiveItem.collaborator.person.name}
            <span style={{ fontSize: '9px', color: 'rgb(108, 117, 125)' }}>{employementType?.label}</span>
          </div>
        </TableCell>
        <TableCell>
          {formateDate(acquisitiveItem.acquisitive_start_date)} - {formateDate(acquisitiveItem.acquisitive_end_date)}
        </TableCell>
        <TableCell style={{ width: '210px' }}>
          {formateDate(acquisitiveItem.concessive_start_date)} - {formateDate(acquisitiveItem.concessive_end_date)}
        </TableCell>
        <TableCell style={{ width: '100px' }}>{acquisitiveItem.balance_days} dias</TableCell>
        <TableCell
          style={{
            width: '100px',
          }}
        >
          <p style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {hasWageNegotiation ? (
              <>
                <MoneyIcon /> 10
              </>
            ) : (
              '--'
            )}
          </p>
        </TableCell>
        <TableCell>
          {hasRequestPending ? (
            <StatusItem color="#FFAC2F">{t('vacation.pending_request')}</StatusItem>
          ) : (
            <StatusItem color={VacationStatusColor[acquisitiveItem.status]}>
              {t(VacationStatusLabel[acquisitiveItem.status])}
            </StatusItem>
          )}
        </TableCell>
        <ActionsCell>
          {onVacations ? <StatusItem color="#77B51F">{isCLT ? 'Em férias' : 'Em descanso'}</StatusItem> : null}
          <Menu
            direction="left"
            menuButton={
              <MoreButton>
                <MoreIcon />
              </MoreButton>
            }
            transition
          >
            <MenuItem
              disabled={false}
              title={isCLT ? t('schedule_vacation') : t('schedule_rest')}
              onClick={requestVacation}
            >
              {isCLT ? t('schedule_vacation') : t('schedule_rest')}
            </MenuItem>
          </Menu>
        </ActionsCell>
      </TableRow>

      {modalRequestOpen ? (
        <RequestVacationModal
          open={modalRequestOpen}
          setOpen={setModalRequestOpen}
          actualBalance={acquisitiveItem.balance_days}
          collaboratorId={acquisitiveItem.collaborator.id}
          acquisitivePeriodId={acquisitiveItem.id}
          setRequests={setRequests}
          setExpandRow={setExpanded}
          type="REQUEST"
          item={item}
          allRequests={requests}
          setAcquisitiveItem={setAcquisitiveItem}
        />
      ) : null}

      {modalProgramOpen ? (
        <RequestVacationModal
          open={modalProgramOpen}
          setOpen={setModalProgramOpen}
          actualBalance={acquisitiveItem.balance_days}
          collaboratorId={acquisitiveItem.collaborator.id}
          acquisitivePeriodId={acquisitiveItem.id}
          setRequests={setRequests}
          setExpandRow={setExpanded}
          type="APPROVE"
          item={item}
          allRequests={requests}
          setAcquisitiveItem={setAcquisitiveItem}
        />
      ) : null}

      {expanded &&
        requests.length > 0 &&
        requests.map(request => (
          <VacationRequestItem
            item={request}
            key={request.id}
            expanded={expanded}
            acquisitiveId={acquisitiveItem.id}
            collaboratorId={acquisitiveItem.collaborator.id}
            actualBalance={acquisitiveItem.balance_days}
            setExpandRow={setExpanded}
            setRequests={setRequests}
          />
        ))}
    </>
  );
};

export default AcquisitiveItem;
