import { links } from "safira-app/config/links";
import {
  GeneralVacationItem,
  RequestVacationByCompany,
  RequestVacationByCompanyItem,
  ResquestAnswerByManager,
} from "interfaces/Vacations";
import api from "services/api";

export const getCollaboratorVacations = async (
  collaboratorId: string,
  companyId: string
) => {
  const response = await api.get(
    links.api.core + `/engineering/collaborators/${collaboratorId}/vacations`,
    {
      headers: { companyId: companyId },
    }
  );

  return response.data as GeneralVacationItem[];
};

export const getVacationsByCompany = async (companyId: string) => {
  const response = await api.get(links.api.core + `/engineering/vacations`, {
    headers: { companyId: companyId },
  });

  return response.data as GeneralVacationItem[];
};

export const getVacationsByManagers = async (companyId: string) => {
  const response = await api.get(
    links.api.core + `/engineering/managers/vacations`,
    {
      headers: { companyId: companyId },
    }
  );

  return response.data as GeneralVacationItem[];
};

export const requestVacationByCompany = async (
  collaboratorId: string,
  acquisitiveId: string,
  companyId: string,
  data: RequestVacationByCompany
) => {
  const response = await api.post(
    links.api.core +
      `/engineering/collaborators/${collaboratorId}/vacations/${acquisitiveId}/request`,
    data,
    {
      headers: { companyId: companyId },
    }
  );

  return response.data as RequestVacationByCompanyItem;
};

export const requestVacationByManager = async (
  collaboratorId: string,
  acquisitiveId: string,
  companyId: string,
  data: RequestVacationByCompany
) => {
  const response = await api.post(
    links.api.core +
      `/engineering/managers/${collaboratorId}/vacations/${acquisitiveId}`,
    data,
    {
      headers: { companyId: companyId },
    }
  );

  return response.data as RequestVacationByCompanyItem;
};

export const programVacationByCompany = async (
  collaboratorId: string,
  acquisitiveId: string,
  companyId: string,
  data: RequestVacationByCompany
) => {
  const response = await api.post(
    links.api.core +
      `/engineering/collaborators/${collaboratorId}/vacations/${acquisitiveId}/schedule`,
    data,
    {
      headers: { companyId: companyId },
    }
  );

  return response.data as RequestVacationByCompanyItem;
};

export const approveRequestByManager = async (
  collaboratorId: string,
  acquisitiveId: string,
  requestId: string,
  data: { answer: boolean },
  companyId
) => {
  const response = await api.post(
    links.api.core +
      `/engineering/managers/${collaboratorId}/vacations/${acquisitiveId}/${requestId}/answer`,
    data,
    {
      headers: { companyId: companyId },
    }
  );

  return response.data;
};

export const approveRequestByCompany = async (
  collaboratorId: string,
  acquisitiveId: string,
  requestId: string,
  data: ResquestAnswerByManager,
  companyId
) => {
  const response = await api.post(
    links.api.core +
      `/engineering/collaborators/${collaboratorId}/vacations/${acquisitiveId}/${requestId}/answer`,
    data,
    {
      headers: { companyId: companyId },
    }
  );

  return response.data;
};
