import { yupResolver } from '@hookform/resolvers/yup';
import { addToast } from 'safira-app/components/Toast';
import { Button, OutlinedButton } from 'components/Buttons/styles';
import { FieldWrapped } from 'components/Forms/FieldWrapped';
import HelpForm, { HelpersContainer } from 'components/Forms/HelpForm';
import { HelperText } from 'components/Forms/Helpers/HelperText';
import { InputBase } from 'components/Forms/Input/Input';
import { Label } from 'components/Forms/Label';
import { TextareaBase } from 'components/Forms/Textarea/Textarea';
import VacationIcon from 'components/Icons/VacationIcon';
import LoadingComponent from 'components/LoadingComponent';
import Modal, { ModalActions, ModalContent } from 'components/Modal';
import { usePermissions } from 'safira-app/contexts/Permissions';
import { GeneralVacationItem, RequestVacationByCompany, RequestVacationByCompanyItem } from 'interfaces/Vacations';
import { GridCol, GridContainer, GridRow } from 'layout/components/page-styles';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { programVacationByCompany, requestVacationByManager } from 'requests/vacations';
import { calculateDateDifference, dateCompare, isDateInRange } from 'utils/utils';
import * as yup from 'yup';
import { AcquisitiveInfo } from './style';
import { getEmployementType } from '../types';
import Checkbox from 'components/Forms/Checkbox';
import MoneyIcon from './icons/MoneyIcon';
import { useTranslation } from 'react-i18next';

interface RequestVacationModalProps {
  open: boolean;
  type: 'REQUEST' | 'APPROVE';
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setExpandRow: React.Dispatch<React.SetStateAction<boolean>>;
  actualBalance: number;
  collaboratorId: string;
  acquisitivePeriodId: string;
  setRequests: React.Dispatch<React.SetStateAction<RequestVacationByCompanyItem[]>>;
  item: GeneralVacationItem;
  allRequests: RequestVacationByCompanyItem[];
  setAcquisitiveItem: React.Dispatch<React.SetStateAction<GeneralVacationItem>>;
}

const schema = yup.object({
  start_date: yup.string().required('Campo obrigatório'),
  end_date: yup.string().required('Campo obrigatório'),
  note: yup.string().required('Campo obrigatório'),
});

const formateDate = (date: string) => {
  if (!date) return date;
  const dateParts = date.split('-');
  const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
  return formattedDate;
};

type OmitData<T> = Omit<T, 'isWageNegotiation'>;
type RequestVacationByCompanyWithoutIsWage = OmitData<RequestVacationByCompany>;

const RequestVacationModal: React.FC<RequestVacationModalProps> = ({
  open,
  setOpen,
  actualBalance,
  collaboratorId,
  acquisitivePeriodId,
  setRequests,
  setExpandRow,
  type,
  item,
  allRequests,
  setAcquisitiveItem,
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<RequestVacationByCompanyWithoutIsWage>({
    resolver: yupResolver(schema),
  });

  const { companyId } = usePermissions();
  const [loading, setLoading] = useState(false);
  const [selectedDaysValue, setSelectedDaysValue] = useState(0);
  const [remainderValue, setRemainderValue] = useState(0);
  const [showDisabledInput, setShowDisabledInput] = useState(false);
  const [isWageNegotiation, setIsWageNegotiation] = useState(false);
  const [validations, setValidations] = useState<any>({
    balancExceeded: 'INFO',
    balancelessThanFive: 'INFO',
    requestFiveMinimum: 'INFO',
  });

  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');

  const hasValidNegotiation = allRequests.some(
    request => request.isWageNegotiation === true && request.status !== 'REFUSED',
  );

  const [endDateValidation, setEndDateValidation] = useState({
    min: '',
    max: '',
  });

  const handleClose = () => setOpen(false);

  const actualDate = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(actualDate.getDate() + 1);

  const tomorrowValidation = `${tomorrow.getFullYear()}-${(tomorrow.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${tomorrow.getDate().toString().padStart(2, '0')}`;

  const dateMin = dateCompare(tomorrowValidation, item.concessive_start_date)
    ? tomorrowValidation
    : item.concessive_start_date;

  const handleStartDate = e => {
    const startSelected = new Date(e.target.value);
    if (!startSelected) return;

    setSelectedStartDate(e.target.value);

    const minDate = new Date(startSelected);
    minDate.setDate(startSelected.getDate() + 5);

    const maxDate = new Date(startSelected);
    maxDate.setDate(startSelected.getDate() + actualBalance);

    const formatedMin = `${minDate.getFullYear()}-${(minDate.getMonth() + 1).toString().padStart(2, '0')}-${minDate
      .getDate()
      .toString()
      .padStart(2, '0')}`;

    const formatedMax = `${maxDate.getFullYear()}-${(maxDate.getMonth() + 1).toString().padStart(2, '0')}-${maxDate
      .getDate()
      .toString()
      .padStart(2, '0')}`;

    setEndDateValidation({
      min: formatedMin,
      max: formatedMax,
    });
  };

  const handleEndDate = e => {
    const endSelected = new Date(e.target.value);
    if (!endSelected) return;

    setSelectedEndDate(e.target.value);
  };

  useEffect(() => {
    if (selectedStartDate > selectedEndDate) return;
  }, [selectedStartDate, selectedEndDate]);

  const onSubmit = (data: RequestVacationByCompanyWithoutIsWage) => {
    const selectedDays = calculateDateDifference(data.start_date, data.end_date);

    const remainder = isWageNegotiation ? actualBalance - (selectedDays + 10) : actualBalance - selectedDays;

    const startDateUTC = new Date(data.start_date);
    startDateUTC.setDate(startDateUTC.getDate() + 1);

    setSelectedDaysValue(selectedDays);
    setRemainderValue(remainder);
    setShowDisabledInput(true);

    // os dias selecionados não podem exceder o saldo disponível
    if (selectedDays > actualBalance) {
      setValidations(old => ({
        ...old,
        balancExceeded: 'ERROR',
      }));
    } else {
      setValidations(old => ({
        ...old,
        balancExceeded: 'SUCCESS',
      }));
    }

    if (tomorrow > startDateUTC) {
      setError('start_date', {
        message: 'A data inicial não pode ser inferir à data atual.',
      });
    }

    // não pode ter sobra de saldo menor que 5 dias
    if (remainder < 5 && remainder !== 0) {
      setValidations(old => ({
        ...old,
        balancelessThanFive: 'ERROR',
      }));
    } else {
      setValidations(old => ({
        ...old,
        balancelessThanFive: 'SUCCESS',
      }));
    }

    // é necessário solicitar no mínimo 5 dias
    if (selectedDays < 5) {
      setValidations(old => ({
        ...old,
        requestFiveMinimum: 'ERROR',
      }));
    } else {
      setValidations(old => ({
        ...old,
        requestFiveMinimum: 'SUCCESS',
      }));
    }

    if (selectedDays > actualBalance || (remainder < 5 && remainder !== 0) || selectedDays < 5) {
      setLoading(false);
      return;
    }

    if (isDateInRange(data.start_date, allRequests)) {
      addToast(
        `Parece que já existe uma solicitação para o período escolhido. Certifique-se de que a data inicial não está dentro do intervalo de alguma solicitação existente.`,
        { appearance: 'warning', toastStyle: { zIndex: 1 } },
      );
      return;
    }

    setLoading(true);

    const newData: RequestVacationByCompany = {
      ...data,
      isWageNegotiation: isWageNegotiation,
    };

    if (type === 'REQUEST') {
      requestVacationByManager(collaboratorId, acquisitivePeriodId, companyId, newData)
        .then(response => {
          setRequests(old => [response, ...old]);
          setAcquisitiveItem(old => ({
            ...old,
            balance_days: old.balance_days - response.spent_balance,
          }));
          handleClose();
          setExpandRow(true);
          addToast(`A solicitação de férias foi criada com sucesso.`, {
            appearance: 'success',
          });
        })
        .catch(err => {
          setLoading(false);
          addToast('Houve um erro inesperado.', { appearance: 'error' });
          console.error(err);
        });
    } else {
      programVacationByCompany(collaboratorId, acquisitivePeriodId, companyId, newData)
        .then(response => {
          setRequests(old => [response, ...old]);
          handleClose();
          setExpandRow(true);
          setAcquisitiveItem(old => ({
            ...old,
            balance_days: old.balance_days - response.spent_balance,
          }));
          addToast(`A programação das férias criada com sucesso.`, {
            appearance: 'success',
          });
        })
        .catch(err => {
          setLoading(false);
          addToast('Houve um erro inesperado.', { appearance: 'error' });
          console.error(err);
        });
    }
  };

  const employementType = getEmployementType(item.collaborator.employment_type);
  const isCLT = employementType?.value === 'FULL_TIME';

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={
        type === 'REQUEST'
          ? isCLT
            ? t('schedule_vacation')
            : t('schedule_rest')
          : isCLT
          ? t('schedule_vacation')
          : t('schedule_rest')
      }
      icon={<VacationIcon />}
    >
      {loading ? <LoadingComponent /> : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <GridContainer style={{ marginBottom: '15px' }}>
            <GridRow>
              <GridCol xs={12} sm={12} lg={12}>
                <HelpersContainer
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <HelpForm
                    type={validations.balancExceeded}
                    text="Os dias selecionados não devem exceder o saldo disponível."
                  />
                  <HelpForm
                    type={validations.balancelessThanFive}
                    text="Não pode ter sobra de saldo menor que 5 dias."
                  />
                  <HelpForm type={validations.requestFiveMinimum} text="É necessário solicitar no mínimo 5 dias." />
                </HelpersContainer>
              </GridCol>
            </GridRow>
          </GridContainer>
          <GridContainer>
            <GridRow>
              <GridCol xs={12} sm={6} lg={6}>
                <FieldWrapped>
                  <Label required={true}>Início</Label>
                  <InputBase
                    hasError={Boolean(errors.start_date)}
                    type="date"
                    min={dateMin}
                    {...register('start_date')}
                    onBlur={handleStartDate}
                  />
                  {errors.start_date ? <HelperText color="danger">{errors.start_date.message}</HelperText> : null}
                </FieldWrapped>
              </GridCol>
              <GridCol xs={12} sm={6} lg={6}>
                <FieldWrapped>
                  <Label required={true}>Término</Label>
                  <InputBase
                    hasError={Boolean(errors.end_date)}
                    type="date"
                    min={endDateValidation.min}
                    max={endDateValidation.max}
                    {...register('end_date')}
                    onBlur={handleEndDate}
                  />
                  {errors.end_date ? <HelperText color="danger">{errors.end_date.message}</HelperText> : null}
                </FieldWrapped>
              </GridCol>
            </GridRow>

            <GridRow>
              <GridCol xs={12} sm={12} lg={12}>
                <AcquisitiveInfo>
                  <span>
                    Período concessivo: {formateDate(item.concessive_start_date)} -{' '}
                    {formateDate(item.concessive_end_date)}
                  </span>
                  <span>Solicitados: /{item.balance_days}</span>
                </AcquisitiveInfo>
              </GridCol>
            </GridRow>

            <GridRow>
              <GridCol xs={12} sm={12} lg={12} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <MoneyIcon />

                <Checkbox
                  disabled={hasValidNegotiation}
                  title={
                    hasValidNegotiation
                      ? ''
                      : type === 'REQUEST'
                      ? 'Abono pecuniário não disponível para esta solicitação.'
                      : 'Indisponível para esta programação.'
                  }
                  onChange={() => setIsWageNegotiation(!isWageNegotiation)}
                  label={
                    type === 'REQUEST'
                      ? 'Deseja solicitar abono pecuniário de 10 dias?'
                      : 'Deseja programar abono pecuniário de 10 dias?'
                  }
                />
              </GridCol>
            </GridRow>

            {showDisabledInput ? (
              <GridRow>
                <GridCol xs={12} sm={6} lg={6}>
                  <FieldWrapped>
                    <Label>Dias solicitados</Label>
                    <InputBase disabled={true} value={selectedDaysValue} />
                  </FieldWrapped>
                </GridCol>
                <GridCol xs={12} sm={6} lg={6}>
                  <FieldWrapped>
                    <Label>Saldo restante de dias</Label>
                    <InputBase value={remainderValue} disabled={true} />
                  </FieldWrapped>
                </GridCol>
              </GridRow>
            ) : null}
            <GridRow>
              <GridCol xs={12} sm={12} lg={12}>
                <FieldWrapped>
                  <Label required={true}>Motivo</Label>
                  <TextareaBase hasError={Boolean(errors.note)} {...register('note')} />
                  {errors.note ? <HelperText color="danger">{errors.note.message}</HelperText> : null}
                </FieldWrapped>
              </GridCol>
            </GridRow>
          </GridContainer>
        </ModalContent>
        <ModalActions>
          <OutlinedButton size="small" type="button" onClick={handleClose}>
            Cancelar
          </OutlinedButton>
          <Button size="small" type="submit">
            Confirmar
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};

export default RequestVacationModal;
