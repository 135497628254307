import React, { useState, useEffect } from 'react';
import { CollapseButton, FooterSidebar, Header, LayoutContainer, Main, Sidebar } from './styles';
import Menu from './Menu';
import { ReactComponent as CollapseIcon } from './icons/collapse-menu.svg';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import InHeader from 'safira-app/components/InHeader';
import { useProfile } from 'hooks/useProfile';
import { useAuth } from 'safira-app/hooks/useAuth';
import api from 'services/api';

type LayoutProps = {
  container?: boolean;
};

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({ container, children }) => {
  const { profile } = useProfile();
  const { user, signOut } = useAuth();
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(localStorage.getItem('isMenuCollapsed') === 'true');

  useEffect(() => {
    localStorage.setItem('isMenuCollapsed', isMenuCollapsed.toString());
  }, [isMenuCollapsed]);

  const handleMenuCollapseToggle = () => {
    setIsMenuCollapsed(prevIsMenuCollapsed => !prevIsMenuCollapsed);
  };

  return (
    <LayoutContainer container={container}>
      <Header>{profile && <InHeader me={profile} signOut={signOut} api={api} user={user} />}</Header>

      <Sidebar isCollapsed={isMenuCollapsed}>
        <Menu isMenuCollapsed={isMenuCollapsed} setMenuCollapsed={setIsMenuCollapsed} />
        <FooterSidebar>
          <CollapseButton onClick={handleMenuCollapseToggle} isCollapsed={isMenuCollapsed}>
            <CollapseIcon />
          </CollapseButton>
        </FooterSidebar>
      </Sidebar>

      {isMenuCollapsed && (
        <>
          <Tooltip anchorSelect=".dashboard-menu" content="Dashboard" place="right" />
          <Tooltip anchorSelect=".collaborators-menu" content="Colaboradores" place="right" />
          <Tooltip anchorSelect=".records-menu" content="Cadastros" place="right" />
        </>
      )}

      <Main isCollapsed={isMenuCollapsed}>{children}</Main>
    </LayoutContainer>
  );
};

export default Layout;
