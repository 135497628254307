export default () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4574_224444"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="23"
        height="22"
      >
        <path
          d="M12.0005 22C13.314 22.0017 14.6148 21.7438 15.8283 21.2412C17.0418 20.7385 18.144 20.001 19.0715 19.071C20.0015 18.1435 20.739 17.0413 21.2417 15.8278C21.7443 14.6143 22.0022 13.3135 22.0005 12C22.0022 10.6865 21.7443 9.3857 21.2416 8.17222C20.739 6.95875 20.0015 5.85656 19.0715 4.92901C18.144 3.99902 17.0418 3.26151 15.8283 2.75885C14.6148 2.25619 13.314 1.99831 12.0005 2.00001C10.687 1.99833 9.38619 2.25623 8.17271 2.75889C6.95923 3.26154 5.85705 3.99904 4.9295 4.92901C3.99953 5.85656 3.26203 6.95875 2.75938 8.17222C2.25672 9.3857 1.99882 10.6865 2.0005 12C1.99879 13.3135 2.25668 14.6143 2.75934 15.8278C3.262 17.0413 3.99951 18.1435 4.9295 19.071C5.85705 20.001 6.95923 20.7385 8.17271 21.2411C9.38619 21.7438 10.687 22.0017 12.0005 22Z"
          fill="white"
          stroke="white"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M8.00049 12L11.0005 15L17.0005 9"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </mask>
      <g mask="url(#mask0_4574_224444)">
        <path d="M0 0H24V24H0V0Z" fill="#198754" />
      </g>
    </svg>
  );
};
