export const EmployementsTypeList = [
  { value: "FULL_TIME", label: "CLT" },
  { value: "LEGAL_ENTITY", label: "Empresa (PJ)" },
  { value: "TEMPORARY", label: "Temporário" },
  { value: "INTERMITTENT", label: "Intermitente" },
  { value: "REMOTE", label: "Remoto" },
  { value: "OUTSOURCING", label: "Terceirizado" },
  { value: "SELF_EMPLOYED", label: "Autônomo" },
  { value: "INTERNSHIP", label: "Estágio" },
  { value: "APPRENTICESHIP", label: "Aprendiz" },
  { value: "VOLUNTEER", label: "Voluntário" },
  { value: "PART_TIME", label: "Meio período" },
  { value: "FREELANCE", label: "Freelancer" },
  { value: "CONTRACT", label: "Contrato" },
  { value: "PARTNER", label: "Sócio" },
  { value: "COOPERATED", label: "Cooperado" },
  { value: "INVESTOR", label: "Investidor" },
  { value: "CONSULTANCY", label: "Consultoria" },
  { value: "SCHOLARSHIP", label: "Bolsista" },
  { value: "OWNER", label: "Proprietário" },
  { value: "STUDENT", label: "Estudante" },
  {
    value: "AUTONOMOUS_REPRESENTATIVE",
    label: "Representante Comercial Autônomo",
  },
  { value: "COMMISSIONED", label: "Comissionado" },
  { value: "ASSOCIATED", label: "Associado" },
];

export const getEmployementType = (value: string) => {
  const employementType = EmployementsTypeList.find(item => item.value === value);
  return employementType ? employementType : undefined;
}