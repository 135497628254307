import styled from "styled-components";

export const Input = styled.input`
  height: 38px;
  border: #afafaf 1px solid;
  border-radius: 4px;
  background: #ffffff;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  &:focus,
  &:focus-visible {
    border: 2px solid blue !important;
  }
`;

export const SearchInputStyle = styled.div`
  position: relative;
  display: inline-block;
`;

export const SearchButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 38px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;
