const OccurrencesIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.49671 7.68962L3.09283 8.79095V6.31696L0.946045 7.6577C1.03181 7.6116 1.12868 7.59018 1.22589 7.59582C1.3231 7.60145 1.41684 7.63392 1.49671 7.68962Z"
        fill="#E88840"
      />
      <path
        d="M18.767 7.68964C18.8492 7.63703 18.9447 7.60907 19.0423 7.60907C19.1399 7.60907 19.2354 7.63703 19.3176 7.68964L17.115 6.32495V8.83885L18.767 7.68964Z"
        fill="#E88840"
      />
      <path
        d="M15.2554 0.666687H10.1318V13.5075C10.2024 13.5089 10.2718 13.4895 10.3314 13.4516L17.1229 8.80692V2.54213C17.1229 2.04611 16.9264 1.57029 16.5764 1.21881C16.2264 0.86732 15.7514 0.668798 15.2554 0.666687Z"
        fill="#A6E1FF"
      />
      <path
        d="M4.97628 0.666687C4.47888 0.666687 4.00185 0.864278 3.65014 1.21599C3.29842 1.56771 3.10083 2.04473 3.10083 2.54213V8.79096L9.89234 13.4516C9.96335 13.4972 10.0479 13.517 10.1318 13.5075V0.666687H4.97628Z"
        fill="#DEF5FC"
      />
      <path
        d="M9.89235 13.4516L3.10084 8.79096L1.50472 7.68963C1.42186 7.63883 1.32657 7.61194 1.22938 7.61194C1.1322 7.61194 1.03691 7.63883 0.954053 7.68963C0.867322 7.73389 0.794557 7.80132 0.743839 7.88444C0.693122 7.96756 0.666438 8.06312 0.666751 8.16049V17.7372C0.666751 18.1606 0.834913 18.5665 1.13424 18.8659C1.43358 19.1652 1.83956 19.3334 2.26288 19.3334H10.1318V13.5075C10.0479 13.517 9.96336 13.4972 9.89235 13.4516Z"
        fill="#FFE3AF"
      />
      <path
        d="M19.3175 7.65765C19.2353 7.60505 19.1398 7.57709 19.0422 7.57709C18.9446 7.57709 18.8491 7.60505 18.7669 7.65765L17.1229 8.77494L10.3314 13.4197C10.2718 13.4575 10.2024 13.4769 10.1318 13.4755V19.2455H18.0247C18.448 19.2455 18.854 19.0774 19.1533 18.778C19.4526 18.4787 19.6208 18.0727 19.6208 17.6494V8.07264C19.6099 7.98333 19.5763 7.89829 19.5232 7.82565C19.4701 7.753 19.3993 7.69516 19.3175 7.65765Z"
        fill="#FFB04B"
      />
      <path
        d="M3.01322 17.5617C2.90883 17.5535 2.80892 17.5158 2.72521 17.4529C2.64151 17.39 2.57747 17.3045 2.54061 17.2065C2.50376 17.1085 2.49561 17.002 2.51712 16.8995C2.53863 16.7971 2.58891 16.7028 2.66207 16.6279L4.10656 15.1834L5.63884 13.6512C5.68613 13.5923 5.74533 13.5442 5.81257 13.5099C5.8798 13.4755 5.95353 13.4559 6.02892 13.4521C6.10431 13.4483 6.17964 13.4605 6.24995 13.488C6.32027 13.5154 6.38399 13.5574 6.43691 13.6112C6.49099 13.6627 6.53406 13.7246 6.56349 13.7932C6.59292 13.8618 6.60809 13.9356 6.60809 14.0103C6.60809 14.0849 6.59292 14.1588 6.56349 14.2274C6.53406 14.296 6.49099 14.3579 6.43691 14.4093C5.46327 15.3989 4.47367 16.3805 3.49206 17.3621C3.43232 17.4292 3.35826 17.4819 3.27537 17.5165C3.19248 17.551 3.10289 17.5664 3.01322 17.5617Z"
        fill="#FEB04B"
      />
      <path
        d="M17.7692 17.0189C17.7792 17.1295 17.7504 17.2402 17.6878 17.3319C17.6252 17.4236 17.5327 17.4907 17.426 17.5217C17.3343 17.5676 17.2301 17.5823 17.1293 17.5636C17.0285 17.5449 16.9365 17.4938 16.8674 17.418L13.8347 14.3933C13.7295 14.2872 13.6704 14.1438 13.6704 13.9943C13.6704 13.8448 13.7295 13.7014 13.8347 13.5953C13.8842 13.5379 13.9454 13.4918 14.0143 13.4603C14.0832 13.4287 14.158 13.4124 14.2338 13.4124C14.3095 13.4124 14.3844 13.4287 14.4533 13.4603C14.5221 13.4918 14.5834 13.5379 14.6328 13.5953L17.6096 16.5641C17.6716 16.6209 17.7186 16.6921 17.7464 16.7715C17.7743 16.8508 17.7821 16.9358 17.7692 17.0189Z"
        fill="#E78840"
      />
      <path
        d="M11.1853 2.64586L10.1318 3.18855V8.59941L11.4726 9.27776V2.51019L11.1853 2.64586Z"
        fill="#4F83BC"
      />
      <path
        d="M12.5979 1.50464H11.8477C11.6406 1.50464 11.4727 1.67257 11.4727 1.87973V9.90026C11.4727 10.1074 11.6406 10.2753 11.8477 10.2753H12.5979C12.8051 10.2753 12.973 10.1074 12.973 9.90026V1.87973C12.973 1.67257 12.8051 1.50464 12.5979 1.50464Z"
        fill="#CC6F75"
      />
      <path
        d="M6.38103 4.09036C6.21712 4.09826 6.06216 4.16746 5.94687 4.28423C5.83157 4.40101 5.76436 4.55683 5.75854 4.72083C5.75854 5.51889 5.75854 6.27705 5.75854 7.05117C5.76009 7.21114 5.82164 7.3647 5.93101 7.48146C6.04037 7.59821 6.18958 7.66965 6.34911 7.68164C6.66522 7.70555 6.98269 7.70555 7.2988 7.68164C7.2988 6.47657 7.2988 5.28746 7.2988 4.0744C6.99554 4.09834 6.6843 4.08238 6.38103 4.09036Z"
        fill="#FB99A1"
      />
      <path
        d="M14.4973 5.58274C14.4861 5.40588 14.4235 5.23613 14.3171 5.09436C14.2108 4.95258 14.0654 4.84495 13.8987 4.78468C13.6034 4.67295 13.2842 4.59314 12.981 4.49738C12.981 5.43111 12.981 6.35686 12.981 7.29059L13.779 7.04319C13.9889 6.98478 14.1735 6.85818 14.3035 6.68335C14.4335 6.50852 14.5017 6.29539 14.4973 6.07754C14.5132 5.91793 14.5132 5.75033 14.4973 5.58274Z"
        fill="#4F83BC"
      />
      <path
        d="M9.19805 3.66737C8.60153 3.93244 7.95911 4.07882 7.30664 4.09833C7.30664 5.3034 7.30664 6.49252 7.30664 7.70557C7.42635 8.37594 7.56202 9.06228 7.68971 9.73265C7.8174 10.403 7.80144 10.3312 8.40797 10.3232C8.67931 10.3232 8.76709 10.2274 8.76709 9.95611V7.98489C8.80506 7.97553 8.84457 7.97425 8.88306 7.98112C8.92155 7.98799 8.95817 8.00287 8.99055 8.0248L9.8764 8.46373L10.1398 8.5994V3.18854L9.19805 3.66737Z"
        fill="#528FD7"
      />
    </svg>
  );
};

export default OccurrencesIcon;
