import React from 'react';
import { InCheckItemFinished } from 'requests/inCheck/types';
import { Table, TableBody, TableHead, TableHeaderCell } from 'components/Tables/styles';
import InCheckTableItem from './InCheckTableItem';
import { useTranslation } from 'react-i18next';

interface InCheckProps {
  inChecks: InCheckItemFinished[];
}

const InCheckTable: React.FC<InCheckProps> = ({ inChecks }) => {
  const { t } = useTranslation();
  return (
    <Table>
      <TableHead>
        <TableHeaderCell style={{ width: '200px' }}>{t('incheck.name')}</TableHeaderCell>
        <TableHeaderCell style={{ width: '200px' }}>{t('incheck.unit')}</TableHeaderCell>
        <TableHeaderCell style={{ width: '200px' }}>{t('incheck.sector')}</TableHeaderCell>
        <TableHeaderCell style={{ width: '100px' }}>{t('incheck.start')}</TableHeaderCell>
        <TableHeaderCell style={{ width: '100px' }}>{t('incheck.end')}</TableHeaderCell>
        <TableHeaderCell style={{ textAlign: 'center', width: '60px' }}>{t('incheck.grade')}</TableHeaderCell>
        <TableHeaderCell style={{ textAlign: 'center', width: '150px' }}>{t('incheck.progress')}</TableHeaderCell>
        <TableHeaderCell style={{ textAlign: 'center', width: '30px' }}>{t('incheck.pdi')}</TableHeaderCell>
      </TableHead>
      <TableBody>
        {inChecks.map(inCheck => (
          <InCheckTableItem key={inCheck.id} inCheck={inCheck} />
        ))}
        {!inChecks.length && (
          <div style={{ padding: 8 }}>
            <p>Não há InChecks disponíveis</p>
          </div>
        )}
      </TableBody>
    </Table>
  );
};

export default InCheckTable;
