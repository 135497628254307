import styled, { css } from "styled-components";

export const MoreButton = styled.button`
  padding: 8px 12px;
  background: transparent;
  color: #787878;
  border: none;

  &:hover {
    filter: brightness(0.25);
    cursor: pointer;
  }
`;

type ButtonProps = {
  size?: "small";
  color?: "primary" | "danger";
};

export const Button = styled.button<ButtonProps>`
  padding-right: 12px;
  padding-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ size }) => (size === "small" ? "38px" : "44px")};
  color: #ffffff;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:disabled {
    background-color: gray !important;
    cursor: not-allowed;
  }

  ${({ color, theme }) =>
    color === "danger"
      ? css`
          background-color: ${theme.colors.danger};
          &:hover {
            background-color: ${({ theme }) => theme.colors.dangerDark};
          }
        `
      : css`
          background-color: ${({ theme }) => theme.colors.primary};
          &:hover {
            background-color: ${({ theme }) => theme.colors.primaryDark};
          }
        `}

  ${({ size }) =>
    size === "small" &&
    css`
      font-size: 14px;
    `}
`;

export const OutlinedButton = styled.button<ButtonProps>`
  padding-right: 12px;
  padding-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ size }) => (size === "small" ? "38px" : "44px")};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary};
  border: 1.5px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  transition: background-color 0.3s;
  transition: color 0.3s;
  transition: border 0.3s;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
    border: 1.5px solid ${({ theme }) => theme.colors.primaryDark};
    background: #f6f6f6;
  }

  &:disabled {
    color: gray !important;
    border-color: grey !important;
    cursor: not-allowed;
  }

  ${({ size }) =>
    size === "small" &&
    css`
      font-size: 14px;
    `}
`;

export const OutlinedLink = styled.a<ButtonProps>`
  padding-right: 12px;
  padding-left: 12px;
  display: flex;
  width: fit-content;
  text-decoration: none !important;
  justify-content: center;
  align-items: center;
  height: ${({ size }) => (size === "small" ? "38px" : "44px")};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary};
  border: 1.5px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  transition: background-color 0.3s;
  transition: color 0.3s;
  transition: border 0.3s;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
    border: 1.5px solid ${({ theme }) => theme.colors.primaryDark};
    background: #f6f6f6;
  }

  ${({ size }) =>
    size === "small" &&
    css`
      font-size: 14px;
    `}
`;

interface IconButtonProps {
  size?: number;
}

export const IconButton = styled.button<IconButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => (size ? `${size}px` : "30px")};
  height: ${({ size }) => (size ? `${size}px` : "30px")};
  border-radius: 50%;
  border: none;
  background: #fff;

  :hover {
    filter: brightness(0.9);
    background-color: #f5f5f5 !important;
  }

  svg {
    width: ${({ size }) => (size ? size / 2 : 30 / 2)} !important;
    height: auto !important;
  }
`;

export const DashedButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  height: 38px;
  border: none;
  font-weight: 600;
  background: transparent;
  gap: 6px;

  svg {
    width: 14px;
  }

  span {
    border-bottom: 2px #F64650 dashed;
    color: #F64650;
  }
`