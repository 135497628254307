import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import pt_BR from 'locale/pt_BR.json';
import safira_pt_BR from 'safira-app/locale/pt_BR.json';

import en_US from 'locale/en_US.json';
import safira_en_US from 'safira-app/locale/en_US.json';

import Cookies from 'js-cookie';

const resources = {
  'pt-BR': {
    translation: {
      ...pt_BR,
      ...safira_pt_BR,
    },
  },
  'en-US': {
    translation: {
      ...en_US,
      ...safira_en_US
    }
  },
};

const lng = Cookies.get('default_language');

i18n.use(initReactI18next).init({
  resources,
  lng: lng ?? 'pt-BR',
});
