export type VacationRequestType =
  | "PENDING"
  | "APPROVED"
  | "REFUSED"
  | "APPROVED_BY_MANAGER"
  | "REFUSED_BY_MANAGER"
  | "ON_VACATION"
  | "DONE";

export type VacationStatus =
  | "EXPIRED"
  | "EXPIRE_IN_30"
  | "EXPIRE_IN_60"
  | "EXPIRE_IN_90"
  | "OPEN"
  | "APPROVED"
  | "NOT_AVAILABLE"
  | "DONE";

export enum VacationRequestStatusEnum {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REFUSED = "REFUSED",
  APPROVED_BY_MANAGER = "APPROVED_BY_MANAGER",
  REFUSED_BY_MANAGER = "REFUSED_BY_MANAGER",
  ON_VACATION = "ON_VACATION",
  DONE = "DONE",
}

export interface GeneralVacationItem {
  id: string;
  acquisitive_start_date: string;
  acquisitive_end_date: string;
  concessive_start_date: string;
  concessive_end_date: string;
  balance_days: number;
  status: VacationStatus;
  collaborator: {
    id: string;
    employment_type: string;
    person: {
      name: string;
      avatar: string;
    };
  };
  requests: Array<RequestVacationByCompanyItem>;
}

export interface RequestVacationByCompany {
  start_date: string;
  end_date: string;
  note: string;
  isWageNegotiation: boolean;
}

export interface ManagersApprovals {
  is_approved: boolean;
  collaborator: {
    id: string;
    person: {
      name: string;
      avatar: string;
    };
  };
}
export interface RequestVacationByCompanyItem extends RequestVacationByCompany {
  id: string;
  status: VacationRequestType;
  spent_balance: number;
  requested_by: {
    avatar: string;
    name: string;
  };
  managers_approvals: Array<ManagersApprovals>;
}

export interface ResquestAnswerByManager {
  answer: boolean;
  note: string;
}
