import api from 'services/api';
import { ActionPlanParams, ActionPlanStatus, InCheckItemFinished, InCheckParams } from './types';
import { Paginate } from 'interfaces/Paginate';
import { links } from 'safira-app/config/links';

export function getFinishedInChecks(params: Omit<InCheckParams, 'finished'>) {
  const { companyId, ...rest } = params || {};

  return api.get<Paginate<InCheckItemFinished>>('/in-check/evaluations/managers', {
    baseURL: links.api.evaluation,
    params: { ...rest },
    headers: { companyId: companyId },
  });
}

export function getActionPlanStatus(params: ActionPlanParams) {
  const { companyId, ...rest } = params || {};

  return api.get<ActionPlanStatus>('/in-check/plans/status', {
    baseURL: links.api.evaluation,
    params: { ...rest },
    headers: { companyId },
  });
}
