import React, { FC, useMemo } from 'react';
import Chart from 'react-apexcharts';

import Flex from 'components/Flex';
import { useRequest } from 'hooks/useRequest';
import { getActionPlanStatus } from 'requests/inCheck';
import { usePermissions } from 'safira-app/contexts/Permissions';
import { ApexOptions } from 'apexcharts';
import { useTranslation } from 'react-i18next';

const ActionPlanStatusGraph: FC = () => {
  const { t } = useTranslation();
  const { companyId } = usePermissions();

  const actionPlanRequest = useRequest({
    fn: () => (companyId ? getActionPlanStatus({ companyId }) : null),
    deps: [companyId],
  });

  const actionPlanStatus = actionPlanRequest.response?.data || null;
  const actionPlanError = actionPlanRequest.error;

  const { notRegisteredPercentage, performedPercentage, progressPercentage } = useMemo(() => {
    const total = Object.values(actionPlanStatus || {}).reduce((acc, curr) => acc + curr, 0);

    return {
      notRegisteredPercentage: Math.floor(((actionPlanStatus?.notRegistered || 0) / total) * 100),
      progressPercentage: Math.floor(((actionPlanStatus?.progress || 0) / total) * 100),
      performedPercentage: Math.floor(((actionPlanStatus?.performed || 0) / total) * 100),
    };
  }, [actionPlanStatus]);

  const options: ApexOptions = {
    series: [notRegisteredPercentage, progressPercentage, performedPercentage],
    colors: ['#7f7f7f', '#ed9f33', '#22ee11'],
    labels: [
      `${t('incheck.not_registered')}: ${notRegisteredPercentage}%`,
      `${t('incheck.in_progress')}: ${progressPercentage}%`,
      `${t('incheck.completed')}: ${performedPercentage}%`,
    ],
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <Flex
      style={{
        width: '100%',
        maxWidth: 350,
        minWidth: 300,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!actionPlanError && <Chart options={options} series={options.series} type="donut" width="350px" />}
      {actionPlanError && <p style={{ color: '' }}>Erro ao carregar os dados</p>}
    </Flex>
  );
};

export default ActionPlanStatusGraph;
