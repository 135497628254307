import AWS from "aws-sdk";
import { links } from "safira-app/config/links";

AWS.config.update({
  accessKeyId: links.aws.access_key_id,
  secretAccessKey: links.aws.secret_access_key,
  region: links.aws.region,
});

const bucket = links.aws.bucket;

export const s3 = new AWS.S3();

export function encode(data: any) {
  var str = data.reduce(function (a: any, b: any) {
    return a + String.fromCharCode(b);
  }, "");

  return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
}

export const getS3Object = (src: string, contentType?: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    s3.getObject(
      {
        Bucket: `${bucket}`,
        Key: src,
      },
      (error: AWS.AWSError, data: AWS.S3.GetObjectOutput) => {
        if (error) {
          reject(error);
        } else {
          const url = `data:${contentType || "image/jpg"};base64,` + encode(data.Body);
          resolve(url);
        }
      },
    );
  });
};

export const getS3Attachment = (src: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    s3.getObject(
      {
        Bucket: `${bucket}`,
        Key: src,
      },
      (error: AWS.AWSError, data: AWS.S3.GetObjectOutput) => {
        if (error) {
          reject(error);
        } else {
          const url = data.Body;
          resolve(url as any);
        }
      },
    );
  });
};
