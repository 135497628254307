import Select, { CSSObjectWithLabel } from 'react-select';
import Flex from 'components/Flex';
import { Label } from 'components/Forms/Label';

export type OptionLabel = {
  value: string;
  label: string;
};

export type MultiSelectionProps<T> = {
  label?: string;
  placeholder?: string;
  options: T[];
  inputProps?: React.SelectHTMLAttributes<HTMLSelectElement>;
  helperText?: string | null;
  styles?: CSSObjectWithLabel;
  onChange?: (options: OptionLabel[]) => void;
  getOptionLabel: (option: T) => OptionLabel;
};

function MultiSelection<T extends any>(props: MultiSelectionProps<T>) {
  const { label, options, helperText, placeholder, styles, getOptionLabel, onChange } = props;

  return (
    <Flex>
      <Label>{label}</Label>
      <Select
        isMulti
        closeMenuOnSelect={false}
        placeholder={placeholder || ''}
        options={options.map(option => getOptionLabel(option))}
        onChange={newOptions => onChange?.(newOptions as any)}
        styles={{
          control: (base, { isDisabled, isFocused }) => ({
            ...base,
            width: 290,
            borderColor: helperText
              ? 'red'
              : isFocused
              ? // @ts-ignore
                '#008ac1'
              : 'rgb(175, 175, 175)',
            backgroundColor: isDisabled ? '#e9e9e9' : '#fff',
            fontSize: '14px',
            '&:hover': {
              // @ts-ignore
              borderColor: helperText ? '#d32f2f' : '#008ac1',
            },
            '&:focus': {
              // @ts-ignore
              borderColor: helperText ? '#d32f2f' : '#008ac1',
              boxShadow: `0 0 0 1px ${helperText ? '#d32f2f' : '#008ac1'}`,
            },
            '&:focus-within': {
              // @ts-ignore
              borderColor: helperText ? '#d32f2f' : '#008ac1',
              boxShadow: `0 0 0 1px ${helperText ? '#d32f2f' : '#008ac1'}`,
            },
            '&:focus-visible': {
              // @ts-ignore
              borderColor: helperText ? '#d32f2f' : '#008ac1',
              boxShadow: `0 0 0 1px ${helperText ? '#d32f2f' : '#008ac1'}`,
            },
            ...styles,
          }),
        }}
      />
      {helperText && <p style={{ color: '#d32f2f', fontSize: 12 }}>{helperText}</p>}
    </Flex>
  );
}

export default MultiSelection;
