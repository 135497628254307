import React from 'react';
import { DateTime } from 'luxon';
import { NavLink } from 'react-router-dom';

import Flex from 'components/Flex';
import ViewManyItems from 'components/ViewManyItems';
import LinearProgress from 'components/LinearProgress';
import { links } from 'safira-app/config/links';
import { TableCell, TableRow } from 'components/Tables/styles';
import { DashedButton } from 'pages/Evaluation/SurveyItem/styles';
import { InCheckItemFinished } from 'requests/inCheck/types';
import { useTranslation } from 'react-i18next';

interface MonitoringTableItemProps {
  inCheck: InCheckItemFinished;
}

export function getInCheckProgress(progressData: InCheckItemFinished['progress']) {
  if (!progressData?.total) {
    return null;
  }

  const progress = progressData?.completed || 0;
  const total = progressData?.total || 0;

  return Math.round((progress / total) * 100);
}

const InCheckTableItem: React.FC<MonitoringTableItemProps> = ({ inCheck }) => {
  const { t } = useTranslation();
  return (
    <TableRow>
      <TableCell>{inCheck.title}</TableCell>
      <TableCell>
        <ViewManyItems
          options={inCheck.units}
          getOptionLabel={unit => ({
            id: unit.id,
            label: unit.name,
          })}
        />
      </TableCell>
      <TableCell>
        <ViewManyItems
          options={inCheck.sectors}
          getOptionLabel={sector => ({
            id: sector.id,
            label: sector.name,
          })}
        />
      </TableCell>
      <TableCell>{DateTime.fromISO(inCheck.start).toLocaleString()}</TableCell>
      <TableCell>{DateTime.fromISO(inCheck.end).toLocaleString()}</TableCell>
      <TableCell style={{ textAlign: 'center' }}>{inCheck.result || '- -'}</TableCell>
      <TableCell style={{ textAlign: 'center' }}>
        {typeof inCheck.progress?.total !== 'number' && <p>- -</p>}

        {typeof inCheck.progress?.total === 'number' && (
          <Flex style={{ flexDirection: 'row', alignItems: 'center', gap: 4, width: '100%' }}>
            <LinearProgress color="#8BC34A" progress={getInCheckProgress(inCheck.progress)!} />
            <span style={{ fontSize: 12 }}>{getInCheckProgress(inCheck.progress)}%</span>
          </Flex>
        )}
      </TableCell>
      <TableCell style={{ width: '120px' }}>
        <Flex
          styled={css => css`
            width: 100%;
            flex-direction: row;
            justify-content: center;
          `}
        >
          <NavLink
            to={
              inCheck?.project_id
                ? `${links.web.project}/kanban/${inCheck.project_id}`
                : `${links.web.incheck}/p/manager/action-plan/create/${inCheck.id}`
            }
          >
            <DashedButton id="show-plan-button">
              {inCheck?.project_id ? t('incheck.view_plan') : t('incheck.create_plan')}
            </DashedButton>
          </NavLink>
        </Flex>
      </TableCell>
    </TableRow>
  );
};

export default InCheckTableItem;
