import React from "react";
import { MonitoringProvider } from "context/MonitoringPDIContext";
import EvaluationComponent from "./EvaluationComponent";

const EvaluationPage: React.FC = () => {
  return (
    <MonitoringProvider>
      <EvaluationComponent />
    </MonitoringProvider>
  );
};

export default EvaluationPage;
