const MoneyIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#008AC1" />
      <path
        d="M8.23496 12.8C8.08385 12.8 7.95709 12.7488 7.85469 12.6464C7.75229 12.544 7.70127 12.4174 7.70163 12.2666V11.6533C7.30163 11.5644 6.95052 11.4088 6.64829 11.1866C6.34607 10.9644 6.10163 10.6533 5.91496 10.2533C5.85274 10.1288 5.85061 9.99764 5.90856 9.85969C5.96652 9.72173 6.07087 9.62182 6.22163 9.55995C6.34607 9.50662 6.47496 9.50893 6.6083 9.56689C6.74163 9.62484 6.84385 9.72031 6.91496 9.85329C7.06607 10.12 7.25718 10.3221 7.48829 10.4597C7.71941 10.5973 8.00385 10.6663 8.34163 10.6666C8.70607 10.6666 9.01487 10.5843 9.26803 10.4197C9.52118 10.2551 9.64794 9.9996 9.64829 9.65329C9.64829 9.34218 9.55052 9.0956 9.35496 8.91355C9.15941 8.73151 8.70607 8.52475 7.99496 8.29329C7.23052 8.05329 6.70607 7.76653 6.42163 7.43302C6.13718 7.09951 5.99496 6.69293 5.99496 6.21329C5.99496 5.63551 6.18163 5.18662 6.55496 4.86662C6.92829 4.54662 7.31052 4.3644 7.70163 4.31995V3.73329C7.70163 3.58218 7.75283 3.45542 7.85523 3.35302C7.95763 3.25062 8.08403 3.1996 8.23443 3.19995C8.38554 3.19995 8.5123 3.25115 8.6147 3.35355C8.7171 3.45595 8.76812 3.58253 8.76776 3.73329V4.31995C9.10554 4.37329 9.39887 4.48226 9.64776 4.64689C9.89665 4.81151 10.1011 5.01364 10.2611 5.25329C10.3411 5.36884 10.3567 5.49773 10.308 5.63995C10.2593 5.78218 10.1592 5.8844 10.0078 5.94662C9.88332 5.99995 9.75443 6.00226 9.62109 5.95355C9.48776 5.90484 9.36332 5.81809 9.24776 5.69329C9.13221 5.56884 8.99656 5.47338 8.84083 5.40689C8.68509 5.3404 8.49185 5.30698 8.2611 5.30662C7.86998 5.30662 7.57221 5.39338 7.36776 5.56689C7.16332 5.7404 7.06127 5.95586 7.06163 6.21329C7.06163 6.50662 7.19496 6.73773 7.46163 6.90662C7.72829 7.07551 8.19052 7.25329 8.84829 7.43995C9.46163 7.61773 9.92616 7.90004 10.2419 8.28689C10.5576 8.67373 10.7153 9.12031 10.715 9.62662C10.715 10.2577 10.5283 10.7377 10.155 11.0666C9.78163 11.3955 9.31941 11.6 8.76829 11.68V12.2666C8.76829 12.4177 8.7171 12.5445 8.6147 12.6469C8.5123 12.7493 8.38572 12.8003 8.23496 12.8Z"
        fill="#F7FEFF"
      />
    </svg>
  );
};

export default MoneyIcon;
