import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin-top: 4rem;
  display: flex;
  justify-content: flex-end;

  .pagination {
    display: flex;
    list-style: none;
    cursor: pointer;
    flex-wrap: nowrap;

    li {
      margin: 0rem 0.5rem;
    }
  }

  .pagination a {
    font-size: 14px;
    padding: 0px 6px;
    border-radius: 5px;
    border: 1px solid transparent;
    color: #3caac5;
  }

  .pagination__link {
    font-weight: bold;
    &:hover {
      background: #e5e5e5;
    }
  }

  .pagination__link--active a {
    color: #fff;
    background: #008ac1;
  }

  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid transparent;
  }
`;
