import React, { useEffect, useState } from 'react';

import { addToast } from 'safira-app/components/Toast';

import Layout from 'layout/Layout';
import Flex from 'components/Flex';
import SearchField from 'components/SearchField';
import MultiSelection, { OptionLabel } from 'components/MultiSelection';
import InCheckTable from './components/InCheckTable';
import ActionPlanStatusGraph from './components/ActionPlanStatusGraph';
import Paginate from 'components/Paginate';
import { InCheckParams } from 'requests/inCheck/types';
import { getSectors, getUnits } from 'requests/engineering';
import { HeaderPage } from 'layout/components/page-styles';
import { useRequest } from 'hooks/useRequest';
import { getFinishedInChecks } from 'requests/inCheck';
import { usePermissions } from 'safira-app/contexts/Permissions';
import { CONTAINER_MAX_WIDTH } from 'layout/styles';
import { useTranslation } from 'react-i18next';

const InCheck: React.FC = () => {
  const { t } = useTranslation();
  const { companyId } = usePermissions();

  const [selectedSectors, setSelectedSectors] = useState<OptionLabel[]>([]);
  const [sectorsErrorMsg, setSectorsErrorMsg] = useState<string | null>(null);
  const [selectedUnits, setSelectedUnits] = useState<OptionLabel[]>([]);
  const [unitsErrorMsg, setUnitsErrorMsg] = useState<string | null>(null);

  const [params, setParams] = useState<Omit<InCheckParams, 'companyId'>>({
    page: 1,
    perPage: 10,
  });

  const sectorsRequest = useRequest({
    fn: () => (companyId ? getSectors({ companyId }) : null),
    deps: [companyId],
  });

  const unitsRequest = useRequest({
    fn: () => (companyId ? getUnits({ companyId }) : null),
    deps: [companyId],
  });

  const inChecksRequest = useRequest({
    fn: () =>
      companyId
        ? getFinishedInChecks({
            ...params,
            companyId,
            units: selectedUnits.map(unit => unit.value),
            sectors: selectedSectors.map(sector => sector.value),
          })
        : null,
    delayMs: 200,
    deps: [companyId, params, selectedSectors, selectedUnits],
  });

  const sectors = sectorsRequest.response?.data?.data || [];
  const sectorsError = sectorsRequest.error;
  const units = unitsRequest.response?.data?.data || [];
  const unitsError = unitsRequest.error;

  const inChecks = inChecksRequest.response?.data?.data || [];
  const inChecksCount = inChecksRequest.response?.data?.last_page || 0;
  const inChecksError = inChecksRequest.error;

  function handleChangePage(currentPage: number) {
    setParams({ ...params, page: currentPage + 1 });
  }

  function handleSearchInChecks(value: string) {
    inChecksRequest.forceLoad();
    setParams({ ...params, search: value || undefined });
  }

  useEffect(() => {
    if (inChecksError) {
      addToast('Erro ao buscar avaliações', {
        appearance: 'error',
      });
    }

    if (sectorsError) {
      setSectorsErrorMsg('Erro ao buscar setores');
    }

    if (unitsError) {
      setUnitsErrorMsg('Erro ao buscar unidades');
    }
  }, [inChecksError, sectorsError, unitsError]);

  return (
    <Layout>
      <Flex
        styled={css => css`
          width: 100%;
          max-width: ${CONTAINER_MAX_WIDTH}px;
          margin: 0 auto;
        `}
      >
        <HeaderPage>
          <h1>{t('incheck.pdi_tracking_incheck')}</h1>
        </HeaderPage>

        <Flex
          paper
          styled={css =>
            css`
              flex-direction: row;
              gap: 16px;
              margin-top: 16px;
              margin-bottom: 16px;

              h3 {
                color: #535353;
                font-weight: 600;
              }

              @media screen and (max-width: ${CONTAINER_MAX_WIDTH}px) {
                flex-direction: column;
              }
            `
          }
        >
          <Flex
            styled={css =>
              css`
                flex-direction: column;
                gap: 16px;
              `
            }
          >
            <h3>{t('incheck.action_plan_tracking')}</h3>

            <Flex styled={css => css({ flexDirection: 'row', gap: 8 })}>
              <MultiSelection
                label={t('incheck.sectors')}
                options={sectors}
                helperText={sectorsErrorMsg}
                onChange={newSectors => setSelectedSectors(newSectors)}
                getOptionLabel={sector => ({
                  value: sector.id,
                  label: sector.name,
                })}
              />
              <MultiSelection
                label={t('incheck.units')}
                options={units}
                helperText={unitsErrorMsg}
                onChange={newUnits => setSelectedUnits(newUnits)}
                getOptionLabel={unit => ({
                  value: unit.id,
                  label: unit.name,
                })}
              />
            </Flex>
          </Flex>

          <Flex styled={css => css({ flexDirection: 'column', gap: 16, width: '100%' })}>
            <h3>{t('incheck.action_plan_status')}</h3>

            <ActionPlanStatusGraph />
          </Flex>
        </Flex>

        <Flex paper>
          <SearchField
            delay={200}
            onSearch={handleSearchInChecks}
            inputProps={{
              placeholder: t('incheck.search_evaluations'),
              style: { width: 290, marginBottom: 16 },
            }}
          />
          <InCheckTable inChecks={inChecks} />
          {inChecksCount && <Paginate pageCount={inChecksCount} callback={handleChangePage} />}
        </Flex>
      </Flex>
    </Layout>
  );
};

export default InCheck;
