import api from 'services/api';
import { PaginateV2 } from 'interfaces/Paginate';
import { links } from 'safira-app/config/links';
import { Sector, SectorParams, Unit, UnitParams } from './types';

export function getSectors(params: SectorParams) {
  const { companyId, ...rest } = params;

  return api.get<PaginateV2<Sector>>('/engineering/sectors', {
    baseURL: links.api.core,
    params: rest,
    headers: { companyId },
  });
}

export function getUnits(params: UnitParams) {
  const { companyId, ...rest } = params;

  return api.get<PaginateV2<Unit>>('/engineering/units', {
    baseURL: links.api.core,
    params: rest,
    headers: { companyId },
  });
}
