import { IChartData, IParticipantPDI } from 'interfaces/Evaluation';
import React, { createContext, useContext, useEffect, useState } from 'react';

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export interface MonitoringContextProps {
  participantPDIList: IParticipantPDI[];
  setParticipantPDIList: SetState<IParticipantPDI[]>;

  dataForChart: IChartData;
  setDataForChart: SetState<IChartData>;
}

export const defaultChartData: IChartData = {
  concluded: {
    name: 'chart_data.completed',
    color: '#74AB00',
    value: 25,
  },
  not_able: {
    name: 'chart_data.not_eligible',
    color: '#FF1A20',
    value: 25,
  },
  not_registered: {
    name: 'chart_data.not_registered',
    color: '#9E9E9E',
    value: 25,
  },
  progress: {
    name: 'chart_data.in_progress',
    color: '#FFAA00',
    value: 25,
  },
};

export const MonitoringContext = createContext<MonitoringContextProps>({} as MonitoringContextProps);

const MonitoringProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [participantPDIList, setParticipantPDIList] = useState<IParticipantPDI[]>([]);
  const [dataForChart, setDataForChart] = useState<IChartData>(defaultChartData);

  useEffect(() => {
    const groupedParticipants = {
      not_able: participantPDIList.filter(({ status }) => status !== 'answered'),
      not_registered: participantPDIList.filter(
        ({ status, pdi_status }) => status === 'answered' && pdi_status === 'not_registered',
      ),
      progress: participantPDIList.filter(
        ({ status, pdi_status, completion }) =>
          status === 'answered' && pdi_status === 'registered' && completion < 100,
      ),
      concluded: participantPDIList.filter(
        ({ status, pdi_status, completion }) =>
          status === 'answered' && pdi_status === 'registered' && completion === 100,
      ),
    };

    setDataForChart(old => {
      return {
        concluded: {
          ...old.concluded,
          value: groupedParticipants.concluded.length,
        },
        not_able: {
          ...old.not_able,
          value: groupedParticipants.not_able.length,
        },
        not_registered: {
          ...old.not_registered,
          value: groupedParticipants.not_registered.length,
        },
        progress: {
          ...old.progress,
          value: groupedParticipants.progress.length,
        },
      };
    });
  }, [participantPDIList]);

  const context = {
    participantPDIList,
    setParticipantPDIList,
    dataForChart,
    setDataForChart,
  };

  return <MonitoringContext.Provider value={context}>{children}</MonitoringContext.Provider>;
};

const useMonitoringPDI = () => useContext(MonitoringContext);

export { MonitoringProvider, useMonitoringPDI };
