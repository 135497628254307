import React, { useEffect, useState } from 'react';
import { InputBase, SearchButton, SearchInputStyle } from 'components/Forms/Input/Input';
import Layout from 'layout/Layout';
import { GridCol, GridContainer, GridRow, HeaderPage } from 'layout/components/page-styles';
import { ReactComponent as SearchIcon } from '../../layout/icons/search.svg';
import { Table, TableBody, TableHead, TableHeaderCell } from 'components/Tables/styles';
import TablePagination from 'components/TablePagination';
import { Button } from 'components/Buttons/styles';
import OccurrenceModal from './components/OccurrenceModal';
import { getOccurrences } from 'requests/occurrences';
import { usePermissions } from 'safira-app/contexts/Permissions';
import { OccurrenceItemProps } from 'interfaces/Occurrences';
import OccurrenceItem from './components/OccurrenceItem';
import { useTranslation } from 'react-i18next';

const OccurrencesPage: React.FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchResults, setSearchResults] = useState<OccurrenceItemProps[]>([]);
  const [occurrenceModal, setOccurrenceModal] = useState(false);
  const [occurrenceList, setOccurrenceList] = useState<OccurrenceItemProps[]>([]);
  const { companyId, checkPermission } = usePermissions();

  const totalRows = searchResults.length || occurrenceList.length;

  useEffect(() => {
    getOccurrences(companyId).then(response => {
      setOccurrenceList(response.data);
    });
  }, [companyId]);

  const handlePageChange = newPage => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = newRowsPerPage => {
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const currentPageData =
    searchResults.length > 0 ? searchResults.slice(startIndex, endIndex) : occurrenceList.slice(startIndex, endIndex);

  const handleSearch = event => {
    const query = event.target.value;
    const filteredList = occurrenceList.filter(occurrence =>
      occurrence.collaborator.name.toLowerCase().includes(query.toLowerCase()),
    );
    setSearchResults(filteredList);
    setPage(1);
  };

  const handleAddOccurrence = () => setOccurrenceModal(true);

  return (
    <>
      <Layout>
        <GridContainer style={{ marginBottom: '16px' }}>
          <GridRow>
            <GridCol xs={12} sm={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
              <HeaderPage>
                <h1>{t('occurrences.occurrences')}</h1>
              </HeaderPage>
            </GridCol>
            <GridCol xs={12} sm={6} lg={6}>
              <SearchInputStyle style={{ width: '100%' }}>
                <InputBase onChange={handleSearch} type="text" placeholder={t('occurrences.search_occurrence')} />
                <SearchButton>
                  <SearchIcon style={{ width: '12px', height: '12px', fill: '#afafaf' }} />
                </SearchButton>
              </SearchInputStyle>
            </GridCol>
            <GridCol xs={12} sm={2} lg={2}>
              <Button
                size="small"
                style={{ width: '100%' }}
                onClick={handleAddOccurrence}
                disabled={!checkPermission(['managers_add_occurrence'])}
                title={
                  !checkPermission(['managers_add_occurrence'])
                    ? 'Você não tem permissão para cadastrar uma ocorrência.'
                    : ''
                }
              >
                {t('occurrences.new_occurrence')}
              </Button>
            </GridCol>
          </GridRow>
        </GridContainer>
        <GridContainer>
          <Table>
            <TableHead>
              <TableHeaderCell>{t('occurrences.employee')}</TableHeaderCell>
              <TableHeaderCell>{t('occurrences.date')}</TableHeaderCell>
              <TableHeaderCell>{t('occurrences.unit')}</TableHeaderCell>
              <TableHeaderCell>{t('occurrences.sector')}</TableHeaderCell>
              <TableHeaderCell>{t('occurrences.reason')}</TableHeaderCell>
              <TableHeaderCell>{t('occurrences.type')}</TableHeaderCell>
              <TableHeaderCell style={{ textAlign: 'right' }}>{t('occurrences.actions')}</TableHeaderCell>
            </TableHead>

            <TableBody>
              {currentPageData.map(occurrence => (
                <OccurrenceItem key={occurrence.id} occurrence={occurrence} setOccurrenceList={setOccurrenceList} />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPage={rowsPerPage}
            totalRows={totalRows}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </GridContainer>
      </Layout>

      {occurrenceModal ? (
        <OccurrenceModal
          open={occurrenceModal}
          setOpen={setOccurrenceModal}
          type="ADD"
          setOccurrenceList={setOccurrenceList}
        />
      ) : null}
    </>
  );
};

export default OccurrencesPage;
