import styled from 'styled-components'

export const SurveyItemStyle = styled.div`
    h3 {
        margin-bottom: 16px;
        color: #003B5D;
        font-weight: 600;
    }
`

export const DashedButton = styled.button`
    background: transparent;
    color: var(--primary-color);
    border: none;
    border-bottom: 1px dashed var(--primary-color);
    padding: 2px;

    &:disabled {
        color: gray;
        border-bottom: 1px dashed gray;
        cursor: not-allowed;
    }
`