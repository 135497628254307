import React from 'react';
import { links } from 'safira-app/config/links';
import Avatar from 'components/Avatar';
import { IconButton } from 'components/Buttons/styles';
import LinearProgress from 'components/LinearProgress';
import { TableCell, TableRow } from 'components/Tables/styles';
import { usePermissions } from 'safira-app/contexts/Permissions';
import { IParticipantPDI, PdiStatus } from 'interfaces/Evaluation';
import { DashedButton } from 'pages/Evaluation/SurveyItem/styles';
import DownloadReport from 'pages/Evaluation/icons/DownloadReport';

interface MonitoringTableItemProps {
    participant: IParticipantPDI;
}

const translatePdiStatus: { [Key in PdiStatus]: string } = {
    concluded: 'Finalizada',
    not_able: 'Não apto',
    not_created: 'Não registrado',
    progress: 'Em andamento',
    not_registered: 'Não registrado',
    registered: 'Finalizado',
  };

const MonitoringTableItem: React.FC<MonitoringTableItemProps> = ({ participant }) => {
    const { companyId } = usePermissions()

    const getStatusToRender = () => {
        const { status, pdi_status, completion } = participant;
    
        if (status !== 'answered') {
          return 'Não apto';
        }
    
        if (pdi_status === 'registered') {
          if (completion === 100) return 'Finalizado';
    
          return 'Em progresso';
        }
    
        return translatePdiStatus[pdi_status];
    };

    const handleDownloadReport = () => {
        window.open(links.web.evaluation + `/user_view/report_viewer/${companyId}/${participant.cycle_id}/0?participant_id=${participant.id}`, '_blank');
    }

    const handleCreatePDI = () => {
        window.location.href = links.web.evaluation + `/individual_development/${participant.id}/${companyId}?origin=manager-panel`
    }

    const handleViewPDI = () => {
        window.open(participant.pdi_link, '_blank');
    }

    return (
        <TableRow>
            <TableCell
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                }}
            >
                <Avatar
                    name={participant.collaborator.person_few_attributes.name}
                    src={participant.collaborator.person_few_attributes.avatar ?? ""}
                />{" "}
                {participant.collaborator.person_few_attributes.name}
            </TableCell>
            <TableCell style={{ width: '150px' }}>{participant.collaborator?.sector?.name}</TableCell>
            <TableCell style={{ width: '150px' }}>{participant.collaborator?.job?.name}</TableCell>
            <TableCell style={{ width: '120px' }}>{getStatusToRender()}</TableCell>
            <TableCell style={{ display: 'flex', alignItems: 'center', gap: '4px', width: '170px' }}>
                <LinearProgress progress={participant.completion} />
                <span>{participant.completion}%</span>
            </TableCell>
            <TableCell style={{ textAlign: 'center', width: '120px' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton
                        title={!participant.has_one_answer ? "Relatório não disponível" : ""}
                        onClick={handleDownloadReport}
                        disabled={!participant.has_one_answer}
                        style={{ background: 'none' }}
                    >
                        <DownloadReport color={participant.has_one_answer ? "#0092b0" : '#ccc'} />
                    </IconButton>
                </div>
            </TableCell>
            <TableCell style={{ width: '120px' }}>
                {participant.member_state === null ? (
                    <DashedButton onClick={handleViewPDI}>Visualizar PDI</DashedButton>
                ) : (
                    <>
                        {participant.member_state === 'not_registered' ? (
                            <DashedButton disabled={!(participant.pdi_status !== 'concluded')} onClick={handleCreatePDI}>Gerar PDI</DashedButton>
                        ) : null}

                        {participant.member_state === 'project_deleted' ? (
                            <DashedButton disabled>Visualizar PDI</DashedButton>
                        ) : null}

                        {participant.member_state === 'not_member' ? (
                            <DashedButton disabled>Visualizar PDI</DashedButton>
                        ) : null}
                    </>
                )}
            </TableCell>
        </TableRow>
    )
}

export default MonitoringTableItem;