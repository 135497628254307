import styled from "styled-components";

export const HeaderPage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 24px;
    font-family: "Myriad Pro", sans-serif;
    font-weight: normal;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  height: 100%;
`;

export const ContainerTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-family: "Myriad Pro", sans-serif;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  max-width: ${({ theme }) => theme.breakpoints.values.lg}px;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
`;

export const GridItem = styled.div`
  box-sizing: border-box;
`;

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  box-sizing: border-box;
`;

interface ColProps {
  children: React.ReactNode;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
}

export const GridCol = styled(GridItem)<ColProps>`
  ${({ xs, theme }) => xs && `grid-column: span ${xs};`}
  ${({ sm, theme }) =>
    sm &&
    `@media (min-width: ${theme.breakpoints.values.sm}px) { grid-column: span ${sm}; }`}
  ${({ md, theme }) =>
    md &&
    `@media (min-width: ${theme.breakpoints.values.md}px) { grid-column: span ${md}; }`}
  ${({ lg, theme }) =>
    lg &&
    `@media (min-width: ${theme.breakpoints.values.lg}px) { grid-column: span ${lg}; }`}
  box-sizing: border-box;
`;
