import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { MeProps } from "safira-app/interfaces/Me";
import api from "services/api";
import { links } from "safira-app/config/links";

type SetState<T> = Dispatch<SetStateAction<T>>;

type ProfileContextType = {
  me: MeProps | null;
  profile: MeProps | null;
  setProfile: SetState<MeProps | null>;
};

export const ProfileContext = createContext({} as ProfileContextType);

const ProfileProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [profile, setProfile] = useState<MeProps | null>(null);

  useEffect(() => {
    api
      .get<MeProps>(`${links.api.schedule}/auth/me`)
      .then((response) => setProfile(response.data));
  }, []);

  return (
    <ProfileContext.Provider value={{ me: profile, profile, setProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfileContext = () => {
  const context = useContext(ProfileContext);
  return context as any;
};

export default ProfileProvider;
