import React from "react";
import styled from "styled-components";

export const RadioContainer = styled.label`
  display: flex;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;
`;

export const RadioInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const RadioStyled = styled.span`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin-right: 8px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #1976d2;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  ${RadioInput}:checked + &::after {
    opacity: 1;
  }
`;

export const RadioLabel = styled.span`
  font-size: 14px;
  color: #555;
`;

interface RadioGroupProps {
  direction: "row" | "column";
}

export const RadioGroup = styled.div<RadioGroupProps>`
  display: flex;
  align-items: center;
  flex-direction: ${({ direction }) => direction};
`;

const Radio = ({ label, value, checked, onChange }) => {
  return (
    <RadioContainer>
      <RadioInput
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <RadioStyled />
      <RadioLabel>{label}</RadioLabel>
    </RadioContainer>
  );
};

export default Radio;
