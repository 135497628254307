import styled, { css, DefaultTheme, FlattenSimpleInterpolation, ThemedCssFunction } from 'styled-components';
import ModelComponent from './ModelComponent';

type CssType = FlattenSimpleInterpolation | ((css: ThemedCssFunction<DefaultTheme>) => FlattenSimpleInterpolation);

const Flex = styled(ModelComponent)<{ styled?: CssType; paper?: boolean }>(props => [
  {
    display: 'flex',
    flexDirection: 'column',
  },
  props.paper && { padding: 16, backgroundColor: '#fff' },
  typeof props.styled === 'function' ? props.styled(css) : props.styled || {},
]);

export default Flex;
