import React, { useEffect, useState } from 'react';
import Layout from 'layout/Layout';
import { GridCol, GridContainer, GridRow, HeaderPage } from 'layout/components/page-styles';
import SurveyItem from './SurveyItem';
import { WhiteContainer } from 'components/WhiteContainer';
import Select from 'components/Forms/Select';
import { GraphAreaStyles } from './styles';
import Graph from './components/Graph';
import { Label } from 'components/Forms/Label';
import { getCycles, getCyclesPDI } from 'requests/evaluation';
import { usePermissions } from 'safira-app/contexts/Permissions';
import { CycleProps } from 'interfaces/Evaluation';
import { useMonitoringPDI } from 'context/MonitoringPDIContext';
import { useTranslation } from 'react-i18next';

const EvaluationComponent: React.FC = () => {
  const { t } = useTranslation();
  const { companyId } = usePermissions();
  const { setParticipantPDIList } = useMonitoringPDI();
  const [cycles, setCycles] = useState<CycleProps[]>([]);
  const [cyclesCopy, setCyclesCopy] = useState<CycleProps[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>('all');
  const pagination = { page: 1, perPage: 9999 };

  useEffect(() => {
    getCycles(companyId, pagination).then(response => {
      setCycles(response.data);
      setCyclesCopy(response.data);
      const cyclesIds = response.data.map(cycle => cycle.id);
      if (cyclesIds.length > 0) {
        getCyclesPDI(companyId, cyclesIds).then(res => {
          setParticipantPDIList(res.data);
        });
      }
    });
  }, [companyId]); //eslint-disable-line

  const handleFilter = (selectedFilter: string) => {
    setSelectedFilter(selectedFilter);
    if (selectedFilter === 'all') {
      getCycles(companyId, pagination).then(response => {
        setCycles(response.data);
        const cyclesIds = response.data.map(cycle => cycle.id);
        if (cyclesIds.length > 0) {
          getCyclesPDI(companyId, cyclesIds).then(res => {
            setParticipantPDIList(res.data);
          });
        }
      });
    } else {
      const cyclesIds = [selectedFilter];
      setCycles(cyclesCopy.filter(cycles => cycles.belongs_evaluation.cycle_id === selectedFilter));
      getCyclesPDI(companyId, cyclesIds).then(res => {
        setParticipantPDIList(res.data);
      });
    }
  };

  return (
    <Layout>
      <GridContainer style={{ marginBottom: '16px' }}>
        <GridRow>
          <GridCol xs={12} md={12} lg={12}>
            <HeaderPage>
              <h1>{t('competency_management.pdi_tracking')}</h1>
            </HeaderPage>
          </GridCol>
        </GridRow>
      </GridContainer>
      <GridContainer style={{ marginBottom: '16px' }}>
        <WhiteContainer>
          <GraphAreaStyles>
            <GridContainer>
              <GridRow>
                <GridCol xs={12} md={4} lg={4}>
                  <h3>{t('competency_management.track_employee_pdi_here')}</h3>
                  <Label>{t('competency_management.choose_evaluation_to_filter')}</Label>
                  <Select
                    inputProps={{
                      onChange: e => handleFilter(e.target.value),
                      style: { width: '290px' },
                      value: selectedFilter,
                    }}
                  >
                    <option value="all">{t('competency_management.all_evaluations')}</option>
                    {cyclesCopy.length > 0 &&
                      cyclesCopy.map(cycle => <option value={cycle.id}>{cycle.belongs_evaluation.title}</option>)}
                  </Select>
                </GridCol>
                <GridCol xs={12} md={4} lg={4} style={{ width: '310px' }}>
                  <Graph />
                </GridCol>
              </GridRow>
            </GridContainer>
          </GraphAreaStyles>
        </WhiteContainer>
      </GridContainer>
      <GridContainer style={{ marginBottom: '16px' }}>
        {cycles.map(cycle => (
          <SurveyItem cycle={cycle} key={cycle.id} />
        ))}
      </GridContainer>
    </Layout>
  );
};

export default EvaluationComponent;
