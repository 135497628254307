import React from "react";
import styled, { css } from "styled-components";

type CheckboxProps = {
  label: string;
  labelTitle?: boolean;
  hasError?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const CheckboxWrapper = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const CheckboxInput = styled.input<{ hasError?: boolean }>`
  position: relative;
  width: 18px;
  height: 18px;
  appearance: none;
  border: ${({ hasError, theme }) =>
      hasError ? theme.colors.danger : "#afafaf"}
    1px solid;
  border-radius: 4px;
  outline: none;
  margin-right: 8px;
  cursor: pointer;

  &:checked {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &:checked::before {
    content: "";
    position: absolute;
    left: 4px;
    width: 6px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  &:focus,
  &:focus-visible {
    border: 2px solid
      ${({ theme, hasError }) =>
        hasError ? theme.colors.danger : theme.colors.primary} !important;
  }
`;

interface CheckboxLabelProps {
  isTitle?: boolean;
}

const CheckboxLabel = styled.span<CheckboxLabelProps>`
  font-size: ${({ isTitle }) => (isTitle ? "16px" : "14px")};
  font-weight: ${({ isTitle }) => (isTitle ? 600 : 400)};
`;

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  hasError,
  labelTitle,
  ...rest
}) => {
  return (
    <CheckboxWrapper>
      <CheckboxInput type="checkbox" hasError={hasError} {...rest} />
      <CheckboxLabel isTitle={labelTitle}>{label}</CheckboxLabel>
    </CheckboxWrapper>
  );
};

export default Checkbox;
