import styled from "styled-components";

interface MenuItemProps {
  isSubMenuOpen?: boolean;
}

export const MenuContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
export const MenuItem = styled.li<MenuItemProps>`
  a {
    display: flex;
    align-items: center;
    height: 55px;
    padding: 0 10px;
    color: #333;
    text-decoration: none;
    gap: 8px;
    border-left: 6px solid transparent;
    font-size: 16px;
    color: #212529;
    font-family: "Myriad Pro", sans-serif;

    span {
      transition: transform 0.3s ease;
      transform: ${(props) =>
        props.isSubMenuOpen ? "rotate(180deg)" : "rotate(0deg)"};
    }
  }

  > a.active {
    border-left: 6px solid var(--primary-color);
    background: #ececec;
  }
`;
export const SubMenuItem = styled.li`
  a {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 10px;
    color: #333;
    text-decoration: none;
    gap: 8px;
  }

  > a.active {
    color: var(--primary-color);
  }
`;
