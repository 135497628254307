import React from 'react';

const InCheckIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="151" height="150" viewBox="0 0 151 150" fill="none">
      <g clip-path="url(#clip0_1973_9629)">
        <path
          d="M98.7288 90.1725C99.0994 89.843 99.5103 89.5618 99.9517 89.3358L94.2234 83.6075C92.8083 85.4984 91.2823 87.3037 89.6537 89.014C87.9433 90.6427 86.138 92.1686 84.2472 93.5837L89.9755 99.312C90.2015 98.8706 90.4826 98.4597 90.8122 98.0891L98.7288 90.1725Z"
          fill="#9DC1C6"
        />
        <path
          d="M147.512 125.25L112.434 90.1724C110.805 88.5489 108.643 87.5698 106.348 87.416C104.053 87.2622 101.779 87.9441 99.9479 89.3357C99.5065 89.5617 99.0956 89.8429 98.725 90.1724L90.8084 98.089C90.4788 98.4596 90.1977 98.8705 89.9717 99.3119C88.5801 101.143 87.8982 103.417 88.052 105.712C88.2058 108.007 89.1848 110.169 90.8084 111.798L125.886 146.876C126.784 147.781 127.851 148.499 129.028 148.989C130.204 149.479 131.466 149.732 132.741 149.732C134.015 149.732 135.277 149.479 136.454 148.989C137.63 148.499 138.698 147.781 139.595 146.876L147.512 139.217C148.456 138.315 149.208 137.232 149.722 136.031C150.235 134.831 150.5 133.539 150.5 132.233C150.5 130.928 150.235 129.636 149.722 128.436C149.208 127.235 148.456 126.152 147.512 125.25Z"
          fill="#B56F3A"
        />
        <path
          d="M52.7707 91.395C45.0119 91.3865 37.4295 89.0792 30.9809 84.7646C24.5324 80.4499 19.5068 74.3213 16.5388 67.1526C13.5707 59.9838 12.7933 52.0964 14.3046 44.4861C15.816 36.8759 19.5483 29.8841 25.0304 24.3935C28.6503 20.7175 32.97 17.8041 37.7345 15.8254C42.499 13.8466 47.6117 12.8426 52.7707 12.8725C60.9164 12.8731 68.8589 15.4156 75.4907 20.1455V5.27775C67.5427 1.40059 58.739 -0.391067 49.9078 0.0713232C41.0766 0.533713 32.5082 3.23495 25.0085 7.92093C17.5088 12.6069 11.3244 19.1236 7.03696 26.8581C2.74952 34.5925 0.5 43.2905 0.5 52.1338C0.5 60.977 2.74952 69.675 7.03696 77.4094C11.3244 85.1439 17.5088 91.6606 25.0085 96.3466C32.5082 101.033 41.0766 103.734 49.9078 104.196C58.739 104.659 67.5427 102.867 75.4907 98.9898V84.0576C68.8826 88.8419 60.929 91.4105 52.7707 91.395Z"
          fill="#FDBB50"
        />
        <path
          d="M89.6514 15.2543C85.5286 11.1532 80.741 7.7801 75.4916 5.27814V20.1459C80.14 23.4538 84.0117 27.735 86.837 32.6916C89.6624 37.6481 91.3735 43.1609 91.8514 48.8461C92.3292 54.5313 91.5623 60.2524 89.6039 65.611C87.6456 70.9696 84.5429 75.8371 80.5119 79.8745C78.9596 81.4083 77.2802 82.8079 75.4916 84.058V98.9902C78.5897 97.4978 81.5234 95.6858 84.2449 93.5837C86.1357 92.1686 87.941 90.6426 89.6514 89.014C91.2801 87.3036 92.806 85.4983 94.2211 83.6075C101.843 73.5835 105.579 61.1395 104.739 48.575C103.899 36.0104 98.5398 24.1745 89.6514 15.2543Z"
          fill="#E29D39"
        />
        <path
          d="M72.5963 42.6724C70.5046 38.1251 66.9215 34.4293 62.441 32.1979C57.9605 29.9666 52.8519 29.3338 47.9624 30.4044C43.0729 31.475 38.6962 34.1847 35.5582 38.0843C32.4201 41.9838 30.7092 46.8388 30.7092 51.8441C30.7092 56.8495 32.4201 61.7045 35.5582 65.604C38.6962 69.5035 43.0729 72.2132 47.9624 73.2838C52.8519 74.3545 57.9605 73.7216 62.441 71.4903C66.9215 69.2589 70.5046 65.5631 72.5963 61.0158C73.9424 58.1564 74.6455 55.0367 74.6559 51.8763C74.6384 48.696 73.9359 45.5569 72.5963 42.6724ZM62.9419 48.5938L52.0003 59.5354C51.7132 59.8235 51.3722 60.052 50.9967 60.2079C50.6211 60.3638 50.2185 60.4441 49.8119 60.4441C49.4053 60.4441 49.0027 60.3638 48.6272 60.2079C48.2517 60.052 47.9106 59.8235 47.6236 59.5354L42.6033 54.5795C42.2657 54.2993 41.9904 53.9517 41.7948 53.559C41.5992 53.1663 41.4877 52.7371 41.4674 52.2988C41.4471 51.8606 41.5184 51.4229 41.6769 51.0138C41.8354 50.6047 42.0775 50.2331 42.3877 49.9229C42.6979 49.6127 43.0695 49.3706 43.4786 49.2121C43.8877 49.0536 44.3254 48.9823 44.7636 49.0026C45.2019 49.0229 45.6311 49.1344 46.0238 49.33C46.4165 49.5256 46.7641 49.8009 47.0443 50.1385L49.8119 52.9061L58.5009 44.2171C59.0915 43.6315 59.8896 43.3029 60.7214 43.3029C61.5532 43.3029 62.3513 43.6315 62.9419 44.2171C63.3159 44.5058 63.6188 44.8763 63.8271 45.3003C64.0355 45.7243 64.1438 46.1905 64.1438 46.6629C64.1438 47.1354 64.0355 47.6015 63.8271 48.0255C63.6188 48.4495 63.3159 48.8201 62.9419 49.1087V48.5938Z"
          fill="#86CE15"
        />
      </g>
      <defs>
        <clipPath id="clip0_1973_9629">
          <rect width="150" height="150" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InCheckIcon;
