import { OutlinedButton } from 'components/Buttons/styles';
import MotiveIcon from 'components/Icons/MotiveIcon';
import Modal, { ModalActions, ModalContent } from 'components/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface MotiveModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  motive: string;
}

const MotiveModal: React.FC<MotiveModalProps> = ({ open, setOpen, motive }) => {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} title="Motivo da solicitação" icon={<MotiveIcon />} onClose={handleClose}>
      <ModalContent>
        <p>{motive}</p>
      </ModalContent>
      <ModalActions>
        <OutlinedButton size="small" type="button" onClick={handleClose}>
          {t('close')}
        </OutlinedButton>
      </ModalActions>
    </Modal>
  );
};

export default MotiveModal;
