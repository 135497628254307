import styled, { css } from "styled-components";

interface OccurrenceTypeProps {
  type: "NEGATIVE" | "POSITIVE" | "NEUTRAL"
}

export const OccurrenceType = styled.span<OccurrenceTypeProps>`
  font-weight: 600;
  padding: 2px 4px;
  border-radius: 3px;

  ${({ type }) =>
    type === "POSITIVE" && css`
      background-color: #FBFFF6;
      color: #77B51F;
      border: 1px solid #77B51F50;
    `
  }

  ${({ type }) =>
    type === "NEGATIVE" && css`
      background-color: #FFE5E9;
      color: #CE0E2D;
      border: 1px solid #CE0E2D50;
    `
  }

  ${({ type }) =>
    type === "NEUTRAL" && css`
      background-color: #F4FCFF;
      color: #008AC1;
      border: 1px solid #008AC150;
    `
  }
`;

export const OccurrenceFooterDescription = styled.span`
  color: #848484;
  font-weight: 500;
  font-size: 0.8rem;
`