import React, { ReactNode } from "react";
import styled from "styled-components";
import Success from "./icons/Success";
import Info from "./icons/Info";
import Error from "./icons/Error";

type HelpFormTypes = "INFO" | "SUCCESS" | "ERROR";

interface HelpFormProps {
  type: HelpFormTypes;
  text: string;
}

interface HelpFormComponentsProps {
  type: string;
}

const HelpFormComponent = styled.div<HelpFormComponentsProps>`
  display: flex;
  align-items: center;
  gap: 4px;

  > div {
    display: flex;
  }
`;

interface HelpFormLabelProps {
  color: string;
}

const HelpFormLabel = styled.span<HelpFormLabelProps>`
  color: ${({ color }) => color};
  font-weight: 600;
`;

const HelpFormColor: { [key in HelpFormTypes]: string } = {
  INFO: "#008AC1",
  SUCCESS: "#198754",
  ERROR: "#DE0000",
};

const HelpFormIcon: { [key in HelpFormTypes]: ReactNode } = {
  INFO: <Info />,
  SUCCESS: <Success />,
  ERROR: <Error />,
};

const HelpForm: React.FC<HelpFormProps> = ({ type, text }) => {
  return (
    <HelpFormComponent type={type}>
      <div>{HelpFormIcon[type]}</div>
      <HelpFormLabel color={HelpFormColor[type]}>{text}</HelpFormLabel>
    </HelpFormComponent>
  );
};

export const HelpersContainer = styled.div`
  display: "flex";
  flex-direction: "column";
  gap: "12px";
`;

export default HelpForm;
