import React from 'react';

const ClimateResearchDisabledIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="41" viewBox="0 0 45 41" fill="none">
      <path
        d="M24.9027 25.1902V4.96449C24.9027 3.64783 24.38 2.38509 23.4494 1.45407C22.5188 0.523042 21.2566 0 19.9406 0C18.6245 0 17.3624 0.523042 16.4318 1.45407C15.5012 2.38509 14.9784 3.64783 14.9784 4.96449V25.1902C13.477 26.2454 12.3508 27.7517 11.763 29.4905C11.1752 31.2294 11.1564 33.1105 11.7095 34.8607C12.2626 36.611 13.3586 38.1394 14.8387 39.2243C16.3187 40.3092 18.1058 40.894 19.9406 40.894C21.7754 40.894 23.5624 40.3092 25.0425 39.2243C26.5225 38.1394 27.6186 36.611 28.1717 34.8607C28.7247 33.1105 28.706 31.2294 28.1182 29.4905C27.5304 27.7517 26.4041 26.2454 24.9027 25.1902Z"
        fill="#8F8F8F"
      />
      <path
        d="M19.9405 38.0665C18.5412 38.0687 17.1857 37.5784 16.1112 36.6815C15.0368 35.7847 14.3119 34.5383 14.0634 33.1606C13.8149 31.7829 14.0587 30.3616 14.752 29.1456C15.4453 27.9295 16.544 26.9962 17.8558 26.5089V4.96986C17.875 4.42988 18.1029 3.91841 18.4915 3.54318C18.8801 3.16795 19.3991 2.95825 19.9392 2.95825C20.4792 2.95825 20.9982 3.16795 21.3868 3.54318C21.7754 3.91841 22.0033 4.42988 22.0225 4.96986V26.5089C23.3341 26.9961 24.4327 27.9292 25.126 29.145C25.8193 30.3608 26.0633 31.7817 25.8151 33.1593C25.567 34.5368 24.8425 35.7832 23.7686 36.6803C22.6946 37.5774 21.3395 38.0681 19.9405 38.0665Z"
        fill="#F3E8D7"
      />
      <path
        d="M17.8559 8.77856V26.5115C16.5455 27.0002 15.4484 27.9339 14.7562 29.1496C14.0639 30.3653 13.8206 31.7856 14.0688 33.1626C14.3169 34.5395 15.0407 35.7854 16.1137 36.6827C17.1868 37.5799 18.5408 38.0715 19.9392 38.0715C21.3377 38.0715 22.6917 37.5799 23.7647 36.6827C24.8377 35.7854 25.5615 34.5395 25.8097 33.1626C26.0578 31.7856 25.8145 30.3653 25.1223 29.1496C24.4301 27.9339 23.3329 27.0002 22.0225 26.5115V8.77856H17.8559Z"
        fill="#585858"
      />
      <path
        d="M29.2242 14.4074H26.5987C26.3362 14.4074 26.1445 14.1447 26.1445 13.7901C26.1445 13.4355 26.3467 13.1729 26.5987 13.1729H29.2242C29.4868 13.1729 29.6758 13.4355 29.6758 13.7901C29.6758 14.1447 29.4736 14.4074 29.2242 14.4074Z"
        fill="#5A5F63"
      />
      <path
        d="M27.7881 9.39566H26.5962C26.3336 9.39566 26.142 9.133 26.142 8.7784C26.142 8.4238 26.3441 8.16113 26.5962 8.16113H27.7881C28.0507 8.16113 28.2423 8.4238 28.2423 8.7784C28.2423 9.133 28.0376 9.39566 27.7881 9.39566Z"
        fill="#5A5F63"
      />
      <path
        d="M28.2108 4.39447H26.5962C26.3336 4.39447 26.142 4.11866 26.142 3.77719C26.142 3.43572 26.3441 3.15991 26.5962 3.15991H28.2108C28.4734 3.15991 28.665 3.42258 28.665 3.77719C28.665 4.1318 28.4629 4.39447 28.2108 4.39447Z"
        fill="#5A5F63"
      />
      <path
        d="M28.2108 24.3891H26.5962C26.3336 24.3891 26.142 24.1264 26.142 23.7718C26.142 23.4172 26.3441 23.1545 26.5962 23.1545H28.2108C28.4734 23.1545 28.665 23.4172 28.665 23.7718C28.665 24.1264 28.4629 24.3891 28.2108 24.3891Z"
        fill="#5A5F63"
      />
      <path
        d="M27.7881 19.3984H26.5962C26.3336 19.3984 26.142 19.1357 26.142 18.7811C26.142 18.4265 26.3441 18.1638 26.5962 18.1638H27.7881C28.0507 18.1638 28.2423 18.4265 28.2423 18.7811C28.2423 19.1357 28.0376 19.3984 27.7881 19.3984Z"
        fill="#5A5F63"
      />
      <path
        d="M22.0226 26.5115V8.77856H17.856V26.5115C17.856 26.5115 20.744 27.8485 20.744 32.1012C20.7433 33.2243 20.4259 34.3244 19.8281 35.2751C19.2304 36.2258 18.3766 36.9885 17.365 37.4754C18.0917 37.8281 18.8824 38.0293 19.6892 38.067C20.496 38.1047 21.302 37.978 22.0583 37.6946C22.8147 37.4112 23.5056 36.977 24.0892 36.4184C24.6728 35.8598 25.1368 35.1883 25.4532 34.4449C25.7696 33.7014 25.9317 32.9014 25.9296 32.0933C25.9276 31.2853 25.7615 30.4861 25.4415 29.7442C25.1214 29.0024 24.654 28.3333 24.0677 27.7776C23.4813 27.2219 22.7882 26.7911 22.0305 26.5115H22.0226Z"
        fill="#585858"
      />
      <path
        d="M35.4414 34.5203C38.4256 34.5203 40.8447 32.1 40.8447 29.1145C40.8447 26.129 38.4256 23.7087 35.4414 23.7087C32.4573 23.7087 30.0382 26.129 30.0382 29.1145C30.0382 32.1 32.4573 34.5203 35.4414 34.5203Z"
        fill="#8F8F8F"
      />
      <path
        d="M37.5234 32.306C37.2609 32.4688 36.531 31.7465 35.5333 31.7386C34.5356 31.7307 33.8136 32.4688 33.5458 32.306C33.2229 32.1746 33.7743 30.6039 35.5333 30.596C37.2924 30.5881 37.8438 32.1746 37.5234 32.306Z"
        fill="#3E4347"
      />
      <path
        d="M38.2771 28.387C38.2771 28.5591 38.2261 28.7273 38.1305 28.8703C38.0349 29.0134 37.899 29.1248 37.74 29.1905C37.5811 29.2563 37.4062 29.2733 37.2376 29.2396C37.0689 29.2058 36.9141 29.1227 36.7927 29.0009C36.6712 28.879 36.5887 28.7239 36.5554 28.555C36.5222 28.3862 36.5398 28.2113 36.606 28.0525C36.6721 27.8937 36.7839 27.758 36.9272 27.6628C37.0705 27.5676 37.2387 27.5171 37.4107 27.5176C37.5247 27.5176 37.6376 27.5401 37.7429 27.5838C37.8482 27.6275 37.9438 27.6916 38.0243 27.7724C38.1048 27.8532 38.1685 27.949 38.2119 28.0545C38.2553 28.16 38.2775 28.273 38.2771 28.387Z"
        fill="#3E4347"
      />
      <path
        d="M37.8932 28.1914C37.9521 28.1235 37.9138 27.9936 37.8076 27.9014C37.7014 27.8091 37.5675 27.7893 37.5086 27.8573C37.4496 27.9252 37.4879 28.055 37.5941 28.1473C37.7003 28.2395 37.8342 28.2593 37.8932 28.1914Z"
        fill="#5A5F63"
      />
      <path
        d="M34.3413 28.387C34.3413 28.5591 34.2903 28.7273 34.1947 28.8703C34.0991 29.0134 33.9632 29.1248 33.8043 29.1905C33.6453 29.2563 33.4704 29.2733 33.3018 29.2396C33.1331 29.2058 32.9783 29.1227 32.8569 29.0009C32.7354 28.879 32.6529 28.7239 32.6197 28.555C32.5864 28.3862 32.604 28.2113 32.6702 28.0525C32.7364 27.8937 32.8482 27.758 32.9914 27.6628C33.1347 27.5676 33.3029 27.5171 33.4749 27.5176C33.5889 27.5176 33.7018 27.5401 33.8071 27.5838C33.9124 27.6275 34.008 27.6916 34.0885 27.7724C34.169 27.8532 34.2328 27.949 34.2761 28.0545C34.3195 28.16 34.3417 28.273 34.3413 28.387Z"
        fill="#3E4347"
      />
      <path
        d="M33.9555 28.1911C34.0145 28.1232 33.9762 27.9934 33.87 27.9011C33.7638 27.8089 33.6299 27.7891 33.571 27.857C33.512 27.9249 33.5503 28.0548 33.6565 28.147C33.7627 28.2393 33.8966 28.259 33.9555 28.1911Z"
        fill="#5A5F63"
      />
      <path
        d="M6.49547 24.2445C10.0828 24.2445 12.9909 21.3351 12.9909 17.746C12.9909 14.157 10.0828 11.2476 6.49547 11.2476C2.90812 11.2476 0 14.157 0 17.746C0 21.3351 2.90812 24.2445 6.49547 24.2445Z"
        fill="#8F8F8F"
      />
      <path
        d="M9.40192 19.9892C9.40192 20.0832 9.36457 20.1734 9.2981 20.2399C9.23163 20.3065 9.14148 20.3438 9.04748 20.3438H3.94091C3.85251 20.3362 3.77018 20.2957 3.7102 20.2302C3.65022 20.1648 3.61694 20.0793 3.61694 19.9905C3.61694 19.9017 3.65022 19.8162 3.7102 19.7508C3.77018 19.6854 3.85251 19.6448 3.94091 19.6372H9.04748C9.0939 19.6369 9.13993 19.6457 9.18292 19.6633C9.2259 19.6808 9.265 19.7067 9.29795 19.7394C9.33089 19.7721 9.35704 19.811 9.37489 19.8539C9.39273 19.8968 9.40192 19.9428 9.40192 19.9892Z"
        fill="#3E4347"
      />
      <path
        d="M4.42659 17.4729C4.96166 17.4729 5.39542 17.039 5.39542 16.5037C5.39542 15.9684 4.96166 15.5344 4.42659 15.5344C3.89152 15.5344 3.45776 15.9684 3.45776 16.5037C3.45776 17.039 3.89152 17.4729 4.42659 17.4729Z"
        fill="#3E4347"
      />
      <path
        d="M4.98741 16.3169C5.0607 16.2623 5.05206 16.1266 4.9681 16.0137C4.88414 15.9008 4.75666 15.8535 4.68337 15.9081C4.61007 15.9626 4.61872 16.0984 4.70268 16.2113C4.78664 16.3242 4.91412 16.3715 4.98741 16.3169Z"
        fill="#5A5F63"
      />
      <path
        d="M8.56159 17.4729C9.09665 17.4729 9.5304 17.039 9.5304 16.5037C9.5304 15.9684 9.09665 15.5344 8.56159 15.5344C8.02653 15.5344 7.59277 15.9684 7.59277 16.5037C7.59277 17.039 8.02653 17.4729 8.56159 17.4729Z"
        fill="#3E4347"
      />
      <path
        d="M9.12355 16.3107C9.19682 16.2561 9.18815 16.1204 9.10418 16.0075C9.0202 15.8946 8.89273 15.8474 8.81945 15.9019C8.74617 15.9565 8.75484 16.0922 8.83882 16.2051C8.92279 16.318 9.05027 16.3653 9.12355 16.3107Z"
        fill="#5A5F63"
      />
      <path
        d="M37.2634 16.0861C41.5279 16.0861 44.985 12.6274 44.985 8.3609C44.985 4.09441 41.5279 0.635742 37.2634 0.635742C32.9989 0.635742 29.5419 4.09441 29.5419 8.3609C29.5419 12.6274 32.9989 16.0861 37.2634 16.0861Z"
        fill="#8F8F8F"
      />
      <path
        d="M37.2635 13.3253C36.1645 13.3282 35.0959 12.9638 34.2274 12.29C33.3589 11.6162 32.7402 10.6715 32.4694 9.60587C32.4481 9.52332 32.4605 9.43571 32.5039 9.36231C32.5472 9.28891 32.6179 9.23574 32.7004 9.21449C32.7829 9.19324 32.8705 9.20566 32.9439 9.24901C33.0172 9.29236 33.0704 9.36309 33.0916 9.44564C33.3279 10.3703 33.8652 11.1899 34.6187 11.7753C35.3722 12.3606 36.2991 12.6783 37.253 12.6783C38.207 12.6783 39.1338 12.3606 39.8873 11.7753C40.6408 11.1899 41.1781 10.3703 41.4144 9.44564C41.4357 9.36309 41.4888 9.29236 41.5622 9.24901C41.6355 9.20566 41.7231 9.19324 41.8056 9.21449C41.8881 9.23574 41.9588 9.28891 42.0022 9.36231C42.0455 9.43571 42.0579 9.52332 42.0367 9.60587C41.7667 10.668 41.1511 11.6101 40.2869 12.2836C39.4227 12.957 38.359 13.3235 37.2635 13.3253Z"
        fill="#3E4347"
      />
      <path
        d="M34.7693 8.6918C35.4233 8.6918 35.9534 8.06146 35.9534 7.28389C35.9534 6.50632 35.4233 5.87598 34.7693 5.87598C34.1153 5.87598 33.5852 6.50632 33.5852 7.28389C33.5852 8.06146 34.1153 8.6918 34.7693 8.6918Z"
        fill="#3E4347"
      />
      <path
        d="M35.3049 6.9818C35.248 7.01274 35.1817 7.02154 35.1187 7.00651C35.0556 6.99148 35.0004 6.95367 34.9636 6.90037C34.9353 6.88073 34.9121 6.85475 34.8956 6.8245C34.8792 6.79426 34.8701 6.76059 34.869 6.72619C34.8679 6.69179 34.8749 6.65761 34.8894 6.62638C34.9038 6.59515 34.9254 6.56775 34.9523 6.54635C34.9793 6.52495 35.0109 6.51016 35.0446 6.50316C35.0782 6.49615 35.1131 6.49712 35.1463 6.506C35.1796 6.51488 35.2103 6.53141 35.236 6.55427C35.2617 6.57714 35.2817 6.60571 35.2944 6.63769C35.3373 6.68418 35.3619 6.74457 35.3639 6.80779C35.3658 6.871 35.3449 6.93279 35.3049 6.9818Z"
        fill="#5A5F63"
      />
      <path
        d="M39.7577 8.6918C40.4116 8.6918 40.9418 8.06146 40.9418 7.28389C40.9418 6.50632 40.4116 5.87598 39.7577 5.87598C39.1037 5.87598 38.5736 6.50632 38.5736 7.28389C38.5736 8.06146 39.1037 8.6918 39.7577 8.6918Z"
        fill="#3E4347"
      />
      <path
        d="M40.2906 6.98176C40.2339 7.01195 40.1682 7.02033 40.1058 7.00533C40.0434 6.99033 39.9886 6.95298 39.9519 6.90034C39.923 6.88109 39.8991 6.8553 39.8821 6.82507C39.865 6.79483 39.8554 6.761 39.8538 6.72632C39.8523 6.69165 39.859 6.6571 39.8734 6.6255C39.8877 6.5939 39.9093 6.56612 39.9364 6.54444C39.9635 6.52276 39.9953 6.50778 40.0293 6.50071C40.0633 6.49365 40.0984 6.4947 40.1319 6.50379C40.1654 6.51287 40.1962 6.52973 40.222 6.55299C40.2477 6.57626 40.2676 6.60527 40.2801 6.63768C40.3229 6.68416 40.3476 6.74455 40.3495 6.80776C40.3514 6.87097 40.3305 6.93275 40.2906 6.98176Z"
        fill="#5A5F63"
      />
    </svg>
  );
};

export default ClimateResearchDisabledIcon;
