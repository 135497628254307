import React, { FC } from 'react';
import { Input } from 'components/Forms/Input';

type SearchFieldProps = {
  onSearch: (search: string) => void;
  delay?: number;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
};

const SearchField: FC<SearchFieldProps> = ({ inputProps, delay, onSearch }) => {
  const searchTimeout = React.useRef<NodeJS.Timeout | null>(null);

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    clearTimeout(searchTimeout.current!);

    const search = e.target.value;

    if (!delay) {
      onSearch(search);
      return;
    }

    searchTimeout.current = setTimeout(() => {
      onSearch(search);
    }, delay);
  }

  return (
    <Input
      {...inputProps}
      onChange={e => {
        handleSearch(e);
        inputProps?.onChange?.(e);
      }}
    />
  );
};

export default SearchField;
