import React from 'react';

const PolicyIcon: React.FC = () => {
  return (
    <svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.5749 36.2177H1.20686C0.540573 36.2177 0 35.6777 0 35.0109V1.20686C0 0.540571 0.540002 0 1.20686 0H25.5749C26.2411 0 26.7817 0.54 26.7817 1.20686V35.0114C26.7817 35.6777 26.2411 36.2177 25.5749 36.2177Z"
        fill="#DEF5FC"
      />
      <path
        d="M1.204 0H4.78457V36.1326H1.204C0.538858 36.1326 0 35.5937 0 34.9286V1.204C0 0.538858 0.538858 0 1.204 0Z"
        fill="#AFE0EF"
      />
      <path
        d="M25.6651 36.2177H4.97656C4.35999 36.2177 3.85999 35.7177 3.85999 35.1011V1.11657C3.85999 0.5 4.35999 0 4.97656 0H25.6651C26.2817 0 26.7817 0.5 26.7817 1.11657V35.1017C26.7817 35.7177 26.2817 36.2177 25.6651 36.2177Z"
        fill="#C7EEFD"
      />
      <path
        d="M16.9189 5.18379H9.86229C9.57201 5.18379 9.33429 4.94608 9.33429 4.65579V4.40894C9.33429 4.11865 9.57201 3.88094 9.86229 3.88094H16.9189C17.2091 3.88094 17.4469 4.11865 17.4469 4.40894V4.65579C17.4469 4.94608 17.2091 5.18379 16.9189 5.18379Z"
        fill="#01CFFA"
      />
      <path
        d="M19.9795 9.91406H6.80231C6.44402 9.91406 6.15088 9.62092 6.15088 9.26263C6.15088 8.90435 6.44402 8.61121 6.80231 8.61121H19.9795C20.3377 8.61121 20.6309 8.90435 20.6309 9.26263C20.6303 9.62092 20.3377 9.91406 19.9795 9.91406Z"
        fill="#01CFFA"
      />
      <path
        d="M19.9795 13.2249H6.80231C6.44402 13.2249 6.15088 12.9318 6.15088 12.5735C6.15088 12.2152 6.44402 11.9221 6.80231 11.9221H19.9795C20.3377 11.9221 20.6309 12.2152 20.6309 12.5735C20.6303 12.9318 20.3377 13.2249 19.9795 13.2249Z"
        fill="#01CFFA"
      />
      <path
        d="M19.9795 16.5352H6.80231C6.44402 16.5352 6.15088 16.2421 6.15088 15.8838C6.15088 15.5255 6.44402 15.2324 6.80231 15.2324H19.9795C20.3377 15.2324 20.6309 15.5255 20.6309 15.8838C20.6303 16.2421 20.3377 16.5352 19.9795 16.5352Z"
        fill="#01CFFA"
      />
      <path
        d="M10.5726 19.8461H6.80231C6.44402 19.8461 6.15088 19.5529 6.15088 19.1946C6.15088 18.8364 6.44402 18.5432 6.80231 18.5432H10.5726C10.9309 18.5432 11.224 18.8364 11.224 19.1946C11.2235 19.5529 10.9303 19.8461 10.5726 19.8461Z"
        fill="#01CFFA"
      />
      <path
        d="M19.9795 19.8461H12.5892C12.2309 19.8461 11.9377 19.5529 11.9377 19.1946C11.9377 18.8364 12.2309 18.5432 12.5892 18.5432H19.9795C20.3377 18.5432 20.6309 18.8364 20.6309 19.1946C20.6303 19.5529 20.3377 19.8461 19.9795 19.8461Z"
        fill="#01CFFA"
      />
      <path
        d="M19.9795 23.1569H6.80231C6.44402 23.1569 6.15088 22.8638 6.15088 22.5055C6.15088 22.1472 6.44402 21.8541 6.80231 21.8541H19.9795C20.3377 21.8541 20.6309 22.1472 20.6309 22.5055C20.6303 22.8638 20.3377 23.1569 19.9795 23.1569Z"
        fill="#01CFFA"
      />
      <path
        d="M19.9795 26.4678H6.80231C6.44402 26.4678 6.15088 26.1747 6.15088 25.8164C6.15088 25.4581 6.44402 25.1649 6.80231 25.1649H19.9795C20.3377 25.1649 20.6309 25.4581 20.6309 25.8164C20.6303 26.1747 20.3377 26.4678 19.9795 26.4678Z"
        fill="#01CFFA"
      />
      <path
        d="M19.9795 29.7781H6.80231C6.44402 29.7781 6.15088 29.4849 6.15088 29.1266C6.15088 28.7684 6.44402 28.4752 6.80231 28.4752H19.9795C20.3377 28.4752 20.6309 28.7684 20.6309 29.1266C20.6303 29.4855 20.3377 29.7781 19.9795 29.7781Z"
        fill="#01CFFA"
      />
      <path d="M22.2885 34.3049H25.1057V39.9997L22.2885 38.4271V34.3049Z" fill="#F01F6C" />
      <path d="M22.2885 38.4272L19.4714 39.9998V34.3049H22.2885V38.4272Z" fill="#FF6136" />
      <path
        d="M22.2714 35.2446L20.5234 34.3503L18.5571 34.2606L17.4926 32.6006L15.832 31.5514L15.696 29.492L14.8268 27.8429L15.6531 25.956L15.8108 24.128L17.4406 22.9L18.52 21.4034L20.5377 21.1389L22.2286 20.3977L22.2714 35.2446Z"
        fill="#FBB91E"
      />
      <path
        d="M22.2285 20.3975L24.0068 21.2238L25.9428 21.3815L27.1262 22.9666L28.6679 24.0906L28.8862 26.0238L29.6731 27.7998L28.8805 29.4449L28.6891 31.514L27.0839 32.6003L25.9799 34.2386L24.0068 34.3655L22.2714 35.2443L22.2285 20.3975Z"
        fill="#F98A52"
      />
      <path
        d="M22.2496 23.0287C24.8628 23.0287 26.9805 25.147 26.9805 27.7596C26.9805 30.3722 24.8622 32.4904 22.2496 32.4904V23.0287Z"
        fill="#FBB91E"
      />
      <path
        d="M22.2497 32.4906C19.6365 32.4906 17.5188 30.3723 17.5188 27.7597C17.5188 25.1472 19.6371 23.0289 22.2497 23.0289V32.4906Z"
        fill="#FEDE0E"
      />
      <path d="M22.2662 29.1154L24.7988 26.5829L23.9788 25.7634L22.2348 27.5068L22.2662 29.1154Z" fill="#3E3B58" />
      <path
        d="M22.2348 27.5069L21.772 27.9571L20.4783 26.6634L19.6583 27.4834L20.952 28.7771L21.772 29.5966L22.2663 29.1154L22.2348 27.5069Z"
        fill="#58557D"
      />
    </svg>
  );
};

export default PolicyIcon;
