import { links } from "safira-app/config/links";
import api from "services/api";

export type CollaboratorStatus = "LINKED" | "UNLINKED" | "PENDING";

export interface CollaboratorsItemProps {
  id: string;
  unit_id: string | string;
  allocated_sector?: {
    name: string;
  };
  person: {
    name: string;
    avatar: string;
  };
  status: CollaboratorStatus;
  allocated_unit?: {
    name: string;
  };
}

export const getCollaborators = async (
  status: CollaboratorStatus,
  page: number,
  perPage: number,
  companyId: string,
  onSelect: boolean
) => {
  const response = await api.get(
    links.api.core +
      `/engineering/collaborators?status=${status}&onSelect=${onSelect}&manager=true&page=${page}&perPage=${perPage}`,
    {
      headers: {
        companyId: companyId,
      },
    }
  );

  return response.data.data as CollaboratorsItemProps[];
};
