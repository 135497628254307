import React, { ReactNode } from "react";
import { Label } from "../Label";
import { FieldWrapped } from "../FieldWrapped";
import { SelectBase } from "./Select";
import { HelperText } from "../Helpers/HelperText";
import { FieldError } from "react-hook-form";

interface SelectProps {
  label?: string;
  required?: boolean;
  children: ReactNode;
  error?: FieldError | undefined;
  inputProps?: React.SelectHTMLAttributes<HTMLSelectElement>;
}

const Select: React.FC<SelectProps> = ({
  label,
  required,
  children,
  error,
  inputProps,
}) => {
  const isRequired = required !== undefined ? required : false;

  return (
    <div>
      <FieldWrapped>
        {label && (
          <Label>
            {label}
            {isRequired && (
              <span title="Campo obrigatório" style={{ color: "#D40000" }}>
                *
              </span>
            )}
          </Label>
        )}

        <SelectBase
          {...inputProps}
          required={isRequired}
          hasError={Boolean(error)}
        >
          {children}
        </SelectBase>
      </FieldWrapped>
      {error ? <HelperText color="danger">{error.message}</HelperText> : null}
    </div>
  );
};

export default Select;
