import React, { useEffect, useState } from 'react';
import Layout from 'layout/Layout';
import { getVacationsByManagers } from 'requests/vacations';
import { usePermissions } from 'safira-app/contexts/Permissions';
import { GridCol, GridContainer, GridRow, HeaderPage } from 'layout/components/page-styles';
import {
  ExpansiveTable,
  ExpansiveTableBody,
  ExpansiveTableHead,
  ExpansiveTableHeaderCell,
} from 'components/ExpansiveTable/styles';
import AcquisitiveItem from './AcquisitiveItem';
import { GeneralVacationItem } from 'interfaces/Vacations';
import { useTranslation } from 'react-i18next';

interface VacationsPageProps {}

const VacationsPage: React.FC<VacationsPageProps> = () => {
  const { t } = useTranslation();
  const { companyId } = usePermissions();
  const [vacations, setVacations] = useState<GeneralVacationItem[]>([]);

  useEffect(() => {
    getVacationsByManagers(companyId).then(response => {
      setVacations(response);
    });
  }, [companyId]);

  return (
    <Layout>
      <GridContainer style={{ marginBottom: '16px' }}>
        <GridRow>
          <GridCol xs={12} md={12} lg={12}>
            <HeaderPage>
              <h1>{t('vacation.vacation_control')}</h1>
            </HeaderPage>
          </GridCol>
        </GridRow>
      </GridContainer>
      <GridContainer>
        <GridRow>
          <GridCol xs={12} md={12} lg={12}>
            <ExpansiveTable>
              <ExpansiveTableHead>
                <ExpansiveTableHeaderCell style={{ width: '15px' }}></ExpansiveTableHeaderCell>
                <ExpansiveTableHeaderCell style={{ width: '370px' }}>{t('vacation.employee')}</ExpansiveTableHeaderCell>
                <ExpansiveTableHeaderCell>{t('vacation.vesting_period')}</ExpansiveTableHeaderCell>
                <ExpansiveTableHeaderCell>{t('vacation.concessive_period')}</ExpansiveTableHeaderCell>
                <ExpansiveTableHeaderCell>{t('vacation.balance')}</ExpansiveTableHeaderCell>
                <ExpansiveTableHeaderCell>{t('vacation.bonus')}</ExpansiveTableHeaderCell>
                <ExpansiveTableHeaderCell>{t('vacation.status')}</ExpansiveTableHeaderCell>
                <ExpansiveTableHeaderCell style={{ textAlign: 'right' }}>
                  {t('vacation.actions')}
                </ExpansiveTableHeaderCell>
              </ExpansiveTableHead>
              <ExpansiveTableBody>
                {vacations.map(vacation => (
                  <AcquisitiveItem key={vacation.id} item={vacation} />
                ))}
              </ExpansiveTableBody>
            </ExpansiveTable>
          </GridCol>
        </GridRow>
      </GridContainer>
    </Layout>
  );
};

export default VacationsPage;
