import React, { forwardRef } from 'react';
import { DynamicProps, TagType } from './types';

function ModelComponent<T extends TagType>(props: DynamicProps<T>, ref: any) {
  const { component, children, ...rest } = props as any;
  const TagName = component || 'div';

  return (
    <TagName {...rest} ref={ref}>
      {children}
    </TagName>
  );
}

export default forwardRef(ModelComponent);
