import React from 'react';

const EndoMarketingIcon: React.FC = () => {
  return (
    <svg width="41" height="48" viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.4069 20.0274C2.12897 17.0923 2.38677 14.2358 3.70259 11.5457C5.47089 7.9386 8.39055 5.75407 12.189 4.643C12.8838 4.43824 13.588 4.30935 14.293 4.16568C14.6186 4.0972 14.7629 4.12808 14.8159 4.50135C14.9347 5.34522 14.9549 5.3271 14.111 5.48889C10.4361 6.20118 7.39763 7.91846 5.38698 11.1671C4.03826 13.3496 3.5757 15.7792 3.58376 18.3074C3.58376 19.1083 3.70259 19.9066 3.76032 20.7068C3.81604 21.4647 3.18163 21.068 2.87886 21.2116C2.51768 21.3835 2.49485 21.0881 2.4774 20.8276C2.45994 20.5685 2.43242 20.2953 2.4069 20.0274Z"
        fill="#2682FF"
      />
      <path
        d="M38.0308 16.6331C37.003 15.2414 36.8311 14.1424 37.5991 12.5588C39.0492 9.57063 38.7203 6.55766 36.8063 3.85015C34.8185 1.03657 31.9921 -0.191307 28.5649 0.145034C25.6527 0.437738 23.4554 1.961 21.9046 4.42146C20.8942 6.04274 19.4321 6.75503 17.5349 6.62412C16.9407 6.57377 16.3446 6.55766 15.7491 6.57646C11.6647 6.76107 8.51274 8.63746 6.42622 12.0754C4.28666 15.6113 4.15307 19.2332 5.84014 22.8148C6.02342 23.2055 6.22885 23.5962 6.45643 23.9869H6.47657C6.47657 23.9869 6.94651 24.6616 7.78971 25.6337L7.95418 25.8204C8.49193 25.4746 8.97932 25.1309 9.42643 24.7771C11.4747 23.1659 12.6395 21.4607 13.1618 19.2809L12.7905 18.6491C12.2038 17.6448 12.5428 16.3545 13.5471 15.7677L13.5478 15.7671L16.0734 14.2868C17.0777 13.7 18.368 14.0391 18.9547 15.0434L18.9554 15.044L28.7657 31.7342C29.3511 32.7392 29.0121 34.0288 28.0077 34.6163L25.4822 36.0966C24.4765 36.6806 23.1882 36.3416 22.6001 35.3386L22.4611 35.101C21.1856 34.7324 19.8422 34.6572 18.5338 34.8814C18.1787 34.9412 17.8269 35.0204 17.4805 35.1191C21.3729 38.6168 26.9034 39.6244 31.7786 37.7232C40.2362 34.5108 43.4156 23.9252 38.0308 16.6331Z"
        fill="#00568B"
      />
      <path
        d="M2.14711 32.6895C0.733938 33.5199 0.261988 35.3393 1.09243 36.7524C1.92288 38.1656 3.74221 38.6376 5.15537 37.8071L9.82319 35.0613L6.82029 29.9444L2.14711 32.6895Z"
        fill="#FC4E51"
      />
      <path
        d="M14.0056 34.4551C15.2912 36.6423 15.9451 39.0545 15.8471 41.2497C15.7383 43.7626 14.683 45.7329 12.8744 46.8064C12.1131 47.2562 11.1471 47.1535 10.4979 46.554L10.4193 46.4781C9.65736 45.6779 9.68892 44.4117 10.4892 43.6498C10.5993 43.545 10.7208 43.4537 10.851 43.3765C11.1994 43.1691 11.7929 42.6011 11.8614 41.1034C11.9245 39.6687 11.4646 38.0374 10.5657 36.5067L14.0056 34.4551Z"
        fill="#FC4E51"
      />
      <path
        d="M14.0056 34.4551C15.2912 36.6423 15.9451 39.0545 15.8471 41.2497C15.7384 43.7626 14.683 45.7329 12.8744 46.8064C12.1131 47.2562 11.1471 47.1535 10.4979 46.554L10.4193 46.4781L10.892 46.1827C11.6298 45.7229 12.2689 45.122 12.7737 44.4144C13.4169 43.5209 13.7868 42.4602 13.8398 41.3605C13.8398 41.3431 13.8398 41.3249 13.8398 41.3048C13.9029 39.8399 13.7841 38.324 12.279 35.4957L14.0056 34.4551Z"
        fill="#E5394B"
      />
      <path
        d="M15.5262 18.6458L24.1643 33.3407C24.3254 33.6139 24.2993 33.959 24.0985 34.2047C24.0421 34.2752 23.9723 34.3343 23.8938 34.3793C23.7045 34.4921 23.4762 34.5169 23.2674 34.4477C21.7032 33.8993 20.0249 33.7556 18.3901 34.0308C17.3999 34.2074 16.4339 34.4981 15.5108 34.8969C14.6018 35.2836 13.7203 35.7307 12.8711 36.2355C10.3643 37.7024 7.14456 36.88 5.64747 34.392C4.93183 33.195 4.72707 31.761 5.07885 30.4117C5.43735 29.0092 6.33627 27.8049 7.57959 27.0624C8.40735 26.579 9.20221 26.0412 9.95881 25.4531C12.3481 23.5788 13.6364 21.5131 14.1164 18.8935C14.1876 18.4813 14.579 18.204 14.9918 18.2752C15.2127 18.3135 15.406 18.4471 15.5188 18.6411L15.5262 18.6458Z"
        fill="#FFDA44"
      />
      <path
        d="M15.5262 18.6458L24.1643 33.3407C24.3254 33.6139 24.2993 33.959 24.0985 34.2047C24.0421 34.2752 23.9723 34.3343 23.8938 34.3793C23.7045 34.4921 23.4762 34.5169 23.2674 34.4477C21.7032 33.8993 20.0248 33.7556 18.3901 34.0308C17.3999 34.2074 16.4339 34.4981 15.5108 34.8969L9.9541 25.4531C12.3488 23.5788 13.6364 21.5131 14.1164 18.8935C14.1876 18.4813 14.579 18.204 14.9918 18.2752C15.2127 18.3135 15.406 18.4471 15.5188 18.6411L15.5262 18.6458Z"
        fill="#FF9F22"
      />
      <path
        d="M16.4909 15.0293L13.9754 16.5069C13.3826 16.8553 13.1846 17.6186 13.533 18.2114C13.533 18.2114 13.533 18.2114 13.533 18.2121L23.3433 34.9022C23.6984 35.487 24.4537 35.683 25.0485 35.344L27.574 33.8637C28.1588 33.5119 28.3521 32.756 28.0084 32.1665L18.1961 15.471C17.847 14.8782 17.0844 14.6802 16.4909 15.0293Z"
        fill="#E5394B"
      />
      <path
        d="M33.3328 7.89632C33.4677 8.26085 33.4617 8.66298 33.3153 9.02282C33.6409 9.1544 33.8927 9.42093 34.0075 9.75257C34.1458 10.0527 34.1538 10.3964 34.0303 10.7025C33.9846 10.8086 33.9222 10.9066 33.8457 10.9932C34.0921 11.1389 34.2841 11.3618 34.3915 11.6269C34.5432 11.9801 34.5432 12.3802 34.3915 12.7333C34.2639 13.0173 34.0478 13.2523 33.7752 13.4026C33.8725 13.5107 33.9484 13.6369 33.9974 13.7739C34.0202 13.8323 34.0578 13.9384 34.0706 13.9887C34.0981 14.1002 34.1075 14.215 34.0981 14.3298C34.09 14.4372 34.0639 14.5419 34.0196 14.6406C33.8665 15.0031 33.5785 15.2925 33.2166 15.4462L31.9055 15.9866C31.3624 16.2115 30.8622 16.4008 30.3903 16.5526C29.6196 16.8244 28.816 16.9943 28.001 17.0574C27.7835 17.0574 27.5895 17.0574 27.4176 17.0574C27.1625 17.0292 26.9047 17.054 26.6597 17.1306L25.801 17.4844C25.4882 17.6408 25.129 17.6791 24.7907 17.5904C24.4651 17.4461 24.2153 17.1709 24.1039 16.8325L22.4826 12.9052C22.3363 12.587 22.3208 12.2244 22.4396 11.8948C22.5893 11.5672 22.868 11.3161 23.2097 11.2027L23.2882 11.1724C23.5588 11.0637 24.0005 10.8844 24.1851 10.5186C24.1851 10.4783 24.1851 10.44 24.1851 10.4071C24.1851 10.3742 24.1851 10.3615 24.1851 10.344C24.1851 10.3266 24.1851 10.344 24.1851 10.3286C24.1844 10.2769 24.1905 10.2252 24.2026 10.1742C24.2435 9.88482 24.3113 9.60017 24.4046 9.32291C24.551 8.93219 24.7578 8.56698 25.0156 8.23937C25.2807 7.95271 25.4445 7.5875 25.4828 7.1988V7.1612C25.5439 6.72617 25.5567 6.28578 25.5204 5.84806C25.505 5.73796 25.4734 5.63055 25.4271 5.52985C25.2619 5.19485 25.2619 4.80145 25.4271 4.46645C25.5191 4.30063 25.658 4.16569 25.8259 4.07774L25.8433 4.06499H25.8635C26.2173 3.9032 26.6134 3.85754 26.9953 3.93341C27.5002 4.05223 27.8943 4.43825 28.1521 5.05253C28.5166 5.93332 28.7032 6.8779 28.7026 7.8312L31.635 7.02828C32.3426 6.81613 33.0904 7.19947 33.3328 7.89632Z"
        fill="#FFBB85"
      />
      <path
        d="M33.3328 7.89632C33.4677 8.26085 33.4617 8.66298 33.3153 9.02282C33.6409 9.1544 33.8927 9.42093 34.0075 9.75257C34.1458 10.0527 34.1538 10.3964 34.0303 10.7025C33.9846 10.8086 33.9222 10.9066 33.8457 10.9932C34.0921 11.1389 34.2841 11.3618 34.3915 11.6269C34.5432 11.9801 34.5432 12.3802 34.3915 12.7333C34.2639 13.0173 34.0478 13.2523 33.7752 13.4026C33.8725 13.5107 33.9484 13.6369 33.9974 13.7739C34.0202 13.8323 34.0578 13.9384 34.0706 13.9887C34.0981 14.1002 34.1075 14.215 34.0981 14.3298C34.09 14.4372 34.0639 14.5419 34.0196 14.6406C33.8665 15.0031 33.5785 15.2925 33.2166 15.4462L31.9055 15.9866C31.3624 16.2115 30.8622 16.4008 30.3903 16.5526L25.4096 4.47182C25.5016 4.306 25.6406 4.17106 25.8084 4.08311L25.8259 4.07036H25.846C26.1998 3.90857 26.5959 3.86292 26.9779 3.93878C27.4827 4.0576 27.8768 4.44362 28.1346 5.0579C28.4991 5.93869 28.6858 6.88327 28.6851 7.83657L31.6175 7.03365C32.3291 6.81009 33.0884 7.19208 33.3328 7.89632Z"
        fill="#F5A86C"
      />
      <path
        d="M38.0033 24.3367C37.8113 25.1148 37.3467 25.7559 36.5384 26.3574C35.6456 26.9596 34.7057 27.4887 33.7275 27.9384C33.3435 28.1305 32.9092 28.3473 32.4648 28.5823C32.3453 28.6427 32.2083 28.6588 32.0781 28.6279C31.9472 28.595 31.8324 28.5165 31.7545 28.4057C31.4638 27.9888 31.1812 27.5947 30.9307 27.249C30.2688 26.3957 29.6774 25.4901 29.1624 24.5415C28.7254 23.6372 28.6093 22.8544 28.8013 22.0763C28.9725 21.3499 29.4108 20.7142 30.0285 20.2959C30.628 19.8972 31.3691 19.7709 32.0666 19.9502C32.591 20.0771 33.0569 20.3778 33.3878 20.8041C33.5684 21.0344 33.7148 21.2895 33.8222 21.5621C34.0424 21.3734 34.2881 21.217 34.5519 21.0975C35.044 20.874 35.5965 20.8243 36.1202 20.9559C36.8231 21.1244 37.4206 21.5836 37.7643 22.2186C38.1053 22.8698 38.1899 23.6251 38.0033 24.3367Z"
        fill="#FF5E95"
      />
      <path
        d="M38.0033 24.3367C37.8113 25.1148 37.3467 25.7559 36.5384 26.3575C35.6456 26.9596 34.7057 27.4887 33.7275 27.9385C33.3435 28.1305 32.9092 28.3473 32.4648 28.5823C32.3453 28.6427 32.2083 28.6588 32.0781 28.6279L33.8235 21.556C34.0437 21.3674 34.2895 21.211 34.5533 21.0915C35.0454 20.8679 35.5979 20.8182 36.1215 20.9498C36.8244 21.1183 37.4219 21.5775 37.7656 22.2126C38.1067 22.8665 38.1913 23.6238 38.0033 24.3367Z"
        fill="#FF3980"
      />
    </svg>
  );
};

export default EndoMarketingIcon;
