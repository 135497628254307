import React, { ReactNode } from "react";
import ReactModal from "react-modal";
import { customStyles } from "./custom";
import CloseIcon from "./icons/Close";
import styled from "styled-components";

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ModalHeaderTitle = styled.h2`
  font-size: 20px;
  font-weight: normal;
  color: rgba(33, 37, 41, 1);
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ModalHeaderClose = styled.button`
  background: transparent;
  border: none;
  width: 30px;
  height: 30px;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 12px;

  &:hover {
    background: #f2f2f2;
    cursor: pointer;
  }
`;

export const ModalContent = styled.div`
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding: 16px;

  p {
    margin-bottom: 8px;
  }

  strong {
    font-weight: 600;
  }

  ul {
    margin-left: 35px;
    margin-bottom: 8px;
  }

`;

export const ModalActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 12px 16px;
  gap: 8px;
`;

interface ModalProps {
  open: boolean;
  title: string;
  children: ReactNode;
  onClose?: () => void;
  icon: ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  open,
  title,
  children,
  onClose,
  icon,
}) => {

  const onRequestClose = (e) => {
    if (onClose) { 
      onClose();
    } else {
      e.preventDefault();
    }
  }

  return (
    <ReactModal isOpen={open} style={customStyles} onRequestClose={onRequestClose} >
      <ModalHeader>
        <ModalHeaderTitle>
          {icon && icon}
          {title}
        </ModalHeaderTitle>
        {onClose && (
          <div>
            <ModalHeaderClose onClick={onClose}>
              <CloseIcon />
            </ModalHeaderClose>
          </div>
        )}
      </ModalHeader>
      {children}
    </ReactModal>
  );
};

export default Modal;
