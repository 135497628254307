import React from 'react';

const TaskIcon: React.FC = () => {
  return (
    <svg width="35" height="38" viewBox="0 0 35 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.435 37.2262H1.56446C0.700265 37.2262 0 36.5259 0 35.6617V3.79061C0 2.92642 0.700265 2.22615 1.56446 2.22615H33.4355C34.2997 2.22615 35 2.92642 35 3.79061V35.6617C35 36.5259 34.2992 37.2262 33.435 37.2262Z"
        fill="#515151"
      />
      <path
        d="M30.1017 33.8929H4.89743C4.03324 33.8929 3.33298 33.1927 3.33298 32.3285V7.12425C3.33298 6.26006 4.03324 5.5598 4.89743 5.5598H30.1017C30.9659 5.5598 31.6661 6.26006 31.6661 7.12425V32.3285C31.6661 33.1922 30.9659 33.8929 30.1017 33.8929Z"
        fill="#F1F1F1"
      />
      <path
        d="M7.55086 8.00806H5.78216C5.35033 8.00806 4.99966 7.65793 4.99966 7.22557V0.873314C4.99966 0.441483 5.3498 0.0908203 5.78216 0.0908203H7.55086C7.98269 0.0908203 8.33335 0.440953 8.33335 0.873314V7.2261C8.33335 7.65793 7.98269 8.00806 7.55086 8.00806Z"
        fill="#0C7DBE"
      />
      <path
        d="M12.5509 8.20541H10.7822C10.3504 8.20541 9.99969 7.85527 9.99969 7.42291V0.873314C9.99969 0.441483 10.3498 0.0908203 10.7822 0.0908203H12.5509C12.9827 0.0908203 13.3334 0.440953 13.3334 0.873314V7.42291C13.3334 7.85527 12.9832 8.20541 12.5509 8.20541Z"
        fill="#0C7DBE"
      />
      <path
        d="M9.27242 19.9248L15.1594 25.6543L12.4745 25.7312L25.595 9.98478C25.7891 9.75189 26.1355 9.72006 26.369 9.91422C26.5859 10.0951 26.6284 10.4076 26.4767 10.6384L15.2199 27.7667L15.178 27.8304C14.6968 28.5625 13.7127 28.7662 12.9806 28.285C12.8008 28.1667 12.6485 28.0113 12.535 27.8437L7.95412 21.0246C7.68833 20.6288 7.7939 20.093 8.18913 19.8272C8.53449 19.5954 8.98595 19.6458 9.27242 19.9248Z"
        fill="#30AA06"
      />
    </svg>
  );
};

export default TaskIcon;
