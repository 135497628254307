import React, { useEffect, useState } from 'react';
import Modal, { ModalActions, ModalContent } from 'components/Modal';
import OccurrencesIcon from 'components/Icons/OccurrencesIcon';
import { Button, OutlinedButton } from 'components/Buttons/styles';
import { GridCol, GridContainer, GridRow } from 'layout/components/page-styles';
import { FieldWrapped } from 'components/Forms/FieldWrapped';
import { Label } from 'components/Forms/Label';
import SelectAutocomplete from 'components/Forms/SelectAutocomplete';
import { FormProvider, useForm } from 'react-hook-form';
import { RadioContainer, RadioGroup, RadioInput, RadioLabel, RadioStyled } from 'components/Forms/Radio';
import { InputBase } from 'components/Forms/Input/Input';
import Textarea from 'components/Forms/Textarea';
import { OccurrenceFooterDescription } from 'pages/Occurrences/styles';
import * as yup from 'yup';
import { getCollaborators } from 'requests/collaborators';
import { usePermissions } from 'safira-app/contexts/Permissions';
import { OccurrenceForm, OccurrenceItemProps, OccurrenceShowProps } from 'interfaces/Occurrences';
import { HelperText } from 'components/Forms/Helpers/HelperText';
import LoadingComponent from 'components/LoadingComponent';
import { createOccurrence, editOccurrence, getOccurrenceMotives } from 'requests/occurrences';
import { AxiosError } from 'axios';
import { addToast } from 'safira-app/components/Toast';
import { useTranslation } from 'react-i18next';

interface OccurrenceModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'ADD' | 'EDIT' | 'VIEW';
  id?: string;
  setOccurrenceList: React.Dispatch<React.SetStateAction<OccurrenceItemProps[]>>;
  dataEdit?: OccurrenceShowProps;
}

interface AutocompleteProps {
  value: string;
  label: string;
}

const convertForAutocomplete = data =>
  data.map(item => ({
    value: item.id,
    label: item.person.name,
  }));

const convertMotiveForAutocomplete = data =>
  data.map(item => ({
    value: item.id,
    label: item.name,
  }));

const convertDateToField = (date: string) => {
  const formatedDate = new Date(date).toISOString().split('T')[0];
  return formatedDate;
};

const maxCharacters = {
  description: 1048,
};

interface ValueFieldProps {
  max: number;
  min: number;
}

const ValueField: ValueFieldProps = {
  max: 999999999999999,
  min: -999999999999999,
};

const schema = yup.object({
  collaboratorId: yup.string().required('Campo obrigatório'),
  description: yup
    .string()
    .nullable()
    .max(maxCharacters.description, `Máximo de caracteres: ${maxCharacters.description}`),
  quantity: yup
    .number()
    .transform((originalValue, originalObject) => (originalObject === '' ? null : originalValue))
    .nullable()
    .integer('Valor inválido.')
    .min(ValueField.min, 'Valor mínimo: 15 digitos.')
    .max(ValueField.max, 'Valor máximo: 15 digitos.')
    .test('is-number', 'Insira um número válido', (value: any) => !isNaN(value as any))
    .test('not-zero', 'O valor não pode ser 0', (value: any) => value !== 0),
  value: yup
    .number()
    .transform((originalValue, originalObject) => (originalObject === '' ? null : originalValue))
    .nullable()
    .integer('Valor inválido.')
    .min(ValueField.min, 'Valor mínimo: 15 digitos.')
    .max(ValueField.max, 'Valor máximo: 15 digitos.')
    .test('is-number', 'Insira um número válido', (value: any) => !isNaN(value as any))
    .test('not-zero', 'O valor não pode ser 0', (value: any) => value !== 0),
  type: yup.string().required('Seleção obrigatória'),
  occurrenceDate: yup.string().required('Campo obrigatório'),
  reasonId: yup.string().required('Campo obrigatório'),
});

const OccurrenceModal: React.FC<OccurrenceModalProps> = ({ open, setOpen, type, setOccurrenceList, dataEdit }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingCollaborators, setLoadingCollaborators] = useState(true);
  const [collaboratorsList, setCollaboratorsList] = useState<AutocompleteProps[]>([]);
  const [loadingMotives, setLoadingMotives] = useState(true);
  const [motiveList, setMotiveList] = useState<AutocompleteProps[]>([]);

  const methods = useForm<OccurrenceForm>({
    // TODO: add yupresolver to validate data
    // resolver: yupResolver(schema),
  });

  const { companyId } = usePermissions();

  const currentDate = new Date().toISOString().split('T')[0];

  useEffect(() => {
    getCollaborators('LINKED', 1, 99999, companyId, true)
      .then(response => {
        const formatedCollaborators = convertForAutocomplete(response);
        setCollaboratorsList(formatedCollaborators);
      })
      .catch((err: AxiosError) => {
        addToast(`Erro ao carregar colaboradores: ${err.code} / ${err.message}`, {
          appearance: 'error',
        });
      })
      .finally(() => setLoadingCollaborators(false));

    getOccurrenceMotives(companyId)
      .then(response => {
        const formatedMotives = convertMotiveForAutocomplete(response.data);
        setMotiveList(formatedMotives);
      })
      .catch((err: AxiosError) => {
        addToast(`Erro ao carregar motivos: ${err.code} / ${err.message}`, {
          appearance: 'error',
        });
      })
      .finally(() => {
        setLoadingMotives(false);
      });
  }, [companyId]);

  const handleClose = () => setOpen(false);

  const formateEditedData = (data: OccurrenceShowProps): OccurrenceItemProps => {
    const newObject: OccurrenceItemProps = {
      collaborator: data.collaborator,
      createdBy: data.createdBy.name,
      description: data.description,
      reason: data.reason.name,
      id: data.id,
      occurrenceDate: data.occurrenceDate,
      type: data.type,
    };

    return newObject;
  };

  const onAddSubmit = (data: OccurrenceForm) => {
    setLoading(true);
    createOccurrence(data, companyId)
      .then(response => {
        const formatedData = formateEditedData(response);
        const newItem: OccurrenceItemProps = {
          ...formatedData,
          isNew: true,
        };
        setOccurrenceList(old => [newItem, ...old]);
        handleClose();
      })
      .catch((err: AxiosError) => {
        addToast(`Ocorreu um erro: ${err.code} / ${err.message}`, {
          appearance: 'error',
        });
        setLoading(false);
      });
  };

  const onEditSubmit = (data: OccurrenceForm) => {
    if (!dataEdit) return;

    setLoading(true);
    editOccurrence(dataEdit.id, data, companyId)
      .then(response => {
        const formatedData = formateEditedData(response);
        setOccurrenceList(oldList =>
          oldList.map(occurrence => (occurrence.id === dataEdit.id ? formatedData : occurrence)),
        );

        handleClose();

        addToast(`A ocorrência de ${dataEdit.collaborator.name} foi editada.`, {
          appearance: 'info',
        });
      })
      .catch((err: AxiosError) => {
        addToast(`Ocorreu um erro: ${err.code} / ${err.message}`, {
          appearance: 'error',
        });
        setLoading(false);
      });
  };

  const defaultCollaborator: AutocompleteProps | null =
    (type === 'EDIT' || type === 'VIEW') && dataEdit
      ? {
          label: dataEdit.collaborator.name,
          value: dataEdit.collaborator.id,
        }
      : null;

  const defaultMotive: AutocompleteProps | null =
    (type === 'EDIT' || type === 'VIEW') && dataEdit
      ? {
          label: dataEdit.reason.name,
          value: String(dataEdit.reason.id),
        }
      : null;

  const onSubmit = type === 'EDIT' ? onEditSubmit : onAddSubmit;

  const getModalTitle = () => {
    const defineTitle: { [key in OccurrenceModalProps['type']]: string } = {
      ADD: 'occurrences.new_occurrence',
      EDIT: 'occurrences.edit_occurrence',
      VIEW: 'occurrences.view_occurrence',
    };

    return defineTitle[type];
  };

  const onlyReadMode = Boolean(type === 'VIEW');

  return (
    <>
      <Modal open={open} title={t(getModalTitle())} onClose={handleClose} icon={<OccurrencesIcon />}>
        {loading ? <LoadingComponent /> : null}
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalContent>
              <GridContainer>
                <GridRow>
                  <GridCol xs={12} sm={12} lg={12}>
                    <FieldWrapped>
                      <Label required={true}>{t('occurrences.employee_colon')}</Label>
                      <SelectAutocomplete
                        name="collaboratorId"
                        key={defaultCollaborator?.value}
                        defaultValue={defaultCollaborator}
                        options={collaboratorsList}
                        isLoading={loadingCollaborators}
                        disabled={onlyReadMode || type === 'EDIT'}
                      />
                      {methods.formState.errors.collaboratorId ? (
                        <HelperText color="danger">{methods.formState.errors.collaboratorId.message}</HelperText>
                      ) : null}
                    </FieldWrapped>
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol xs={12} sm={12} lg={12}>
                    <FieldWrapped>
                      <Label required={true}>{t('occurrences.occurrence_type')}</Label>
                      <RadioGroup direction="row" style={{ marginTop: '5px' }}>
                        <RadioContainer>
                          <RadioInput
                            key={dataEdit?.type}
                            type="radio"
                            defaultChecked={dataEdit?.type === 'NEUTRAL'}
                            {...methods.register('type')}
                            value="NEUTRAL"
                            disabled={onlyReadMode}
                          />
                          <RadioStyled />
                          <RadioLabel>{t('occurrences.neutral')}</RadioLabel>
                        </RadioContainer>

                        <RadioContainer>
                          <RadioInput
                            key={dataEdit?.type}
                            type="radio"
                            defaultChecked={dataEdit?.type === 'POSITIVE'}
                            {...methods.register('type')}
                            value="POSITIVE"
                            disabled={onlyReadMode}
                          />
                          <RadioStyled />
                          <RadioLabel>{t('occurrences.positive')}</RadioLabel>
                        </RadioContainer>

                        <RadioContainer>
                          <RadioInput
                            key={dataEdit?.type}
                            type="radio"
                            {...methods.register('type')}
                            defaultChecked={dataEdit?.type === 'NEGATIVE'}
                            value="NEGATIVE"
                            disabled={onlyReadMode}
                          />
                          <RadioStyled />
                          <RadioLabel>{t('occurrences.negative')}</RadioLabel>
                        </RadioContainer>
                      </RadioGroup>
                      {methods.formState.errors.type ? (
                        <HelperText color="danger">{methods.formState.errors.type.message}</HelperText>
                      ) : null}
                    </FieldWrapped>
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol xs={12} sm={6} lg={6}>
                    <FieldWrapped>
                      <Label required={true}>{t('occurrences.reason_colon')}</Label>
                      <SelectAutocomplete
                        key={defaultMotive?.value}
                        defaultValue={defaultMotive}
                        name="reasonId"
                        isLoading={loadingMotives}
                        options={motiveList}
                        disabled={onlyReadMode}
                      />
                      {methods.formState.errors.reasonId ? (
                        <HelperText color="danger">{methods.formState.errors.reasonId.message}</HelperText>
                      ) : null}
                    </FieldWrapped>
                  </GridCol>
                  <GridCol xs={12} sm={6} lg={6}>
                    <FieldWrapped>
                      <Label required={true}>{t('occurrences.occurrence_date')}</Label>
                      <InputBase
                        key={dataEdit?.occurrenceDate}
                        {...methods.register('occurrenceDate')}
                        defaultValue={
                          dataEdit?.occurrenceDate ? convertDateToField(dataEdit.occurrenceDate) : currentDate
                        }
                        type="date"
                        required
                        disabled={onlyReadMode}
                      />
                      {methods.formState.errors.occurrenceDate ? (
                        <HelperText color="danger">{methods.formState.errors.occurrenceDate.message}</HelperText>
                      ) : null}
                    </FieldWrapped>
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol xs={12} sm={6} lg={6}>
                    <FieldWrapped>
                      <Label>{t('occurrences.quantity')}</Label>
                      <InputBase
                        type="number"
                        defaultValue={dataEdit?.quantity ?? undefined}
                        {...methods.register('quantity')}
                        disabled={onlyReadMode}
                      />
                      {methods.formState.errors.quantity ? (
                        <HelperText color="danger">{methods.formState.errors.quantity.message}</HelperText>
                      ) : null}
                    </FieldWrapped>
                  </GridCol>
                  <GridCol xs={12} sm={6} lg={6}>
                    <FieldWrapped>
                      <Label>{t('occurrences.value')}</Label>
                      <InputBase
                        type="number"
                        defaultValue={dataEdit?.value ?? undefined}
                        {...methods.register('value')}
                        disabled={onlyReadMode}
                      />
                      {methods.formState.errors.value ? (
                        <HelperText color="danger">{methods.formState.errors.value.message}</HelperText>
                      ) : null}
                    </FieldWrapped>
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol xs={12} sm={12} lg={12}>
                    <Textarea
                      label={t('occurrences.description')}
                      limit={maxCharacters.description}
                      inputProps={{
                        defaultValue: dataEdit?.description ?? '',
                        disabled: onlyReadMode,
                        ...methods.register('description'),
                      }}
                    ></Textarea>
                    {type === 'EDIT' || type === 'VIEW' ? (
                      <OccurrenceFooterDescription>
                        Registrado por: {dataEdit?.createdBy.name}
                      </OccurrenceFooterDescription>
                    ) : null}
                  </GridCol>
                </GridRow>
              </GridContainer>
            </ModalContent>
            <ModalActions
              style={{
                justifyContent: 'end',
              }}
            >
              <div style={{ display: 'flex', gap: '8px' }}>
                <OutlinedButton size="small" type="button" onClick={handleClose}>
                  {type === 'VIEW' ? t('close') : t('cancel')}
                </OutlinedButton>
                {!onlyReadMode ? (
                  <Button size="small" type="submit">
                    {t('occurrences.save_occurrence')}
                  </Button>
                ) : null}
              </div>
            </ModalActions>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default OccurrenceModal;
