import { WhiteContainer } from 'components/WhiteContainer';
import React from 'react';
import MonitoringTable from '../MonitoringTable';
import { SurveyItemStyle } from './styles';
import { CycleProps } from 'interfaces/Evaluation';

interface SurveyItemProps {
  cycle: CycleProps;
}

const SurveyItem: React.FC<SurveyItemProps> = ({ cycle }) => {
  return (
    <WhiteContainer>
      <SurveyItemStyle>
        <h3>{cycle.belongs_evaluation.title}</h3>
        <MonitoringTable cycle={cycle} />
      </SurveyItemStyle>
    </WhiteContainer>
  );
};

export default SurveyItem;
