import React from 'react';

const AdmissionIcon: React.FC = () => {
  return (
    <svg width="40" height="29" viewBox="0 0 40 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.4761 0H3.52392C1.58693 0 0 1.49529 0 3.3466V25.586C0 27.4373 1.56359 28.9326 3.52392 28.9326H36.4761C38.4131 28.9326 40 27.4373 40 25.586V3.32286C40 1.49529 38.4131 0 36.4761 0Z"
        fill="#5E54AC"
      />
      <path
        d="M40.0001 3.32286V25.5623C40.0001 27.4136 38.4365 28.9089 36.4762 28.9089H11.7154V0H36.4762C38.4131 0 40.0001 1.49529 40.0001 3.32286Z"
        fill="#453D81"
      />
      <path
        d="M34.5158 22.2395H23.5706C22.1237 22.2395 22.1237 20.0084 23.5706 20.0084H34.5158C35.9627 20.0084 35.9627 22.2395 34.5158 22.2395Z"
        fill="#1DE8F1"
      />
      <path
        d="M34.5158 17.801H23.5706C22.1237 17.801 22.1237 15.5699 23.5706 15.5699H34.5158C35.9627 15.5699 35.9627 17.801 34.5158 17.801Z"
        fill="#1DE8F1"
      />
      <path
        d="M34.5158 13.3389H23.5706C22.1237 13.3389 22.1237 11.1078 23.5706 11.1078H34.5158C35.9627 11.1078 35.9627 13.3389 34.5158 13.3389Z"
        fill="#1DE8F1"
      />
      <path
        d="M32.3455 8.90039H25.7877C24.3408 8.90039 24.3408 6.66933 25.7877 6.66933H32.3455C33.7691 6.66933 33.7924 8.90039 32.3455 8.90039Z"
        fill="#B7E0F6"
      />
      <path
        d="M16.4528 9.23279C16.4528 11.9148 14.3291 14.0747 11.692 14.0747C9.05493 14.0747 6.93124 11.9148 6.93124 9.23279C6.93124 7.95111 7.42133 6.71691 8.33148 5.79125C9.21829 4.8656 10.4318 4.34343 11.692 4.36717C14.3291 4.3909 16.4528 6.5745 16.4528 9.23279Z"
        fill="#FFBB85"
      />
      <path
        d="M20.0702 21.0526C20.1168 22.002 19.7668 22.9039 19.09 23.5684C18.4132 24.2093 17.5031 24.5416 16.5696 24.4941H7.07133C6.13784 24.5416 5.25103 24.2093 4.55091 23.5684C3.89747 22.9039 3.52407 21.9782 3.57075 21.0526C3.57075 20.6491 3.59408 20.2456 3.61742 19.8658C3.64076 19.4386 3.71077 19.0114 3.78078 18.5842C3.85079 18.1569 3.96748 17.7297 4.08417 17.3025C4.20085 16.8753 4.38755 16.4955 4.59759 16.1157C4.80762 15.736 5.06433 15.38 5.36771 15.0714C5.69443 14.7629 6.06783 14.5255 6.4879 14.3594C6.95464 14.1695 7.42139 14.0983 7.91147 14.0983C8.1215 14.0983 8.3082 14.1932 8.6816 14.4306C8.91497 14.5967 9.19502 14.7629 9.4984 14.9527C9.82512 15.1426 10.1752 15.3088 10.5486 15.4274C10.922 15.5461 11.3187 15.6173 11.6921 15.6411H11.8321C12.2755 15.6411 12.6956 15.5699 13.1157 15.4274C13.4891 15.3088 13.8391 15.1426 14.1658 14.9527L14.9826 14.4306C15.356 14.1932 15.5427 14.0983 15.7528 14.0983C16.2429 14.0983 16.7329 14.1932 17.1763 14.3594C17.5964 14.5255 17.9698 14.7629 18.2965 15.0714C18.5999 15.38 18.8566 15.7123 19.0667 16.1157C19.2767 16.4955 19.4634 16.899 19.5801 17.3025C19.7201 17.7297 19.8135 18.1569 19.8835 18.5842C19.9535 19.0114 20.0235 19.4386 20.0468 19.8658C20.0702 20.2456 20.0702 20.6491 20.0702 21.0526Z"
        fill="#6AA9FF"
      />
      <path
        d="M11.7154 14.0746V4.39085C14.3525 4.39085 16.4761 6.55072 16.4761 9.23274C16.4761 10.5144 15.9861 11.7486 15.0759 12.6505C14.1891 13.5999 12.9756 14.0984 11.7154 14.0746Z"
        fill="#F5A86C"
      />
      <path
        d="M20.07 21.0526C20.1167 22.002 19.7666 22.9039 19.0898 23.5684C18.413 24.2093 17.5029 24.5416 16.5694 24.4941H11.6919V15.6411H11.8319C12.2754 15.6411 12.6954 15.5699 13.1155 15.4274C13.4889 15.3088 13.8389 15.1426 14.1657 14.9527L14.9825 14.4306C15.3559 14.1932 15.5426 14.0983 15.7526 14.0983C16.2427 14.0983 16.7328 14.1932 17.1762 14.3594C17.5962 14.5255 17.9696 14.7629 18.2964 15.0714C18.5997 15.38 18.8564 15.7123 19.0665 16.1157C19.2765 16.4955 19.4632 16.899 19.5799 17.3025C19.7199 17.7297 19.8133 18.1569 19.8833 18.5842C19.9533 19.0114 20.0233 19.4386 20.0466 19.8658C20.07 20.2456 20.07 20.6491 20.07 21.0526Z"
        fill="#2682FF"
      />
    </svg>
  );
};

export default AdmissionIcon;
