import { links } from "safira-app/config/links"
import { CycleResponseProps, PdiFilters } from "interfaces/Evaluation"
import api from "services/api"

export const getCycles = async (companyId: string, pagination: { page: number, perPage: number }) => {
    const response = await api.get(links.api.evaluation + `/competence/cycles/managers`, {
        headers: { companyId },
        params: {
            page: pagination.page,
            perPage: pagination.perPage,
            filter: 'finished'
        }
    })

    return response.data as CycleResponseProps
}

export const getCyclesPDI = async (companyId: string, cyclesIds: string[], pdiFilters?: PdiFilters) => {
    const response = await api.get(links.api.evaluation + `/competence/pdis/index-by-company`, {
        headers: { companyId },
        params: {
            perPage: 99999,
            cycles_ids: cyclesIds,
            ...pdiFilters
        }
    })

    return response.data
}