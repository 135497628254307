import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
`;

export const TableHead = styled.thead`
  background-color: #ffffff;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`;

export const TableBody = styled.tbody`
  & tr:nth-child(even) {
    background-color: #ffffff;
  }

  & tr:nth-child(odd) {
    background-color: #f2f2f2;
  }

  & tr:first-child {
    background-color: #f2f2f2;
  }
`;

export const TableCell = styled.td`
  padding: 8px 16px;

  div.collaborator-cell {
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const ActionsCell = styled.td`
  text-align: right;
`;

export const TableHeaderCell = styled.th`
  padding: 8px 16px;
  text-align: left;
  font-weight: 600;
`;

export const TableRow = styled.tr`
  &:hover {
    background-color: #ebebeb;
  }
`;

export const DetailRow = styled.tr`
  &:nth-child(even) {
    background-color: #f8f9fa;
  }

  &:nth-child(odd) {
    background-color: #f2f2f2;
  }

  &:first-child {
    background-color: #f2f2f2;
  }

  & > td {
    padding: 4px 16px;
  }
`;

export const DetailCell = styled.td`
  padding: 4px 16px;
`;

interface ExpandButtonProps {
  expanded: boolean;
}

export const ExpandButton = styled.button<ExpandButtonProps>`
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: ${(props) => (props.expanded ? "rotate(90deg)" : "rotate(0deg)")};
`;

interface ExpandedContentProps {
  expanded: boolean;
}

export const ExpandedContent = styled.div<ExpandedContentProps>`
  display: ${(props) => (props.expanded ? "block" : "none")};
`;
