import React from 'react';

const OkrIcon: React.FC = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.326 5.50961C23.2523 5.08715 23.3938 4.65816 23.7038 4.36264L26.0826 2.0928C23.7093 0.890137 21.0251 0.211792 18.1827 0.211792V6.27275C20.1862 6.27275 22.0687 6.78853 23.7063 7.69316L23.326 5.50961Z"
        fill="#F03049"
      />
      <path
        d="M30.2158 12.2442C30.1511 12.2442 30.0864 12.2392 30.0222 12.2297L28.0524 11.9266C29.0493 13.624 29.6218 15.6008 29.6218 17.7121C29.6218 24.03 24.5001 29.1512 18.1827 29.1512V35.2116C27.8477 35.2116 35.6827 27.3766 35.6827 17.7116C35.6827 14.7183 34.9306 11.901 33.6061 9.43753L31.1069 11.881C30.8676 12.1153 30.551 12.2442 30.2158 12.2442Z"
        fill="#F03049"
      />
      <path
        d="M24.0034 9.39959L23.7058 7.69269C22.0682 6.78806 20.1857 6.27228 18.1823 6.27228V12.2193C19.0558 12.2193 19.8816 12.4245 20.6147 12.7873L24.0034 9.39959Z"
        fill="#DCE1EB"
      />
      <path
        d="M29.6218 17.7116C29.6218 15.6008 29.0493 13.6235 28.0523 11.9261L26.1789 11.6376L22.9066 14.9099C23.3942 15.7303 23.6752 16.6881 23.6752 17.7111C23.6752 20.7441 21.2162 23.2031 18.1832 23.2031V29.1507C24.5006 29.1507 29.6218 24.029 29.6218 17.7116Z"
        fill="#DCE1EB"
      />
      <path
        d="M0.682739 17.7117C0.682739 27.3766 8.51783 35.2117 18.1827 35.2117V29.1508C11.8649 29.1508 6.74369 24.0291 6.74369 17.7117C6.74369 11.3939 11.8654 6.27269 18.1827 6.27269V0.211731C8.51783 0.211229 0.682739 8.04632 0.682739 17.7117Z"
        fill="#FF432E"
      />
      <path
        d="M18.1828 12.2198V6.27228C11.8649 6.27228 6.74371 11.394 6.74371 17.7113C6.74371 24.0292 11.8654 29.1504 18.1828 29.1504V23.6398V23.2033C15.1498 23.2033 12.6908 20.7443 12.6908 17.7113C12.6908 14.6783 15.1498 12.2198 18.1828 12.2198Z"
        fill="#F0F0F0"
      />
      <path
        d="M18.1828 15.2202V12.2193C15.1498 12.2193 12.6908 14.6783 12.6908 17.7113C12.6908 20.7443 15.1498 23.2033 18.1828 23.2033V19.1227C18.1356 19.1267 18.0884 19.1297 18.0403 19.1297C17.6238 19.1297 17.232 18.9677 16.9369 18.6726C16.3283 18.064 16.3283 17.0741 16.9369 16.466L18.1828 15.2202Z"
        fill="#FF432E"
      />
      <path
        d="M20.6151 12.7877C19.8816 12.4245 19.0563 12.2198 18.1827 12.2198V15.2206L20.6151 12.7877Z"
        fill="#F03049"
      />
      <path
        d="M23.6747 17.7117C23.6747 16.6882 23.3938 15.7304 22.9061 14.9106L19.1436 18.6731C18.8827 18.934 18.5455 19.0905 18.1827 19.1231V23.2037C21.2157 23.2032 23.6747 20.7447 23.6747 17.7117Z"
        fill="#F03049"
      />
      <g opacity="0.2">
        <path
          d="M29.0794 31.4061L17.1607 18.3098C16.7468 17.8542 16.7799 17.1498 17.235 16.7354C17.69 16.3214 18.395 16.3545 18.8094 16.8096L30.7266 29.9139C30.2369 30.4267 29.6052 30.9886 29.0794 31.4061Z"
          fill="black"
        />
      </g>
      <path
        d="M17.9849 18.6836C17.6994 18.6836 17.4145 18.5747 17.1967 18.357C16.7617 17.922 16.7617 17.216 17.1967 16.7805L32.2211 1.75606C32.6567 1.32106 33.3621 1.32106 33.7976 1.75606C34.2326 2.19107 34.2326 2.89701 33.7976 3.33251L18.7732 18.3575C18.5554 18.5747 18.2704 18.6836 17.9849 18.6836Z"
        fill="#23272B"
      />
      <path
        d="M35.3931 5.99166C35.3088 5.7147 35.0775 5.50799 34.7931 5.45531L30.869 4.72629L30.3045 0.762584C30.2634 0.472582 30.0632 0.229742 29.7863 0.133911C29.5093 0.0380794 29.2022 0.104811 28.9905 0.30701L24.3304 4.75388C24.1417 4.93401 24.0549 5.19641 24.1001 5.4538L24.8953 10.0201C24.9024 10.0602 24.9129 10.0989 24.9259 10.1365C24.9305 10.1551 24.9345 10.1741 24.9405 10.1927C25.0323 10.4681 25.2706 10.6688 25.5576 10.713L30.017 11.3994C30.0562 11.4054 30.0958 11.4084 30.135 11.4084C30.3362 11.4084 30.5308 11.3301 30.6768 11.1876L35.1939 6.77136C35.4002 6.56916 35.4774 6.26862 35.3931 5.99166Z"
        fill="#FFBC36"
      />
    </svg>
  );
};

export default OkrIcon;
