import styled from "styled-components";

export const ExpansiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
`;

export const ExpansiveTableHead = styled.thead`
  background-color: #ffffff;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`;

export const ExpansiveTableBody = styled.tbody`
  & tr.father-row {
    box-shadow: 0px 2px 5px -3px rgba(0, 0, 0, 0.5);
    /* border-bottom: 1px solid #cdcdcd; */
  }

  & tr.father-row:nth-child(even) {
    background-color: #ffffff;
  }

  & tr.father-row:nth-child(odd) {
    background-color: #f2f2f2;
  }

  & tr.father-row:first-child {
    background-color: #f2f2f2;
  }

  & tr.father-row > td {
    padding: 4px 16px;
  }
`;

export const ExpansiveTableCell = styled.td`
  padding: 0 16px;

  div.collaborator-cell {
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const ExpansiveActionsCell = styled.td`
  text-align: right;
`;

export const ExpansiveTableHeaderCell = styled.th`
  padding: 1px 16px;
  text-align: left;
  font-weight: 600;
`;

export const ExpansiveTableRow = styled.tr`
  &:hover {
    background-color: inherit;
  }
`;

export const ExpansiveDetailRow = styled.tr`
  background-color: #cdcdcd;
`;

export const ExpansiveDetailCell = styled.td``;

interface ExpandButtonProps {
  expanded: boolean;
}

export const ExpansiveExpandButton = styled.button<ExpandButtonProps>`
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: ${(props) => (props.expanded ? "rotate(90deg)" : "rotate(0deg)")};
`;

interface ExpandedContentProps {
  expanded: boolean;
}

export const ExpansiveExpandedContent = styled.div<ExpandedContentProps>`
  display: ${(props) => (props.expanded ? "block" : "none")};
`;
