export default () => {
  return (
    <svg
      width="8"
      height="15"
      viewBox="0 0 8 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.2973 7.5L0 15L0 0L7.2973 7.5Z" fill="#444444" />
    </svg>
  );
};
