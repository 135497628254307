import { Button } from "components/Buttons/styles";
import { FieldWrapped } from "components/Forms/FieldWrapped";
import { HelperText } from "components/Forms/Helpers/HelperText";
import { InputBase } from "components/Forms/Input/Input";
import { Label } from "components/Forms/Label";
import { TextareaBase } from "components/Forms/Textarea/Textarea";
import VacationIcon from "components/Icons/VacationIcon";
import LoadingComponent from "components/LoadingComponent";
import Modal, { ModalActions, ModalContent } from "components/Modal";
import { usePermissions } from "safira-app/contexts/Permissions";
import {
  RequestVacationByCompanyItem,
  ResquestAnswerByManager,
} from "interfaces/Vacations";
import { GridCol, GridContainer, GridRow } from "layout/components/page-styles";
import React, { useState } from "react";
import {
  approveRequestByCompany,
  approveRequestByManager,
} from "requests/vacations";
import Checkbox from "components/Forms/Checkbox";

interface ApproveModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  item: RequestVacationByCompanyItem;
  collaboratorId: string;
  acquisitiveId: string;
  actualbalance: number;
  approveBy: "MANAGER" | "COMPANY";
  setItem: React.Dispatch<React.SetStateAction<RequestVacationByCompanyItem>>;
}

const ApproveModal: React.FC<ApproveModalProps> = ({
  open,
  setOpen,
  item,
  collaboratorId,
  acquisitiveId,
  actualbalance,
  approveBy,
  setItem,
}) => {
  const [answerNote, setAnswerNote] = useState<string>("");
  const [noteError, setNoteError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { companyId } = usePermissions();

  let refuseEnabled;
  let approveEnabled;

  if (approveBy === "MANAGER") {
    refuseEnabled =
      item.status === "PENDING" || item.status === "APPROVED_BY_MANAGER";
    approveEnabled =
      item.status === "PENDING" || item.status === "REFUSED_BY_MANAGER";
  } else {
    refuseEnabled =
      item.status === "PENDING" ||
      item.status === "APPROVED_BY_MANAGER" ||
      item.status === "REFUSED_BY_MANAGER" ||
      item.status === "APPROVED";
    approveEnabled =
      item.status === "PENDING" ||
      item.status === "APPROVED_BY_MANAGER" ||
      item.status === "REFUSED_BY_MANAGER";
  }

  const handleClose = () => setOpen(false);

  const handleAnswer = (answer: boolean) => {
    if (answerNote.length === 0) {
      setNoteError(true);
      return;
    }

    const data: ResquestAnswerByManager = {
      answer: answer,
      note: answerNote,
    };

    setLoading(true);

    if (approveBy === "COMPANY") {
      approveRequestByCompany(
        collaboratorId,
        acquisitiveId,
        item.id,
        data,
        companyId
      ).then((response) => {
        if (data.answer === false) {
          setItem((old) => ({
            ...old,
            status: "REFUSED",
          }));
        }

        if (data.answer === true) {
          setItem((old) => ({
            ...old,
            status: "APPROVED",
          }));
        }
        handleClose();
      });
    } else {
      approveRequestByManager(
        collaboratorId,
        acquisitiveId,
        item.id,
        data,
        companyId
      )
        .then((response) => {
          if (data.answer === false) {
            setItem((old) => ({
              ...old,
              status: "REFUSED_BY_MANAGER",
            }));
          }

          if (data.answer === true) {
            setItem((old) => ({
              ...old,
              status: "APPROVED_BY_MANAGER",
            }));
          }

          handleClose();
        })
        .catch((err) => {
          handleClose();
          console.error(err);
        });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      icon={<VacationIcon />}
      title="Responder solicitação de férias"
    >
      {loading ? <LoadingComponent /> : null}
      <ModalContent>
        <GridContainer>
          <GridRow>
            <GridCol xs={12} sm={6} lg={6}>
              <FieldWrapped>
                <Label required={true}>Início</Label>
                <InputBase
                  value={item.start_date}
                  disabled={true}
                  type="date"
                />
              </FieldWrapped>
            </GridCol>
            <GridCol xs={12} sm={6} lg={6}>
              <FieldWrapped>
                <Label required={true}>Término</Label>
                <InputBase disabled={true} value={item.end_date} type="date" />
              </FieldWrapped>
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol xs={12} sm={6} lg={6}>
              <FieldWrapped>
                <Label>Dias solicitados</Label>
                <InputBase value={item.spent_balance} disabled={true} />
              </FieldWrapped>
            </GridCol>
            <GridCol xs={12} sm={6} lg={6}>
              <FieldWrapped>
                <Label>Saldo restante de dias</Label>
                <InputBase disabled={true} value={actualbalance} />
              </FieldWrapped>
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol xs={12} sm={12} lg={12}>
              <Checkbox
                checked={item.isWageNegotiation}
                disabled={true}
                label="Abono pecuniário de 10 dias"
              />
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol xs={12} sm={12} lg={12}>
              <FieldWrapped>
                <Label required={true}>Motivo</Label>
                <TextareaBase
                  value={item.note}
                  disabled={true}
                  hasError={false}
                />
              </FieldWrapped>
            </GridCol>
          </GridRow>

          <GridRow>
            <GridCol xs={12} sm={12} lg={12}>
              <FieldWrapped>
                <Label required={true}>Observação</Label>
                <TextareaBase
                  hasError={false}
                  value={answerNote}
                  onChange={(e) => {
                    if (noteError) setNoteError(false);
                    setAnswerNote(e.target.value);
                  }}
                />
                {noteError && (
                  <HelperText color="danger">Campo obrigatório</HelperText>
                )}
              </FieldWrapped>
            </GridCol>
          </GridRow>
        </GridContainer>
      </ModalContent>
      <ModalActions>
        {refuseEnabled ? (
          <Button
            size="small"
            type="button"
            color="danger"
            onClick={() => handleAnswer(false)}
          >
            Recusar
          </Button>
        ) : null}
        {approveEnabled ? (
          <Button size="small" type="button" onClick={() => handleAnswer(true)}>
            {approveBy === "MANAGER" ? "Aprovar" : "Programar"}
          </Button>
        ) : null}
      </ModalActions>
    </Modal>
  );
};

export default ApproveModal;
