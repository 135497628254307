import React from "react";

const HRConnectIcon: React.FC = () => {
  return (
    <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.2467 34.4764C13.1838 34.4761 13.1223 34.4573 13.07 34.4222C13.0177 34.3871 12.977 34.3374 12.9529 34.2792C12.9288 34.221 12.9224 34.1571 12.9346 34.0953C12.9467 34.0335 12.9769 33.9767 13.0212 33.932L15.2821 31.6712C15.3424 31.6141 15.4225 31.5829 15.5055 31.584C15.5885 31.5851 15.6677 31.6186 15.7265 31.6772C15.7852 31.7359 15.8187 31.8151 15.8199 31.8981C15.8212 31.981 15.79 32.0612 15.7331 32.1216L13.4722 34.3825C13.4427 34.4122 13.4076 34.4358 13.3689 34.452C13.3302 34.4681 13.2886 34.4764 13.2467 34.4764Z" fill="#FB8500"/>
      <path d="M16.4659 36.116C16.449 36.1158 16.4321 36.1145 16.4154 36.112C16.332 36.0987 16.2572 36.0529 16.2075 35.9845C16.1578 35.9161 16.1373 35.8308 16.1505 35.7474L16.6502 32.5926C16.6639 32.5091 16.7102 32.4345 16.7789 32.3851C16.8476 32.3358 16.9331 32.3157 17.0166 32.3294C17.1001 32.3431 17.1747 32.3894 17.2241 32.4581C17.2734 32.5268 17.2935 32.6123 17.2798 32.6958L16.7801 35.8505C16.7675 35.9248 16.729 35.9921 16.6715 36.0407C16.614 36.0893 16.5412 36.116 16.4659 36.116Z" fill="#FB8500"/>
      <path d="M11.6064 31.2572C11.5263 31.2568 11.4494 31.2264 11.3907 31.1719C11.3321 31.1174 11.2961 31.0429 11.2899 30.9631C11.2837 30.8833 11.3077 30.804 11.3572 30.7411C11.4067 30.6782 11.4781 30.6363 11.5571 30.6235L14.7136 30.1238C14.7972 30.1105 14.8826 30.131 14.9511 30.1807C15.0196 30.2303 15.0655 30.3052 15.0788 30.3888C15.0921 30.4723 15.0717 30.5577 15.022 30.6262C14.9723 30.6947 14.8975 30.7407 14.8139 30.754L11.6591 31.2531C11.6417 31.256 11.624 31.2574 11.6064 31.2572Z" fill="#FB8500"/>
      <path d="M32.5191 14.0001C32.4561 14.0001 32.3944 13.9814 32.3419 13.9464C32.2895 13.9114 32.2486 13.8615 32.2245 13.8033C32.2003 13.745 32.194 13.6809 32.2063 13.619C32.2186 13.5572 32.249 13.5003 32.2936 13.4557L34.5545 11.1978C34.6148 11.1408 34.6949 11.1095 34.7779 11.1106C34.8609 11.1118 34.9402 11.1452 34.9989 11.2038C35.0576 11.2625 35.0911 11.3417 35.0924 11.4247C35.0936 11.5077 35.0624 11.5878 35.0055 11.6482L32.7446 13.9091C32.7149 13.9384 32.6797 13.9615 32.641 13.9771C32.6023 13.9928 32.5609 14.0006 32.5191 14.0001Z" fill="#FB8500"/>
      <path d="M31.0613 13.2562C31.0446 13.2563 31.0279 13.2549 31.0115 13.2522C30.9701 13.2457 30.9304 13.2311 30.8946 13.2092C30.8589 13.1874 30.8278 13.1587 30.8032 13.1248C30.7785 13.091 30.7608 13.0526 30.7509 13.0119C30.7411 12.9712 30.7394 12.9289 30.746 12.8875L31.2457 9.73275C31.2652 9.65555 31.3128 9.58843 31.3793 9.5446C31.4458 9.50077 31.5262 9.4834 31.6049 9.4959C31.6835 9.5084 31.7546 9.54987 31.8042 9.61215C31.8538 9.67443 31.8783 9.75302 31.8729 9.83246L31.3744 12.9855C31.3629 13.0605 31.3251 13.1289 31.2677 13.1785C31.2104 13.2282 31.1372 13.2557 31.0613 13.2562Z" fill="#FB8500"/>
      <path d="M33.2642 15.4603C33.1835 15.461 33.1054 15.4311 33.0459 15.3766C32.9863 15.322 32.9497 15.2469 32.9434 15.1664C32.937 15.0859 32.9615 15.006 33.0118 14.9429C33.0621 14.8797 33.1345 14.838 33.2144 14.8261L36.3691 14.327C36.4112 14.3187 36.4545 14.3191 36.4965 14.328C36.5384 14.3369 36.5781 14.3542 36.6132 14.3788C36.6483 14.4034 36.6781 14.4348 36.7008 14.4712C36.7235 14.5076 36.7386 14.5482 36.7452 14.5906C36.7519 14.6329 36.7499 14.6762 36.7394 14.7178C36.7289 14.7594 36.7102 14.7984 36.6843 14.8326C36.6583 14.8667 36.6258 14.8953 36.5886 14.9166C36.5514 14.9379 36.5103 14.9515 36.4677 14.9565L33.3129 15.4562C33.2968 15.4587 33.2805 15.46 33.2642 15.4603Z" fill="#FB8500"/>
      <path d="M26.2497 17.7698H27.8387V6.2707C27.839 5.44743 27.6772 4.63216 27.3624 3.87145C27.0475 3.11074 26.586 2.4195 26.004 1.8372C25.422 1.25489 24.731 0.792936 23.9705 0.477707C23.21 0.162478 22.3948 0.000152218 21.5715 0H6.26722C5.44397 0.000228353 4.62882 0.162606 3.86833 0.477862C3.10783 0.793118 2.41687 1.25508 1.83491 1.83737C1.25294 2.41965 0.791364 3.11087 0.476531 3.87154C0.161697 4.63221 -0.000228175 5.44745 2.41321e-07 6.2707V21.575C0.000615041 23.2373 0.661345 24.8313 1.83692 26.0065C3.0125 27.1818 4.60668 27.8421 6.26896 27.8422H21.5732C21.6764 27.8422 21.779 27.8422 21.8811 27.8347C21.9831 27.8271 22.0845 27.8219 22.1848 27.8121C22.3781 27.7935 22.57 27.7659 22.7605 27.7292C22.956 27.6905 23.1479 27.6443 23.3361 27.5906C24.5343 27.2387 25.6002 26.5373 26.3975 25.5762C27.1947 24.615 27.6871 23.4378 27.8115 22.1953H26.2497C26.1214 22.1958 25.9932 22.2047 25.866 22.2219C25.7953 22.2312 25.7263 22.2451 25.6579 22.259C25.5287 23.0223 25.1889 23.7344 24.6768 24.3149C24.1647 24.8955 23.5006 25.3215 22.7593 25.5448C22.5708 25.6015 22.3784 25.6445 22.1837 25.6735C21.9812 25.7036 21.7768 25.7187 21.5721 25.7187H6.2678C5.16873 25.7175 4.11502 25.2804 3.33785 24.5032C2.56069 23.726 2.12354 22.6723 2.12231 21.5732V6.26896C2.12354 5.16989 2.56069 4.11618 3.33785 3.33901C4.11502 2.56185 5.16873 2.1247 6.2678 2.12347H21.5721C22.6712 2.1247 23.7249 2.56185 24.502 3.33901C25.2792 4.11618 25.7164 5.16989 25.7176 6.26896V17.7907C25.8967 17.7779 26.0758 17.7698 26.2497 17.7698Z" fill="#FFB703"/>
      <path d="M19.8074 17.7258C19.4788 16.6356 18.8166 15.6758 17.9141 14.9815C17.0116 14.2871 15.9141 13.8932 14.7761 13.8551C14.6601 13.836 12.099 13.8168 11.9662 13.8551C9.80219 14.007 8.22597 15.051 7.27699 17.0064C6.84047 17.88 6.76453 18.8104 6.76453 19.7786C6.78308 20.9177 7.54249 21.6962 8.68162 21.6962H13.4659C15.0613 21.6962 16.6543 21.6771 18.2509 21.6771C18.6773 21.6829 19.0913 21.5337 19.416 21.2572C19.7406 20.9808 19.9539 20.5958 20.0161 20.1739C20.1177 19.3522 20.0466 18.5184 19.8074 17.7258Z" fill="#FFB703"/>
      <path d="M13.4659 12.9031C14.2058 12.9075 14.9309 12.6964 15.5529 12.2956C16.2607 11.8436 16.803 11.1743 17.0984 10.3881C17.3938 9.60188 17.4264 8.74109 17.1914 7.93478C16.9563 7.12848 16.4661 6.4201 15.7945 5.91585C15.1228 5.4116 14.3058 5.13866 13.4659 5.13794C12.4963 5.14626 11.5638 5.51132 10.8462 6.16344L10.7714 6.23938L10.6955 6.31533C10.284 6.70808 9.97731 7.19752 9.80331 7.73909C9.68633 8.08253 9.61595 8.44012 9.59403 8.80227C9.53439 9.62115 9.74806 10.4367 10.2016 11.1211C10.5522 11.6684 11.035 12.1186 11.6056 12.4301C12.1761 12.7415 12.8159 12.9042 13.4659 12.9031Z" fill="#FFB703"/>
      <path d="M34.9454 18.9214H26.2498C26.0695 18.9214 25.8916 18.9295 25.7153 18.9446C25.5205 18.9608 25.3287 18.9869 25.1397 19.0211C24.9507 19.0553 24.7524 19.1005 24.564 19.1539C23.3566 19.4961 22.2815 20.197 21.4813 21.1638C20.6811 22.1305 20.1934 23.3176 20.0829 24.5677H21.5716C21.7196 24.5677 21.8674 24.5568 22.0139 24.5353C22.0875 24.5243 22.1594 24.5086 22.2313 24.4924C22.3513 23.723 22.6892 23.0041 23.2049 22.4207C23.7206 21.8373 24.3926 21.4138 25.1414 21.2002C25.3302 21.1468 25.5226 21.1068 25.7171 21.0808C25.8936 21.0568 26.0716 21.0444 26.2498 21.0437H34.9454C36.0241 21.0449 37.0582 21.4739 37.8209 22.2367C38.5836 22.9994 39.0126 24.0335 39.0138 25.1121V33.8077C39.0124 34.8862 38.5833 35.9201 37.8206 36.6826C37.0579 37.445 36.0239 37.8739 34.9454 37.8749H26.2498C25.1712 37.8739 24.1371 37.445 23.3744 36.6823C22.6117 35.9197 22.1827 34.8857 22.1814 33.8071V28.9666C21.9774 28.9834 21.7722 28.9938 21.5698 28.9938H20.0597V33.8054C20.0593 34.6186 20.2191 35.4239 20.53 36.1754C20.8409 36.9268 21.2968 37.6097 21.8716 38.1849C22.4464 38.7602 23.1289 39.2166 23.8802 39.528C24.6314 39.8395 25.4366 39.9999 26.2498 40.0001H34.9454C36.5875 40.0001 38.1623 39.3479 39.3235 38.1868C40.4847 37.0257 41.1371 35.4509 41.1373 33.8089V25.1132C41.1374 24.3001 40.9773 23.4949 40.6661 22.7436C40.355 21.9924 39.8989 21.3098 39.3239 20.7348C38.7489 20.1598 38.0663 19.7037 37.315 19.3926C36.5638 19.0814 35.7586 18.9213 34.9454 18.9214Z" fill="#FB8500"/>
      <path d="M29.518 29.5217C29.5021 29.5218 29.4863 29.524 29.471 29.5281C27.8131 29.644 26.6061 30.4446 25.8768 31.9426C25.5423 32.6115 25.4844 33.3246 25.4844 34.066C25.4989 34.9391 26.0803 35.535 26.9534 35.535H30.6183C31.8403 35.535 33.0617 35.5205 34.2838 35.5205H34.3127C34.6348 35.5191 34.9458 35.4025 35.1894 35.1917C35.433 34.981 35.5931 34.6901 35.6409 34.3715C35.7188 33.7419 35.6643 33.1029 35.4809 32.4956C35.2292 31.6602 34.7221 30.9247 34.0308 30.3924C33.3396 29.8602 32.4989 29.5579 31.627 29.5281C31.6179 29.5275 31.6088 29.5275 31.5997 29.5281C31.5637 29.5272 31.5285 29.538 31.4992 29.5589C31.4699 29.5798 31.4482 29.6097 31.4374 29.644C31.3332 29.8777 31.2172 30.1058 31.0896 30.3275C31.0658 30.3666 31.052 30.411 31.0495 30.4567C31.047 30.5024 31.0558 30.548 31.0751 30.5895C31.3226 31.433 31.5551 32.2765 31.8026 33.1199C31.8586 33.2868 31.865 33.4662 31.821 33.6366C31.7771 33.807 31.6847 33.961 31.5551 34.0799C31.3487 34.3014 31.1273 34.5072 30.9215 34.7286L30.8733 34.7733C30.803 34.8333 30.7161 34.8705 30.6241 34.8799C30.6094 34.8808 30.5947 34.8808 30.58 34.8799C30.5138 34.8793 30.4485 34.8644 30.3885 34.8365C30.3285 34.8085 30.2752 34.7679 30.2322 34.7176C30.0432 34.5286 29.8687 34.3396 29.6646 34.1652C29.5026 34.0309 29.3873 33.8489 29.3353 33.645C29.2832 33.4412 29.297 33.2261 29.3748 33.0307C29.6217 32.2162 29.8548 31.4017 30.0704 30.5727C30.0897 30.4893 30.0794 30.4019 30.0415 30.3252C29.9394 30.1072 29.8229 29.8892 29.7215 29.6707C29.7079 29.6277 29.6813 29.59 29.6454 29.5628C29.6094 29.5356 29.5659 29.5202 29.5209 29.5188L29.518 29.5217Z" fill="#FB8500"/>
      <path d="M33.1365 24.2318C32.9036 23.8644 32.593 23.5526 32.2264 23.3183C31.8599 23.0841 31.4464 22.9332 31.0151 22.8764C30.5838 22.8195 30.1453 22.8579 29.7306 22.9891C29.3158 23.1202 28.9349 23.3408 28.6148 23.6353L28.5568 23.6933L28.4988 23.7512C28.1831 24.0531 27.948 24.4292 27.8148 24.8452C27.7256 25.1074 27.6718 25.3803 27.6548 25.6567C27.609 26.2831 27.7721 26.9069 28.1185 27.4306C28.3868 27.8506 28.7567 28.196 29.194 28.435C29.6312 28.6741 30.1217 28.799 30.62 28.7982C31.187 28.8022 31.743 28.6411 32.22 28.3344C32.5497 28.1254 32.8351 27.8534 33.0596 27.534C33.2842 27.2147 33.4436 26.8542 33.5287 26.4731C33.6139 26.0921 33.623 25.698 33.5557 25.3134C33.4884 24.9288 33.346 24.5613 33.1365 24.2318Z" fill="#FB8500"/>
    </svg>
  )
}

export default HRConnectIcon;