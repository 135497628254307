import React, { useState } from 'react';
import Avatar from 'components/Avatar';
import { ActionsCell, TableCell, TableRow } from 'components/Tables/styles';
import { OccurrenceType } from 'pages/Occurrences/styles';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { MoreButton } from 'components/Buttons/styles';
import { ReactComponent as MoreIcon } from '../../../../layout/icons/more-icon.svg';
import { OccurrenceItemProps, OccurrencesTypes, OccurrenceShowProps } from 'interfaces/Occurrences';
import { Chip } from 'components/Chip/Chip';
import LoadingComponent from 'components/LoadingComponent';
import { showOccurrence } from 'requests/occurrences';
import { usePermissions } from 'safira-app/contexts/Permissions';
import { addToast } from 'safira-app/components/Toast';
import { AxiosError } from 'axios';
import OccurrenceModal from 'pages/Occurrences/components/OccurrenceModal';
import { useTranslation } from 'react-i18next';

interface OccurrenceItemComponentProps {
  occurrence: OccurrenceItemProps;
  setOccurrenceList: React.Dispatch<React.SetStateAction<OccurrenceItemProps[]>>;
}

const getTypeLabel = (type: OccurrencesTypes) => {
  const Label: { [key in OccurrencesTypes]: string } = {
    NEGATIVE: 'occurrences.negative',
    NEUTRAL: 'occurrences.neutral',
    POSITIVE: 'occurrences.positive',
  };

  return Label[type];
};

function formateDate(date: string) {
  const newDate = new Date(date);

  const day = String(newDate.getDate() + 1).padStart(2, '0');
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const year = newDate.getFullYear();

  return `${day}/${month}/${year}`;
}

const OccurrenceItem: React.FC<OccurrenceItemComponentProps> = ({ occurrence, setOccurrenceList }) => {
  const { t } = useTranslation();
  const [occurrenceModal, setOccurrenceModal] = useState(false);
  const [dataEdit, setDataEdit] = useState<OccurrenceShowProps | null>(null);
  const [modalType, setModalType] = useState<'EDIT' | 'VIEW'>('VIEW');
  const [menuLoading, setMenuLoading] = useState(false);

  const { companyId, checkPermission } = usePermissions();

  const handleOpenModal = (type: 'VIEW' | 'EDIT') => {
    setMenuLoading(true);

    showOccurrence(occurrence.id, companyId)
      .then(response => {
        setModalType(type);
        setDataEdit(response);
        setOccurrenceModal(true);
      })
      .catch((err: AxiosError) => {
        addToast(`Ocorreu um erro: ${err.code} / ${err.message}`, {
          appearance: 'error',
        });
      })
      .finally(() => {
        setMenuLoading(false);
      });
  };

  return (
    <>
      <TableRow>
        <TableCell
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <div>
            <Avatar name={occurrence.collaborator.name} src={occurrence.collaborator.avatar ?? ''} />{' '}
          </div>
          {occurrence.collaborator.name}
          {occurrence.isNew ? <Chip>NOVO</Chip> : null}
        </TableCell>
        <TableCell>{formateDate(occurrence.occurrenceDate)}</TableCell>
        <TableCell>{occurrence.collaborator?.unit}</TableCell>
        <TableCell>{occurrence.collaborator?.sector}</TableCell>
        <TableCell>{occurrence.reason}</TableCell>
        <TableCell>
          <OccurrenceType type={occurrence.type}>{t(getTypeLabel(occurrence.type))}</OccurrenceType>
        </TableCell>
        <ActionsCell>
          {menuLoading ? <LoadingComponent size={60} thickness={6} /> : null}
          <Menu
            direction="left"
            menuButton={
              <MoreButton>
                <MoreIcon />
              </MoreButton>
            }
            transition
          >
            <MenuItem
              onClick={() => handleOpenModal('VIEW')}
              disabled={!checkPermission(['department_view_occurrence'])}
              title={
                !checkPermission(['department_view_occurrence'])
                  ? 'Você não tem permissão para visualizar uma ocorrência.'
                  : ''
              }
            >
              {t('view')}
            </MenuItem>
            <MenuItem
              onClick={() => handleOpenModal('EDIT')}
              disabled={!checkPermission(['managers_edit_occurrence'])}
              title={
                !checkPermission(['managers_edit_occurrence'])
                  ? 'Você não tem permissão para editar uma ocorrência.'
                  : ''
              }
            >
              {t('edit')}
            </MenuItem>
          </Menu>
        </ActionsCell>
      </TableRow>

      {occurrenceModal && dataEdit ? (
        <OccurrenceModal
          open={occurrenceModal}
          setOpen={setOccurrenceModal}
          type={modalType}
          setOccurrenceList={setOccurrenceList}
          dataEdit={dataEdit}
        />
      ) : null}
    </>
  );
};

export default OccurrenceItem;
