import React from 'react';
import styled from 'styled-components';

// Estilos para a barra de progresso
const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #ccc;
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
`;

interface ProgressFillerProps {
  progress: number;
  color?: string;
}

const ProgressFiller = styled.div<ProgressFillerProps>`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: ${props => (props.color ? props.color : '#fd3120')};
  transition: width 0.3s ease-in-out;
`;

const LinearProgress: React.FC<ProgressFillerProps> = ({ color, progress }) => {
  return (
    <ProgressBarContainer>
      <ProgressFiller color={color} progress={progress} />
    </ProgressBarContainer>
  );
};

export default LinearProgress;
