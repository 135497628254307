import styled from 'styled-components';

export const CONTAINER_MAX_WIDTH = 1280;

interface SidebarProps {
  isCollapsed: boolean;
}

interface CollapsedButtonProps {
  isCollapsed: boolean;
}

interface MainProps {
  isCollapsed: boolean;
}

export const LayoutContainer = styled.div<{ container?: boolean }>`
  ${props =>
    props?.container
      ? `
    max-width: ${CONTAINER_MAX_WIDTH}px;
    margin: 0 auto;
  `
      : ''}
`;

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 55px;
  background-color: #fff;
  border-bottom: 1px solid #e4e4e4;
  z-index: 99;
`;

export const Sidebar = styled.div<SidebarProps>`
  position: fixed;
  top: 55px;
  left: 0;
  bottom: 0;
  width: ${props => (props.isCollapsed ? '55px' : '250px')};
  background-color: #fff;
  overflow-y: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  overflow-x: hidden;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li > a {
    display: flex;
    align-items: center;
    height: 55px;
    padding: 0 10px;
    color: #333;
    text-decoration: none;
    gap: 8px;
    border-left: 6px solid transparent;
    font-size: 16px;
    color: #212529;
    font-family: 'Myriad Pro', sans-serif;
  }

  li > a > img {
    width: 20px;
    height: 20px;
  }

  li > a > span {
    margin-left: auto;
  }

  li > a > span > img {
    width: 16px;
    height: 16px;
  }

  li.active > a {
    border-left: 6px solid var(--primary-color);
    background: #ececec;
  }

  li ul {
    list-style: none;
    padding: 0 0 0 30px;
    margin: 0;
  }

  li ul li > a {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 10px;
    color: #333;
    text-decoration: none;
    gap: 8px;
  }

  li ul li > a.active {
    color: var(--primary-color);
  }

  li ul li > a > img {
    width: 18px;
    height: 18px;
  }
`;

export const Main = styled.main<MainProps>`
  margin-left: ${props => (props.isCollapsed ? '55px' : '250px')};
  padding: 16px;
  margin-top: 55px;
  transition: all 0.3s ease-in-out;
`;

export const FooterSidebar = styled.div`
  display: flex;
  border-top: 1px solid #ececec;
  padding: 12px;
  justify-content: flex-end;
`;

export const CollapseButton = styled.button<CollapsedButtonProps>`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  font-size: 18px;
  border: none;
  border-radius: 2px;
  transition: background-color 0.5s ease;
  transform: ${props => (props.isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)')};

  &:hover {
    cursor: pointer;
    background: #ececec;
  }
`;
