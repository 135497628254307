import React from 'react';
import { ApexOptions } from 'apexcharts';
import { useMonitoringPDI } from 'context/MonitoringPDIContext';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

const Graph: React.FC = () => {
  const { t } = useTranslation();
  const { dataForChart } = useMonitoringPDI();

  const chartConfig: ApexOptions = {
    series: [
      dataForChart.concluded.value,
      dataForChart.not_able.value,
      dataForChart.not_registered.value,
      dataForChart.progress.value,
    ],
    chart: {
      height: 150,
      width: 200,
      type: 'donut',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      dataForChart.concluded.color,
      dataForChart.not_able.color,
      dataForChart.not_registered.color,
      dataForChart.progress.color,
    ],
    stroke: {
      width: 1,
    },
    labels: [
      t(dataForChart.concluded.name),
      t(dataForChart.not_able.name),
      t(dataForChart.not_registered.name),
      t(dataForChart.progress.name),
    ],
    plotOptions: {
      pie: {
        donut: {
          size: '75%', // Reduza o tamanho do donut
        },
      },
    },
  };

  return <ReactApexChart options={chartConfig} series={chartConfig.series} type="donut" />;
};

export default Graph;
