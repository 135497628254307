export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 4px 15px rgba(44, 63, 88, 0.35)",
    borderRadius: "6px",
    maxWidth: "600px",
    width: "100%",
    border: "none",
    backgroundColor: "#fff",
    padding: 0,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 9998,
  },
};
