import Avatar from 'components/Avatar';
import { IconButton, MoreButton } from 'components/Buttons/styles';
import MotiveIcon from 'components/Icons/MotiveIcon';
import { StatusItem } from 'components/StatusItem';
import { DetailCell, DetailRow, ExpandedContent } from 'components/Tables/styles';
import { RequestVacationByCompanyItem, VacationRequestStatusEnum } from 'interfaces/Vacations';
import React, { useState } from 'react';
import { VacationRequestStatusColor, VacationRequestStatusLabel } from 'utils/lists';
import MotiveModal from './MotiveModal';
import { ReactComponent as MoreIcon } from '../../../../layout/icons/more-icon.svg';
import ApproveModal from 'pages/Vacations/ApproveModal';
import { Menu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import MoneyIcon from 'pages/Vacations/RequestVacationModal/icons/MoneyIcon';
import { useTranslation } from 'react-i18next';

interface VacationRequestItemProps {
  expanded: boolean;
  item: RequestVacationByCompanyItem;
  acquisitiveId: string;
  collaboratorId: string;
  setRequests: React.Dispatch<React.SetStateAction<RequestVacationByCompanyItem[]>>;
  setExpandRow: React.Dispatch<React.SetStateAction<boolean>>;
  actualBalance: number;
}

const formateDate = (date: string) => {
  if (!date) return date;
  const dateParts = date.split('-');
  const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0].slice(2)}`;
  return formattedDate;
};

const VacationRequestItem: React.FC<VacationRequestItemProps> = ({
  expanded,
  item,
  acquisitiveId,
  collaboratorId,
  actualBalance,
}) => {
  const { t } = useTranslation();
  const [motiveModal, setMotiveModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [itemCopy, setItemCopy] = useState<RequestVacationByCompanyItem>(item);

  const handleMotiveModalOpen = () => setMotiveModal(true);

  const handleAnswer = () => setApproveModal(true);

  const ACTIONS = {
    ANSWER: { label: 'Aprovar/Recusar', action: handleAnswer },
    REPROVE: { label: 'Reprovar', action: handleAnswer },
    APPROVE: { label: 'Aprovar', action: handleAnswer },
  };

  const menuOptions = [
    {
      [VacationRequestStatusEnum.PENDING]: [ACTIONS.ANSWER],
    },
    {
      [VacationRequestStatusEnum.APPROVED_BY_MANAGER]: [ACTIONS.REPROVE],
    },
    {
      [VacationRequestStatusEnum.REFUSED_BY_MANAGER]: [ACTIONS.APPROVE],
    },
  ];

  const options = menuOptions.find(option => option.hasOwnProperty(itemCopy.status));

  const hasWageNegotiation = itemCopy.isWageNegotiation;

  return (
    <>
      <DetailRow>
        <DetailCell style={{ width: '15px' }}></DetailCell>
        <DetailCell style={{ width: '370px' }}>
          <ExpandedContent expanded={expanded}>
            <p>
              Período solicitado: {formateDate(itemCopy.start_date)} - {formateDate(itemCopy.end_date)}
            </p>
          </ExpandedContent>
        </DetailCell>
        <DetailCell>
          <ExpandedContent expanded={expanded}>
            <p style={{ display: 'flex', alignItems: 'center', gap: '4px' }} title={itemCopy.requested_by.name}>
              Solicitante: <Avatar name={itemCopy.requested_by.name} src={itemCopy.requested_by.avatar} />
            </p>
          </ExpandedContent>
        </DetailCell>
        <DetailCell style={{ width: '210px' }}>
          <ExpandedContent expanded={expanded}>
            {itemCopy.managers_approvals.length > 0 ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                <p>Aprovante:</p>
                {itemCopy.managers_approvals.map((manager, index) => (
                  <>
                    {manager.collaborator?.person ? (
                      <Avatar
                        key={index.toString()}
                        name={manager.collaborator.person.name}
                        src={manager.collaborator.person.avatar}
                      />
                    ) : (
                      <Avatar key={index.toString()} src="https://static-incicle.s3.amazonaws.com/no-avatar.jpg" />
                    )}
                  </>
                ))}
              </div>
            ) : null}
          </ExpandedContent>
        </DetailCell>
        <DetailCell>
          <ExpandedContent expanded={expanded}>
            <p>Dias: {hasWageNegotiation ? itemCopy.spent_balance - 10 : itemCopy.spent_balance}</p>
          </ExpandedContent>
        </DetailCell>
        <DetailCell>
          {!!hasWageNegotiation ? (
            <ExpandedContent expanded={expanded}>
              <p style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <MoneyIcon /> 10
              </p>
            </ExpandedContent>
          ) : null}
        </DetailCell>
        <DetailCell>
          <ExpandedContent expanded={expanded}>
            <StatusItem color={VacationRequestStatusColor[itemCopy.status]}>
              {t(VacationRequestStatusLabel[itemCopy.status])}
            </StatusItem>
          </ExpandedContent>
        </DetailCell>
        <DetailCell style={{ textAlign: 'right' }}>
          <ExpandedContent expanded={expanded} style={{ display: 'flex', justifyContent: 'end' }}>
            <IconButton style={{ background: 'transparent' }} onClick={handleMotiveModalOpen}>
              <MotiveIcon />
            </IconButton>

            <Menu
              direction="left"
              menuButton={
                <MoreButton
                  disabled={Boolean(!options)}
                  style={{
                    opacity: Boolean(options) ? 1 : 0,
                    cursor: Boolean(options) ? 'pointer' : 'default',
                  }}
                >
                  <MoreIcon />
                </MoreButton>
              }
              transition
            >
              {options &&
                options[itemCopy.status].map((menu, index) => (
                  <MenuItem key={index.toString()} disabled={false} onClick={menu.action}>
                    {menu.label}
                  </MenuItem>
                ))}
            </Menu>
          </ExpandedContent>
        </DetailCell>
      </DetailRow>

      {approveModal ? (
        <ApproveModal
          approveBy="MANAGER"
          item={itemCopy}
          open={approveModal}
          setOpen={setApproveModal}
          collaboratorId={collaboratorId}
          acquisitiveId={acquisitiveId}
          actualbalance={actualBalance}
          setItem={setItemCopy}
        />
      ) : null}

      {motiveModal ? (
        <MotiveModal open={motiveModal} setOpen={setMotiveModal} motive={itemCopy.note}></MotiveModal>
      ) : null}
    </>
  );
};

export default VacationRequestItem;
