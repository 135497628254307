import React from "react";
import ReactPaginate from "react-paginate";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

import { Container } from "./styles";

interface Props {
  pageCount: number;
  callback: (currentPage: number) => void;
}

interface Page {
  selected: number;
}

const Paginate: React.FC<React.PropsWithChildren<Props>> = ({
  pageCount = 1,
  callback,
}) => {
  function handlePageClick({ selected: currentPage }: Page) {
    callback(currentPage);
  }

  return (
    <Container>
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={7}
        onPageChange={handlePageClick}
        previousLabel={<MdKeyboardArrowLeft size={14} />}
        nextLabel={<MdKeyboardArrowRight size={14} />}
        containerClassName="pagination"
        previousLinkClassName="pagination__link"
        nextLinkClassName="pagination__link"
        disabledClassName="pagination__link--disabled"
        activeClassName="pagination__link--active"
      />
    </Container>
  );
};

export default Paginate;
