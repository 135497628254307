import React, { useContext, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import VacationsIcon from '../icons/vacation-menu-icon.svg';
import EvaluationIcon from '../icons/evaluation-icon.svg';
import OccurrencesIcon from '../icons/occurrences.svg';
import InCheckIcon from '../icons/in-check.svg';

import { MenuContainer, MenuItem } from './styles';
import { usePermissions } from 'safira-app/contexts/Permissions';
import { ProfileContext } from 'contexts/ProfileContext';
import { useTranslation } from 'react-i18next';

interface MenuProps {
  isMenuCollapsed: boolean;
  setMenuCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const Menu: React.FC<MenuProps> = ({ isMenuCollapsed }) => {
  const { t } = useTranslation();
  const { checkPermission, companyId } = usePermissions();
  const { profile } = useContext(ProfileContext);

  const { selectedCompany } = useMemo(() => {
    const selectedCompany = profile?.companies?.find(company => company.id === companyId);
    return { selectedCompany };
  }, [profile, companyId]);

  const renderMenuItemContent = (icon: string, text: string) => {
    return (
      <>
        <img src={icon} alt={`${text} icon`} />
        {!isMenuCollapsed && text}
      </>
    );
  };

  return (
    <MenuContainer>
      {selectedCompany?.is_manager_competence ? (
        <MenuItem className="competence-menu">
          <NavLink to="/evaluation" className={({ isActive }) => (isActive ? 'active' : '')}>
            {renderMenuItemContent(EvaluationIcon, t('homepage.competency_management'))}
          </NavLink>
        </MenuItem>
      ) : null}

      {selectedCompany?.is_manager_in_check && (
        <MenuItem className="competence-menu">
          <NavLink to="/in_check" className={({ isActive }) => (isActive ? 'active' : '')}>
            {renderMenuItemContent(InCheckIcon, t('homepage.incheck'))}
          </NavLink>
        </MenuItem>
      )}

      {checkPermission(['managers_vacations_list']) ? (
        <MenuItem className="vacations-menu">
          <NavLink to="/vacations" className={({ isActive }) => (isActive ? 'active' : '')}>
            {renderMenuItemContent(VacationsIcon, t('homepage.vacation_control'))}
          </NavLink>
        </MenuItem>
      ) : null}

      {checkPermission(['managers_list_occurrences']) ? (
        <MenuItem className="occurrences-menu">
          <NavLink to="/occurrences" className={({ isActive }) => (isActive ? 'active' : '')}>
            {renderMenuItemContent(OccurrencesIcon, t('homepage.occurrences'))}
          </NavLink>
        </MenuItem>
      ) : null}
    </MenuContainer>
  );
};

export default Menu;
