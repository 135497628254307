import styled from "styled-components";

interface SelectBaseProps {
  hasError?: boolean;
}

export const SelectWrapped = styled.div`
  width: 100%;
  max-height: 350px;
  overflow: auto;
  position: relative;
`;

export const SelectBase = styled.select<SelectBaseProps>`
  min-height: 38px;
  max-height: 250px;
  border: #afafaf 1px solid;
  border: ${({ hasError, theme }) =>
      hasError ? theme.colors.danger : "#afafaf"}
    1px solid;
  border-radius: 4px;
  background: #ffffff;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  outline: none;
  &:focus,
  &:focus-visible {
    border: 2px solid ${({ theme }) => theme.colors.primary} !important;
  }

  option {
    font-size: 14px;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }
`;
