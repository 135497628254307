import { VacationRequestType, VacationStatus } from "interfaces/Vacations";

export const formationAcademicLevels = [
  { value: "1", label: "Educação infantil" },
  { value: "2", label: "Fundamental" },
  { value: "3", label: "Médio" },
  { value: "4", label: "Superior (graduação)" },
  { value: "5", label: "Pós-graduação" },
  { value: "6", label: "Mestrado" },
  { value: "7", label: "Doutorado" },
  { value: "8", label: "Tecnólogo" },
  { value: "9", label: "MBA" },
  { value: "10", label: "Especialização" },
];

export const KnowledgeAreas = [
  { value: "1", label: "Ciências Exatas" },
  { value: "2", label: "Ciências Biológicas" },
  { value: "3", label: "Engenharias" },
  { value: "4", label: "Ciências da Saúde" },
  { value: "5", label: "Ciências Humanas" },
  { value: "6", label: "Ciências Agrárias" },
  { value: "7", label: "Ciências Sociais" },
  { value: "8", label: "Linguística, Letras e Artes" },
];

export const SkinColors = [
  { value: "0", label: "Não informado" },
  { value: "1", label: "Branca" },
  { value: "2", label: "Preta" },
  { value: "3", label: "Parda" },
  { value: "4", label: "Amarela" },
  { value: "5", label: "Indígena" },
];

export const SexualOrientation = [
  { value: "0", label: "Não informado" },
  { value: "1", label: "Heterossexual" },
  { value: "2", label: "Homossexual" },
  { value: "3", label: "Bissexual" },
  { value: "4", label: "Assexual" },
  { value: "5", label: "Pansexual" },
  { value: "6", label: "Outros" },
];

export const DesvinculateMotives = [
  { value: "DISMISSAL_REQUEST", label: "Pedido de demissão" },
  { value: "SUMMARY_DISMISSAL", label: "Dispensa sem justa causa" },
  { value: "DISMISSAL_BY_JUST_COUSE", label: "Dispensa com justa causa" },
  { value: "CONTRACT_END", label: "Término do contrato de experiência" },
  {
    value: "REPEAL_BY_COMPANY",
    label: "Rescisão antecipada do contrato por iniciativa do empregador",
  },
  {
    value: "REPEAL_BY_COLLABORATOR",
    label: "Rescisão antecipada do contrato por iniciativa do colaborador",
  },
  { value: "DEATH", label: "Falecimento do colaborador" },
  { value: "OTHERS", label: "Outros" },
];

export const BehavioralProfileCompetenceTypes = [
  { value: "1", label: "Técnica" },
  { value: "2", label: "Comportamental" },
  { value: "3", label: "Resultado" },
];

export const genderOptions = [
  { value: "NOT_REVEALED", label: "Não informado" },
  { value: "MALE_CIS", label: "Homem cis" },
  { value: "FEMALE_CIS", label: "Mulher cis" },
  { value: "MALE_TRANS", label: "Homem trans" },
  { value: "FEMALE_TRANS", label: "Mulher trans" },
  { value: "OTHER", label: "Outro" },
];

export const bloodOptions = [
  { value: "NOT_REVEALED", label: "Não informado" },
  { value: "A_POSITIVE", label: "A+" },
  { value: "A_NEGATIVE", label: "A-" },
  { value: "B_POSITIVE", label: "B+" },
  { value: "B_NEGATIVE", label: "B-" },
  { value: "O_POSITIVE", label: "O+" },
  { value: "O_NEGATIVE", label: "O-" },
  { value: "AB_POSITIVE", label: "AB+" },
  { value: "AB_NEGATIVE", label: "AB-" },
];

export const VacationRequestStatusLabel: { [key in VacationRequestType] } = {
  APPROVED: 'vacation.approved',
  PENDING: 'vacation.pending',
  REFUSED: 'vacation.rejected',
  APPROVED_BY_MANAGER: 'vacation.approved_manager',
  REFUSED_BY_MANAGER: 'vacation.rejected_manager',
  ON_VACATION: 'vacation.on_vacation',
  DONE: 'completed',
};

export const VacationRequestStatusColor: { [key in VacationRequestType] } = {
  APPROVED: "#008AC1",
  PENDING: "#FFAC2F",
  REFUSED: "#DE0000",
  APPROVED_BY_MANAGER: "#008AC1",
  REFUSED_BY_MANAGER: "#DE0000",
  ON_VACATION: "#77B51F",
  DONE: "#8306E5",
};

export const VacationStatusLabel: { [key in VacationStatus] } = {
  EXPIRED: "Vencidas",
  NOT_AVAILABLE: "Não adquiridas",
  APPROVED: "Programadas",
  DONE: "Realizadas",
  EXPIRE_IN_30: "Vence em 30 dias",
  EXPIRE_IN_60: "Vence em 60 dias",
  EXPIRE_IN_90: "Vence em 90 dias",
  OPEN: 'vacation.open',
};

export const VacationStatusColor: { [key in VacationStatus] } = {
  EXPIRED: "#FF5B5B",
  NOT_AVAILABLE: "#212529",
  APPROVED: "#008AC1",
  DONE: "#8306E5",
  EXPIRE_IN_30: "#DE0000",
  EXPIRE_IN_60: "#FF2D2D",
  EXPIRE_IN_90: "#FF5B5B",
  OPEN: "#6C757D",
};
