export default ({ color = '#0092b0' }) => {
  return (
    <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.49984 0.322266V7.0931C8.49984 7.5075 8.66446 7.90493 8.95748 8.19795C9.25051 8.49098 9.64794 8.6556 10.0623 8.6556H16.8332V19.0723C16.8332 19.6248 16.6137 20.1547 16.223 20.5454C15.8323 20.9361 15.3024 21.1556 14.7498 21.1556H2.24984C1.6973 21.1556 1.1674 20.9361 0.776698 20.5454C0.385997 20.1547 0.166504 19.6248 0.166504 19.0723V2.4056C0.166504 1.85306 0.385997 1.32316 0.776698 0.93246C1.1674 0.541759 1.6973 0.322266 2.24984 0.322266H8.49984ZM8.49984 9.69727C8.22357 9.69727 7.95862 9.80701 7.76327 10.0024C7.56792 10.1977 7.45817 10.4627 7.45817 10.7389V13.5598L7.02692 13.1285C6.83046 12.9388 6.56733 12.8338 6.29421 12.8361C6.02109 12.8385 5.75983 12.9481 5.56669 13.1412C5.37356 13.3343 5.26401 13.5956 5.26163 13.8687C5.25926 14.1418 5.36426 14.405 5.554 14.6014L7.76234 16.8118C7.85908 16.9087 7.97396 16.9855 8.10042 17.038C8.22688 17.0904 8.36242 17.1174 8.49932 17.1174C8.63621 17.1174 8.77176 17.0904 8.89821 17.038C9.02467 16.9855 9.13955 16.9087 9.23629 16.8118L11.4457 14.6014C11.5452 14.5053 11.6245 14.3904 11.6791 14.2633C11.7337 14.1362 11.7624 13.9995 11.7636 13.8612C11.7648 13.7229 11.7385 13.5857 11.6861 13.4577C11.6337 13.3297 11.5564 13.2134 11.4586 13.1156C11.3608 13.0178 11.2445 12.9405 11.1165 12.8881C10.9884 12.8357 10.8513 12.8093 10.713 12.8105C10.5746 12.8117 10.438 12.8405 10.3109 12.8951C10.1838 12.9497 10.0688 13.029 9.97275 13.1285L9.5415 13.5598V10.7389C9.5415 10.4627 9.43176 10.1977 9.23641 10.0024C9.04106 9.80701 8.7761 9.69727 8.49984 9.69727ZM10.5832 0.367057C10.9778 0.450761 11.3397 0.647257 11.6248 0.932682L16.2228 5.5306C16.5082 5.81573 16.7047 6.1776 16.7884 6.57227H10.5832V0.367057Z"
        fill={color}
      />
    </svg>
  );
};
