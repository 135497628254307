import React from 'react';

const FeedbackIcon: React.FC = () => {
  return (
    <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.383 17.837V17.7872C17.4385 15.3581 18.2489 13.0094 19.6979 11.0776C21.1469 9.1459 23.16 7.73035 25.4488 7.03395C24.8015 5.8605 23.9881 4.79014 23.0349 3.85714C21.7778 2.62691 20.2942 1.66121 18.6693 1.01542C17.0444 0.369626 15.3102 0.0564588 13.566 0.0938798C10.0428 0.0270156 6.63712 1.38264 4.09454 3.86395C1.55196 6.34525 0.0796693 9.75008 0 13.333V13.3543C0.0091294 15.1727 0.392524 16.969 1.12551 18.6276C1.85849 20.2862 2.92482 21.7704 4.25586 22.9845V26.3204C4.25338 26.4996 4.28599 26.6775 4.35178 26.8438C4.41757 27.01 4.51524 27.1613 4.63908 27.2888C4.76292 27.4163 4.91048 27.5175 5.07314 27.5864C5.2358 27.6554 5.41033 27.6907 5.58655 27.6904C5.83438 27.6893 6.07697 27.6177 6.28691 27.4838L8.9226 25.8218C10.4058 26.3507 11.9663 26.6205 13.538 26.6195H13.8181C15.9986 26.6236 18.1462 26.0796 20.07 25.0359C18.3838 23.0304 17.4341 20.4919 17.383 17.8536V17.837Z"
        fill="#FFDA44"
      />
      <path
        d="M21.4779 10.182C22.4882 9.1922 23.6808 8.4152 24.9873 7.89568C26.2938 7.37616 27.6883 7.12438 29.0908 7.15481C31.9246 7.10196 34.6635 8.19304 36.7081 10.1892C38.7527 12.1854 39.9364 14.9242 40.0001 17.8059V17.8249C39.9918 19.2863 39.6828 20.7298 39.093 22.0625C38.5032 23.3951 37.6456 24.5875 36.5754 25.5627V28.2528C36.5763 28.5042 36.4924 28.7484 36.3378 28.9445C36.1831 29.1407 35.9671 29.277 35.7256 29.3308C35.6489 29.3478 35.5706 29.3557 35.4921 29.3545C35.2929 29.3541 35.0979 29.2965 34.9295 29.1883L32.8097 27.8421C31.6168 28.2668 30.3618 28.4836 29.0978 28.4831H28.8644C26.0689 28.4758 23.3869 27.3577 21.3906 25.3673C19.3944 23.377 18.2419 20.6721 18.1792 17.8297V17.8059C18.207 16.3766 18.5128 14.967 19.079 13.6585C19.6452 12.35 20.4605 11.1685 21.4779 10.182Z"
        fill="#FF9F00"
      />
      <path
        d="M23.0351 3.85708C21.778 2.62685 20.2945 1.66115 18.6696 1.01536C17.0447 0.369565 15.3104 0.0563978 13.5662 0.0938187V9.2776H16.7552C17.3865 9.27854 17.9962 9.51176 18.4716 9.93421C18.9471 10.3566 19.2562 10.9398 19.3419 11.5759C20.8086 9.39678 22.9646 7.79393 25.4514 7.03389C24.8048 5.85928 23.9905 4.7887 23.0351 3.85708Z"
        fill="#FF9F00"
      />
      <path
        d="M14.5467 18.9767C14.4203 18.8656 14.3189 18.7282 14.2492 18.5738C14.1795 18.4193 14.1433 18.2515 14.1429 18.0816V14.5914H13.5662V26.6077H13.8207C16.0011 26.6118 18.1487 26.0677 20.0726 25.0241C19.265 24.0637 18.6204 22.9732 18.1653 21.7974C17.9016 21.7875 17.6489 21.6871 17.4486 21.5125L14.5467 18.9767Z"
        fill="#FF9F00"
      />
      <path
        d="M11.5468 9.27784V8.44684C11.5462 8.221 11.4822 8.00005 11.3624 7.8099C11.2426 7.61976 11.0719 7.46832 10.8704 7.37335C10.669 7.27838 10.4451 7.24382 10.225 7.27374C10.0049 7.30365 9.79773 7.39679 9.62785 7.54223L5.65914 11.0419C5.53416 11.1534 5.43401 11.2906 5.3654 11.4446C5.29679 11.5986 5.26129 11.7656 5.26129 11.9347C5.26129 12.1037 5.29679 12.2708 5.3654 12.4248C5.43401 12.5787 5.53416 12.716 5.65914 12.8274L9.62785 16.3271C9.82513 16.4993 10.0733 16.5996 10.3329 16.612C10.5926 16.6245 10.8489 16.5485 11.0613 16.396C11.2116 16.286 11.334 16.1412 11.4184 15.9736C11.5029 15.806 11.5469 15.6204 11.5468 15.432V14.6034H13.5662V9.27784H11.5468Z"
        fill="#007DBC"
      />
      <path
        d="M16.7552 9.27747H13.5662V14.5911H14.1429V18.0814C14.1433 18.2513 14.1795 18.4191 14.2492 18.5735C14.3189 18.7279 14.4203 18.8654 14.5467 18.9765L17.4486 21.5146C17.6489 21.6892 17.9016 21.7897 18.1653 21.7995C17.6771 20.5384 17.4131 19.199 17.3855 17.8439V17.787C17.4288 15.5669 18.1087 13.4081 19.3419 11.5758C19.2562 10.9397 18.9471 10.3565 18.4717 9.93408C17.9962 9.51164 17.3865 9.27841 16.7552 9.27747Z"
        fill="#06598B"
      />
      <path
        d="M28.3064 21.2869C28.3064 21.4276 28.2766 21.5665 28.219 21.6944C28.1613 21.8222 28.0773 21.9359 27.9726 22.0277L25.5703 24.1266C25.4072 24.2693 25.2016 24.3523 24.9866 24.3622C24.7716 24.3721 24.5596 24.3084 24.3844 24.1812C24.2595 24.0885 24.158 23.967 24.0882 23.8267C24.0184 23.6864 23.9823 23.5312 23.9829 23.3739V16.1988C23.9841 15.6161 24.2122 15.0576 24.6174 14.6456C25.0225 14.2335 25.5717 14.0015 26.1446 14.0002H29.0908V7.15511C26.2567 7.10162 23.5172 8.19242 21.4721 10.1887C19.427 12.185 18.2429 14.924 18.1792 17.8062V17.8347C18.2414 20.6812 19.3965 23.3899 21.3973 25.3814C23.3982 27.3728 26.0858 28.4888 28.8854 28.4906H29.0908V18.3974H28.3064V21.2869Z"
        fill="#FFDA44"
      />
      <path
        d="M35.3358 15.4605L32.0417 12.5638C31.8788 12.4208 31.6735 12.3374 31.4584 12.327C31.2434 12.3167 31.0312 12.38 30.8558 12.5069C30.7302 12.599 30.628 12.7204 30.5578 12.8608C30.4875 13.0012 30.4512 13.1566 30.4519 13.3141V14.0074H29.0909V18.4046H30.4519V19.0908C30.4512 19.2483 30.4875 19.4038 30.5578 19.5442C30.628 19.6846 30.7302 19.8059 30.8558 19.8981C31.0312 20.0249 31.2434 20.0882 31.4584 20.0779C31.6735 20.0676 31.8788 19.9842 32.0417 19.8411L35.3358 16.9444C35.4397 16.8526 35.523 16.7392 35.5801 16.6118C35.6372 16.4844 35.6667 16.346 35.6667 16.206C35.6667 16.066 35.6372 15.9277 35.5801 15.8003C35.523 15.6729 35.4397 15.5594 35.3358 15.4676V15.4605Z"
        fill="#E5394B"
      />
      <path
        d="M26.1448 14.0074C25.5718 14.0086 25.0227 14.2407 24.6175 14.6527C24.2124 15.0648 23.9842 15.6233 23.983 16.206V23.3811C23.9824 23.5384 24.0185 23.6935 24.0883 23.8338C24.1581 23.9742 24.2596 24.0957 24.3845 24.1884C24.5597 24.3155 24.7718 24.3792 24.9868 24.3693C25.2018 24.3594 25.4073 24.2765 25.5705 24.1337L27.9727 22.0349C28.0774 21.9431 28.1615 21.8294 28.2191 21.7015C28.2767 21.5737 28.3065 21.4347 28.3065 21.2941V18.3975H29.0909V14.0074H26.1448Z"
        fill="#FC4E51"
      />
    </svg>
  );
};

export default FeedbackIcon;
