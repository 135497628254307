import styled from "styled-components";

interface StatusItemProps {
  color: string;
}

export const StatusItem = styled.span<StatusItemProps>`
  color: #fff;
  background-color: ${({ color }) => color};
  font-size: 9px;
  font-weight: 600;
  padding: 2px 4px;
  border-radius: 2px;
`;
