import { Table, TableBody, TableHead, TableHeaderCell } from 'components/Tables/styles';
import React, { useMemo } from 'react';
import MonitoringTableItem from './MonitoringTableItem';
import { CycleProps } from 'interfaces/Evaluation';
import { useMonitoringPDI } from 'context/MonitoringPDIContext';

interface MonitoringTableProps {
    cycle: CycleProps;
}

const MonitoringTable: React.FC<MonitoringTableProps> = ({ cycle }) => {
    const { participantPDIList } = useMonitoringPDI()

    const participants = useMemo(() => {
        return participantPDIList.filter(participantPDI => participantPDI.cycle_id === cycle.id);
    }, [cycle, participantPDIList])

    return (
        <Table>
            <TableHead>
                <TableHeaderCell>Participantes</TableHeaderCell>
                <TableHeaderCell style={{ width: '150px' }}>Setor</TableHeaderCell>
                <TableHeaderCell style={{ width: '150px' }}>Cargo</TableHeaderCell>
                <TableHeaderCell style={{ width: '120px' }}>Status</TableHeaderCell>
                <TableHeaderCell style={{ width: '170px' }}>Progresso PDI</TableHeaderCell>
                <TableHeaderCell style={{ textAlign: 'center', width: '120px' }}>Relatório</TableHeaderCell>
                <TableHeaderCell style={{ width: '120px' }}>PDI</TableHeaderCell>
            </TableHead>
            <TableBody>
                {participants.map((participant) => <MonitoringTableItem participant={participant} key={participant.id} />)}   
            </TableBody>
        </Table>
    )
}

export default MonitoringTable;