import CreateRoutes from './routes';
import { ThemeProvider } from 'styled-components';
import { theme } from './layout/theme';
import { AuthProvider } from 'safira-app/contexts/AuthContext';
import ProfileProvider from 'contexts/ProfileContext';
import { PermissionsProvider } from 'safira-app/contexts/Permissions';
import { ToastProvider } from 'react-toast-notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ProfileProvider>
          <ToastProvider>
            <ThemeProvider theme={theme}>
              <PermissionsProvider>
                <CreateRoutes />
              </PermissionsProvider>
            </ThemeProvider>
          </ToastProvider>
        </ProfileProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
